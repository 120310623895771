<template>
  <div
    v-if="errorMessages?.length"
    :class="`error ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
    style="margin-top: 2vw"
  >
    <ul>
      <li v-for="(value, name, index) in errorMessages" :key="index">
        {{ value.split("::")[0] }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errorMessages: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.error {
  margin: auto;
  width: 90%;
  border-radius: var(--border-radius);
  background-color: red;
  padding: 0.5vw;
  color: white;
}

.error li {
  font-size: calc(var(--font-size) * 1.2);
}

.isMobile li {
  font-size: calc(var(--font-size) * 3);
}
</style>
