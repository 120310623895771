export default [
  {
    _id: "6200f74e4f7b5f2557e49abf",
    members: [
      {
        fullName: "Стоян Тинчев",
        studentClass: "12А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232464693.svg?background=%23fec700&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Захари Момчилов",
        studentClass: "12Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230600656.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Кристиян Стоименов",
        studentClass: "12А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232976822.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Виолета Кабаджова",
        studentClass: "12А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232202086.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Мартин Вайер",
        studentClass: "12Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230672830.svg?background=%23450e59&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/VayerMaking/lemniskata",
    teamName: "Лемнискåта",
    projectName: "lemniskata",
    projectDescription:
      "Система, която оценява вероятността за успешно кацане върху космичен терен. Възможните употреби са кацане на астероиди, планети или луни (аналогично върши работа и за избор на позиция на излитане). Основните фактори, които се взимат предвид при определянето на оценката са височина на терена и климатични условия.  Резултатът се визуализира чрез уеб GUI.",
    technologies: [
      "Blockchain",
      "Docker",
      "Python",
      "JavaScript",
      "HTML",
      "CSS",
      "React.js",
      "Redis",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T10:41:18.675Z",
    __v: 14,
    grishoPoints: 0,
    mentorName: "Боян Миланов",
  },
  {
    _id: "6200f9164f7b5f2557e49e17",
    members: [
      {
        fullName: "Иван Петков",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644228958331.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Александър Ангелов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230628054.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Александър Асенов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237656326.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Адриан Пенев",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644239675672.svg?background=%236814f0&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Иван Генов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1645965981392.svg?background=%23950404&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Team-AIS/Space_game",
    teamName: "AIS",
    projectName: "AIS - 3000",
    projectDescription:
      "Удивителна галактическа игра, включваща в себе си РЕАЛИСТИЧНИ ФИЗИКИ, битки с невероятни и чудновати чудовища и още нещо!!!    ;)",
    technologies: ["C++", "JavaScript", "Python", "C", "HTML", "Arduino", "Raspberry Pi"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T10:48:54.247Z",
    __v: 10,
    grishoPoints: 0,
    mentorName: "Стойко Димитров",
  },
  {
    _id: "6200f9194f7b5f2557e49e35",
    members: [
      {
        fullName: "Борислав Борисов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644229980001.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Габриел Петров",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230941905.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Велизар Цикловски",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230524052.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Никола Алексов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644253403553.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Ясен Цветков",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255130951.svg?background=%2306fd56&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/bobikenobi12/kompetentnite.git",
    teamName: "Компетентните",
    projectName: "Astro visualizer",
    projectDescription:
      "Уеб игра, която комбинира алгоритми за намиране на път с лесен за използване интерфейс.",
    technologies: ["HTML", "CSS", "React.js", "JavaScript", "Node.js"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T10:48:57.171Z",
    __v: 13,
    grishoPoints: 45,
    mentorName: "Иво Стратев",
  },
  {
    _id: "6200fe269e09393546c24a49",
    members: [
      {
        fullName: "Никола Петров",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232657482.svg?background=%230088cc&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Тихомир Гърменлиев",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232460403.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Искрен Русимов",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232193674.svg?background=%23ff7300&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Браян Монтичелли",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644233423024.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Иван Постолов",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644414690930.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/tgarmenliev/No-name.git",
    teamName: "Mi int",
    projectName: "SpeechApp",
    projectDescription:
      "Проектът представлява програма, която изпълнява 2 основни функции. Първата от тях е слушане на текст и записването му в текстов формат във файл. Втората функция е резюмиране на даден текст. След като текстът бъде записан от устен в текстов формат той може да бъде резюмиран чрез специална платформа. Резюмирането представлява текст, който съдържа всичко основно и важно от друг текст. Програмата може да помага на хора със слухови увреждания. Основният език, който ще бъде поддържан е английският.",
    technologies: ["Python", "CSS", "HTML", "C", "C++"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:10:30.479Z",
    __v: 7,
    grishoPoints: 0,
    mentorName: "Евгени Димов",
  },
  {
    _id: "6201004b9e09393546c24e20",
    members: [
      {
        fullName: "Йоан Иванов",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644229592624.svg?background=%231db11b&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Живко Нушев",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232344960.svg?background=%233c8122&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Димитър Димитров",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644233343667.svg?background=%23cb6420&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Георги Фиданов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644269086948.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Радослав Филипов",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644478605498.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/y0608/TtT/blob/main/README.md",
    teamName: "TtT",
    projectName: "TtT pager",
    projectDescription:
      "Нашият проект цели комуникация в космоса между астронавти, говорещи различни езици.\n\nГоворим за хипотетично по-голяма космическа станция или кораб, в който може да има голям на брой космонавти, от най-различни страни. Тук идва проблемът за езиковата бариера, която пейджърите превъзмогват. TtT pager представлява N на брой устройства - пейджъри, които комуникират един с друг, чрез raspberry pi, което служи като сървър. Сървърът получава, преработва (превежда) и предава информация в локална мрежа, недостъпна от други лица.",
    technologies: ["Python", "Embedded", "Raspberry Pi", "IOT"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:19:39.978Z",
    __v: 18,
    grishoPoints: 0,
    mentorName: "Иван Трендафилов",
  },
  {
    _id: "620101f59e09393546c25015",
    members: [
      {
        fullName: "Калоян Дойчинов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230813645.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Мартин Божилов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230872084.svg?background=%23324dd2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Алеко Георгиев",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230442082.svg?background=%236f32d2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Александър Иванов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644231230565.svg?background=%234d32d2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Кристиян Богданов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644915273910.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/dot-ht/dot-project",
    teamName: "dot",
    projectName: "dot in space",
    projectDescription:
      "Бързо. Лесно. Просто. Имаш въпрос за вселената? Ние имаме отговора. Ще ти разкрием тайните на дълбокия космос на лесен и разбираем език. Елате на звездните кординати: https://github.com/dot-ht/, и се запознайте със всезнаещия todbot.",
    technologies: [
      "Python",
      "JavaScript",
      "CSS",
      "HTML",
      "C",
      "C++",
      "C#",
      "Node.js",
      "React.js",
      "Linux",
      "MySQL",
      "SQL",
      "Swift",
      "Flutter",
      "Kotlin",
      "Flask",
      "Django",
      "TensorFlow",
      "TypeScript",
      "MongoDB",
      "SQLite",
      "Machine Learing",
      "Docker",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:26:45.729Z",
    __v: 15,
    grishoPoints: 5,
    mentorName: "Ангел Пенчев",
  },
  {
    _id: "620102139e09393546c25027",
    members: [
      {
        fullName: "Кристиан Кирчев",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644234827437.svg?background=%23ff00d0&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Ивайло Георгиев",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644234066603.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Димо Йорданов",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/2.svg?background=%23ffffff&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Даниел Димитров",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644843696622.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/KristianKirchev/Siomgus.git",
    teamName: "Капиталистически Карагьоз",
    projectName: "Siomgus",
    projectDescription: "Управляем мараоход, със сензори и камера",
    technologies: [
      "C",
      "C++",
      "C#",
      "CSS",
      "HTML",
      "Python",
      "Java",
      "JavaScript",
      "Arduino",
      "Embedded",
      "SQL",
      "MySQL",
      "Linux",
      "Raspberry Pi",
      "Flask",
      "Django",
      "Vue.js",
      "React.js",
      "Assembler",
      "Rust",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:27:15.596Z",
    __v: 19,
    grishoPoints: 5,
    mentorName: "Любомир Циров",
  },
  {
    _id: "620104ecc13c431f628744ef",
    members: [
      {
        fullName: "Тодор Стоименов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230860364.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Стоян Бабанов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230773919.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Иван Ламбев",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230557885.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Борис Стоянов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236771677.svg?background=%2300ff00&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Антон Станков",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644350537678.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/1van4o/Team-Pospalankovcite",
    teamName: "Pospalankovcite",
    projectName: "EncryptoSpace",
    projectDescription:
      "Нашият проект се базира основно на SpringBoot Java application, който има за цел да криптира информация базирана на изследвания от космонавтите, която се криптира и записва в memory база, след което стига до Земята и се декриптира. Проектът цели пренасяне на информация от Космоса до Земята, защитена чрез криптиране.",
    technologies: ["JavaScript", "CSS", "HTML", "Java"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:39:24.951Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Иван Паунов",
  },
  {
    _id: "62010540c13c431f628745bb",
    members: [
      {
        fullName: "Габриел Мартинов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230879068.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Кристиан Кирилов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236232392.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Билян Костадинов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230600986.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Николай Белперчинов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230800024.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Мартин Йосифов",
        studentClass: "8Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230880686.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/MartinovG/Hack-Tues-Infinity_Durjavna-Sigurnost",
    teamName: "Durjavna sigurnost",
    projectName: "AstroIRR",
    projectDescription:
      "Радар, пригоден да засича близки опасности,които могат да повредят космическия ни кораб.Използва инфра червен сензор,който работи по метода на триангулацията и не се влияе от температурите на околната среда.",
    technologies: ["Arduino", "Embedded", "Java"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:40:48.925Z",
    __v: 6,
    grishoPoints: 15,
    mentorName: "Кристиан Мариянов",
  },
  {
    _id: "620105ccc13c431f62874795",
    members: [
      {
        fullName: "Рая Литова",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644233806712.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Андрей Тодоров",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644234116985.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Матей Вълов",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644234907358.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Александър Асса",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644268650274.svg?background=%230675fd&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Ирина Ахамад",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644590293650.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/RayaLitova/SpaceInnovators",
    teamName: "ne znam",
    projectName: "Space Innovators",
    projectDescription:
      "Space Innovators - това е 2D игра, в която създаваш и развиваш своя собствена космическа станция. На нея има екипаж, който изисква грижа от страна на играча и изпълнява задачи, необходими за развитието на станцията. С напредване на играта играчът ще се сблъсква с нови цивилизации, нужда от ресурси, препядствия, застрашаващи живота на екипажа му, и още множество предвидими и непредвидими ситуации. Целта на играта е играчът да усъвършенства своята станция и да я опази, както и нейния екипаж.",
    technologies: ["C#", "Unity3D"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T11:43:08.299Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Стоян Рузманов",
  },
  {
    _id: "620115cea766fb2963fa3648",
    members: [
      {
        fullName: "Ивайла Панайотова",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236484004.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Яна Илиева",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236938993.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Милица Кирякова",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237357455.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/iv4pa4/Cong-Posmic",
    teamName: "No Pong Intended",
    projectName: "Cong Posmic",
    projectDescription:
      "Как да се справим със скуката, но и да добием нови знания за безкрайния космос? Well... нашият добър приятел Cong Posmic може да помогне! Проектът представлява сайт, в който имаш опции за това какво искаш да правиш. От неговата хроника (нашата информационна част) можете да научите, интересни факти,  да намерите космическа литература,  да разгледате опитите и подбраните видеа или просто да се развеселите с някоя от събраните по време на пътешествието му Space Jokes! Ако пък искате да пообщувате с Cong може да проверите знанията си и да станете част от екипажа му, като успешно преминете теста с въпроси (чиито отговори се съдържат в информативната част) и постигнете висок резултат, или просто да се позабавлявате с играта, в която нашият бот ще познае вашата планета. Това е забавен и бърз начин да проверите знанията си!",
    technologies: ["JavaScript", "HTML", "PHP", "MySQL", "SQL"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T12:51:26.651Z",
    __v: 3,
    grishoPoints: 0,
    mentorName: "Емил Доцев",
  },
  {
    _id: "62011bd34e1616bf6cb2dfb7",
    members: [
      {
        fullName: "Андрей Ежков",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237392140.svg?background=%233d32d2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Александър Велев",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237378759.svg?background=%239b3408&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Станислав Иванов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237427219.svg?background=%23091658&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Spacethusiast/Website",
    teamName: "META™",
    projectName: "Крипто космически ентусиасти",
    projectDescription: "Крипто космически ентусиасти",
    technologies: [
      "Python",
      "HTML",
      "CSS",
      "Java",
      "C",
      "C++",
      "Node.js",
      "Flask",
      "Objective C",
      "Assembler",
      "Django",
      "Unity3D",
      "TensorFlow",
      "MySQL",
      "NoSQL",
      "SQL",
      "Machine Learing",
      "Etherium",
      "Blockchain",
      "Embedded",
      "Arduino",
      "Raspberry Pi",
      "VR",
      "AR",
      "Kotlin",
      "Kubernetes",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T13:17:07.096Z",
    __v: 4,
    grishoPoints: 0,
    mentorName: "Елвис Бабуров",
  },
  {
    _id: "620129244e1616bf6cb2ea74",
    members: [
      {
        fullName: "Елена Чернева",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256484923.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Стефани Пенчева",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256861020.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Магдалена Никифорова",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1645532549637.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Емилиана Петренко",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644360126032.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/xcWhy/HackTues8___PlatypusESM",
    teamName: "PlatypusESM",
    projectName: "Space quiz",
    projectDescription:
      'Space Quiz е игра, която съчетава полезното с приятното. Проектът цели както да предостави на потребителите факти за Космоса, така и да провери знанията им след това. А как става това? \nВ началото потребителят избира свой собствен аватар в костюм по избор. След това той преминава през "обучение", в което прочита даден текст, написан върху карти.\nСлед като е прочел целия текст, потребителят отива, за да провери какво е научил от прочетеното.\nАко потребителят не срещне въпрос, който знае, то той може да го добави в обозначената за това секция. За да не се злоупотребява обаче, всеки въведен въпрос, ще преминава през проверка на член от екипа.\nСъс Space Quiz можете едновременно и да се забавлявате, и да учите.',
    technologies: ["Python", "C++", "C", "JavaScript", "HTML", "CSS"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T14:13:56.989Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Нейчо Калайджиев",
  },
  {
    _id: "62012a574e0611652f9c4f36",
    members: [
      {
        fullName: "Николай Лазаров",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230257880.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Християн Радев",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644346878703.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Йосиф Хамед",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644263381176.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Йоан Джелекарски",
        studentClass: "12Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644261473299.svg?background=%2332d2b7&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/JIOjosBG/hacktues8",
    teamName: "Котки с палци",
    projectName: "СПИМ",
    projectDescription:
      "СПИМ - система за подпомагане изследването на Марс.\n\n\nСистемата съдържа няколко компонента:\n\n-множество устройства, разположени в станции на Марс, които чрез сензори измерват външната обстановка;\n - температура, влажност, марсотресения, светлина; \n-джобни устройства, които астронавтите взимат със себе си при експедиция, за да бъдат уведомявани при сериозно отклонения в показателите до станциите ;\n-сървър, в който се съхранява информация за предишни, текущи (и бъдещи) данни;\n-уеб сайт, в който нагледно е представена информацията чрез графики",
    technologies: [
      "JavaScript",
      "Python",
      "CSS",
      "HTML",
      "SQLite",
      "Embedded",
      "Arduino",
      "Django",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T14:19:03.686Z",
    __v: 10,
    grishoPoints: 1,
    mentorName: "Велизар Стоянов",
  },
  {
    _id: "62012da14e0611652f9c5314",
    members: [
      {
        fullName: "Стефан Касамаков",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243810928.svg?background=%23d2329f&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Михаил Бошкилов",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644261023222.svg?background=%23800040&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Михаел Динков",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644271990157.svg?background=%23403a4b&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Migumagunata/530_malkiq_kosmos_HackTUES_8",
    teamName: "530 malkiq kosmos",
    projectName: "система за гасене на пожари в извънземни съоражения",
    projectDescription:
      "Система на arduino основа със сензо-ри за дим, движение, вентилато и\nOLED екран. При засичане на дим или друг газ, който е признак на пожар ще се пусне едноминутно отброяване с цел известяване и изолира на помещението. Докато този таймер тече е включи сензор за движение, ако той отчете движение ще се пусне още 30 секунден таймер за евакуация. Ако няма движение ще се задейства вентила-тор, който ще вмъкне въздух свисоко съдържание на азот, като по този начин ще се намали кислорода и ще се прекрати пожара.",
    technologies: ["C", "Python", "HTML", "CSS", "Raspberry Pi", "Arduino", "Embedded"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T14:33:05.262Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Огнян Чиков",
  },
  {
    _id: "6201535914391c71393a261f",
    members: [
      {
        fullName: "Антон Христов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243413975.svg?background=%23f5ec00&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Михаил Георгиев",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644476367639.svg?background=%238d7e35&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Стоян Иванов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243617572.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Misho12345/hack-tues",
    teamName: "Картоф в Кашон",
    projectName: "Astroland",
    projectDescription: "Видеоигра, в която отбраняваш дома си от извънземни на изоставена планета",
    technologies: ["JavaScript", "HTML", "CSS", "Node.js"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T17:14:01.853Z",
    __v: 10,
    grishoPoints: 0,
    mentorName: "Димитър Калинков",
  },
  {
    _id: "620155e11d06c5ac94f4b1ba",
    members: [
      {
        fullName: "Матей Захариев",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644231545794.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Десислава Стефанова",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644252381342.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александра Стойчева",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644251730388.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Симеон Йорданов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644937343403.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Иван Георгиев",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644230791591.svg?background=%2330a1b0&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Moiseicho/Hacktues-infinity.git",
    teamName: "Code = problem, !Code = no_problem;",
    projectName: "Interplanetal",
    projectDescription:
      "Космонавт се озовава в странен свят, където планетите крият антична сила. За да я откриете трябва да преминете през поставените лабиринти и изпитания. Всяка следваща част помага за следващата планета. Намерете всички части за да получите контрол над времето и пространството.",
    technologies: ["C", "C++", "HTML", "CSS", "Python", "C#", "Unity3D"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T17:24:49.909Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Георги Стоилов",
  },
  {
    _id: "62015ae31d06c5ac94f4b939",
    members: [
      {
        fullName: "Мартин Наков",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256303587.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Димитър Николов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236820073.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Венелин Новаков",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644236545565.svg?background=%239e8eb8&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Емил Дойчинов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237087904.svg?background=%23ffffff&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Алекс Огнянов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644233316600.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/generot/Memelitrix_HackTues8",
    teamName: "Memelitrix",
    projectName: "AbilitY",
    projectDescription:
      'AbilitY - AbilitY е уеб приложение, позволяващо на хората с увреждания и затрдунения да получат помощ от доброволци, решили да им помогнат със задачи,  вариращи от най-обикновени нужди до пълни грижи за тях в случай, че техните роднини и познати нямат тази възможност. Това ще се случва чрез пускане на "обяви", на които доброволците могат да отговарят и да приемат.',
    technologies: [
      "JavaScript",
      "Python",
      "HTML",
      "CSS",
      "C",
      "C++",
      "C#",
      "Node.js",
      "Assembler",
      "Unity3D",
      "Linux",
      "TypeScript",
      "React.js",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T17:46:11.600Z",
    __v: 4,
    grishoPoints: 0,
    mentorName: "Валентин Младенов",
  },
  {
    _id: "62015d811d06c5ac94f4be44",
    members: [
      {
        fullName: "Иван Матев",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644254860115.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Виктор Велков",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644254991485.svg?background=%2300ff04&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Калоян Атанасов",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256140680.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Крис Петков",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644242228592.svg?background=%230000ff&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Боян Вачков",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256388058.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Brusnarq/Hacktues-8.v2",
    teamName: "Кой как се уреди",
    projectName: "Last Chance",
    projectDescription:
      "Last chance е 3D игра, в която играеш като космунават, който се носи в открития космос, заради липса на гориво в неговия космически кораб. За щастие, наблизо има непозната планета. Как ще се върнеш у дома? Какво или кого ще намериш там? Разбреи в Last chance!",
    technologies: ["JavaScript", "Python", "HTML", "CSS", "C", "C++", "React.js"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T17:57:21.912Z",
    __v: 4,
    grishoPoints: 20,
    mentorName: "Павлин Николов",
  },
  {
    _id: "620160481d06c5ac94f4c3d1",
    members: [
      {
        fullName: "Светослав Илиев",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644251509516.svg?background=%2300b3ff&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Никола Георгиев",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255627810.svg?background=%236326c5&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Радослав Димитров",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256025978.svg?background=%234407a6&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Николай Вълканов",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644414155723.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Евгения Голубева",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255271185.svg?background=%23d2144d&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Svetoslav47/HackTues2022",
    teamName: "ERROR: name undefined",
    projectName: "Future Space",
    projectDescription:
      "Проектът ни представлява сайт, който решава проблем с незаинтересоваността към изследването на космоса. Искаме с негова помощ да заинтересуваме човечеството и да му покажем, че космосът е нещо, което представлява интерес и неговата колонизация може да помогне за решаване на проблеми.",
    technologies: ["HTML", "JavaScript", "CSS", "C", "C++", "C#", "Unity3D"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T18:09:12.529Z",
    __v: 7,
    grishoPoints: 20,
    mentorName: "Морети Георгиев",
  },
  {
    _id: "6201620d1d06c5ac94f4c789",
    members: [
      {
        fullName: "Калоян Миладинов",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644252051385.svg?background=%23004040&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Денис Мирчев",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255579939.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Божидар Павлов",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644693418300.svg?background=%2399c1f1&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Никола Сачков",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644258152385.svg?background=%23100b4b&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/false-positive/astrolink",
    teamName: "False Positive",
    projectName: "AstroLink",
    projectDescription:
      "Платформа за споделяне на файлове с данни за астрономически проучвания. Основната идея на проекта е да следи развитието на дадено проучване, да систематизира данните в него и да ги прави достъпни за всеки участник в проекта, независимо дали той се намира на Земята или в космоса.  Хората работещи по дадедено проучване са обединени в екипи като всеки user може да е член на повече от един екип и проект, както и всеки екип може да се занимава с няколко проекта едновременно.",
    technologies: [
      "JavaScript",
      "Python",
      "HTML",
      "CSS",
      "C",
      "React.js",
      "Machine Learing",
      "Django",
      "TensorFlow",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T18:16:45.109Z",
    __v: 5,
    grishoPoints: 100,
    mentorName: "Димитър Терзиев",
  },
  {
    _id: "620168da2bec3ecee3428496",
    members: [
      {
        fullName: "Иван Иванов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255798225.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Любомир Станев",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644256200565.svg?background=%23325ad2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Викторио Миланов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255448690.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Веселин Владимиров",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644242696868.svg?background=%23315ec9&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Николай Йорданов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255484964.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/i-kratko/SpaceVision",
    teamName: "Перничани в повече",
    projectName: "SpaceVision",
    projectDescription:
      "SpaceVision е проркт, целящ да приобщи незрящите хора към необятния космос.",
    technologies: [
      "Python",
      "C",
      "Arduino",
      "Embedded",
      "Raspberry Pi",
      "C++",
      "JavaScript",
      "HTML",
      "Java",
      "C#",
      "CSS",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T18:45:46.851Z",
    __v: 4,
    grishoPoints: 0,
    mentorName: "Пламен Данов",
  },
  {
    _id: "620172128f0775c3005a526e",
    members: [
      {
        fullName: "Филостратос Титопулос",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1645962627750.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Евгени Попов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644260257418.svg?background=%23d9690d&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александра Лазарова",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644260303393.svg?background=%2333c7db&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Огнян Разсадов",
        studentClass: "9В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644583606574.svg?background=%23ff0000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Михаил Петров",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1645961917385.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/filio123321/hacktues8",
    teamName: "L0rd Farquaad",
    projectName: "MarsIT",
    projectDescription:
      "Нашият проект представлява марсоход, който обикаля, прави панорамни снимки и събира проби от Марс. Той събира данни за околната среда, които биват представени в сайт. Този сайт съдържа информация за марсохода, галерия със снимки на Червената планета, история на маршрута, който е изминал и най-актуалните новини, свързани с Марс.",
    technologies: [
      "Python",
      "C",
      "C++",
      "Flask",
      "Raspberry Pi",
      "Arduino",
      "Embedded",
      "IOT",
      "Linux",
      "SQL",
      "MySQL",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T19:25:06.535Z",
    __v: 5,
    grishoPoints: 0,
    mentorName: "Боян Атанасов",
  },
  {
    _id: "62017ab9837e403248a36fe4",
    members: [
      {
        fullName: "Стенли Христов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644260654986.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Валентин Маринов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644263453157.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Михаил Василев",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644266844442.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Христо Ангелов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644588804099.svg?background=%231870a6&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/stenli05/gewgweg",
    teamName: "muchas gracias aficion eso espara vo sortos sui",
    projectName: "help people with disabilities",
    projectDescription:
      "Създаване на сайт за разработка на карта подпомагаща хората с физически затруднения да открият специално направени за тях локации например ресторанти, специализирани магазини, превозни средства и други.",
    technologies: [
      "HTML",
      "Python",
      "CSS",
      "JavaScript",
      "Java",
      "C",
      "PHP",
      "SQL",
      "NoSQL",
      "MySQL",
      "Postgre SQL",
      "SQLite",
      "Linux",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T20:02:01.227Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Огнян Барух",
  },
  {
    _id: "62017b09837e403248a37045",
    members: [
      {
        fullName: "Васил Колев",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243721119.svg?background=%23f5a905&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Иван Иванов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243982002.svg?background=%23ff7b00&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Теодор Тотев",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644277032066.svg?background=%23a55622&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Виктория Димитрова",
        studentClass: "12Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644426074966.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Яна Балджиева",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644347200082.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/vickymd03/HackTUES-Infinity-C--/tree/main/server",
    teamName: "C--",
    projectName: "GreenBo",
    projectDescription:
      "Създаване на самоходна машина за изследване на космически тела.  Проектиране на физически прототип на устройството със следните възможности:                        задвижване с четири колела, всяко от които има собствен мотор и възможност за завиване;  шаси на два етажа; роботизирана ръка с три степени на свобода и щипка за захващане; модул за радиоуправление; предаване на видео изображения, предаване на данни и приемане на команди; автономно захранване от батерии и зареждане чрез слънчев панел; отделение за събиране на образци. Част от екипа ще създаде сървър и приложение за събиране на снимки, данни от апарата и изпращане на команди към него.",
    technologies: ["JavaScript", "Python", "HTML", "CSS", "C", "PHP", "Embedded", "MySQL", "SQL"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-07T20:03:21.720Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Станислав Милев",
  },
  {
    _id: "620216c8aae78464a6910008",
    members: [
      {
        fullName: "Димитър Цветков",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644304401980.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Никола Белев",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644304239790.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Деян Атанасов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644304172788.svg?background=%23050505&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Дейвид Гигов",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644319522664.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/DeyanFit/quadra-d",
    teamName: "Quadra D",
    projectName: "D space game",
    projectDescription:
      'Правим игра тип "space shooter". Ще се играе с конфигурация, включваща Raspberry, която наподобява GameBoy конзола.',
    technologies: [
      "Python",
      "C",
      "Arduino",
      "Etherium",
      "VR",
      "HTML",
      "C++",
      "CSS",
      "C#",
      "Blockchain",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T07:07:52.683Z",
    __v: 3,
    grishoPoints: 0,
    mentorName: "Виктор Найчев",
  },
  {
    _id: "62021b97aae78464a6910a22",
    members: [
      {
        fullName: "Калоян Анастасов",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232886743.svg?background=%230effff&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Таня Колева",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644338328934.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Виктор Златев",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1645951680620.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Васил Велев",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644416454331.svg?background=%23323dd2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Константин Златев",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644417727762.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Flychuban/Team_FLASH__Hack_TUES8.git",
    teamName: "FLASH",
    projectName: "Space Traveler",
    projectDescription:
      "Интерактивен уебсайт, на тема космос, с образователна и развлекателна цел, съдържащ и видео игра, с цел да бъдат прескочени дадени препядствия.",
    technologies: ["JavaScript", "HTML", "CSS"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T07:28:23.184Z",
    __v: 9,
    grishoPoints: 1,
    mentorName: "Валери Добрев",
  },
  {
    _id: "62023488aae78464a691323e",
    members: [
      {
        fullName: "Дарий Топузов",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644253020097.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Дамян Мяшков",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644305688286.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Антонио Готев",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644386306187.svg?background=%2300ffff&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Кристиян Андреев",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644831973613.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Стефан Крусашки",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644403416950.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/dr-programmer/HackTues8---TE-AM-new",
    teamName: "TE-AM",
    projectName: "SIRIUS",
    projectDescription:
      "Нашият проект е написан на flutter за темата Wide Space. Той е приложение, което позволява на потребителя да научи нови неща за космоса и след това да провери знанията си чрез интерактивна тестова система. Също така се има в предвид log in система, която позволява запазване на прогреса в приложението, и daily блог страница, която позволява за качване на интересни факти и събития за космоса. За бъдещо развитие може да се добави всеки user да може да добавя статии, но те трябва да бъдат проверени за достоверност от администраторския екип. Прогресът се предвижда да се показва на Account Page.",
    technologies: ["Flutter"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T09:14:48.325Z",
    __v: 8,
    grishoPoints: 10,
    mentorName: "Теодора Йовчева",
  },
  {
    _id: "62023b34aae78464a6913d74",
    members: [
      {
        fullName: "Злати Златев",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644315672335.svg?background=%233252d2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Никола Кръстанов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644255703701.svg?background=%23ff8040&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Борис Антов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644316208125.svg?background=%23352dd7&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Даниел Икономов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644315497900.svg?background=%23d29732&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александър Мечкаров",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644316458584.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/NickProgrammerGaming/HackTuesInfinityGame",
    teamName: "FЭta cheese",
    projectName: "Life Beyond",
    projectDescription:
      "Нашият проект е platformer 2D игра, състоeйки се от 3 нива репрезентирани от 3 планети - Alpha, Beta, Sigma.",
    technologies: ["C#", "Unity3D", "C", "Python", "JavaScript", "CSS", "HTML"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T09:43:16.222Z",
    __v: 6,
    grishoPoints: 0,
    mentorName: "Тодор Димитров",
  },
  {
    _id: "620250b7aae78464a691665e",
    members: [
      {
        fullName: "Ясен Ефремов",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644310400614.svg?background=%23ff6400&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Ивайло Генчев",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644318513249.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Явор Пачеджиев",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644356162939.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Симеон Христов",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644611962668.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александър Христов",
        studentClass: "11Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644241885890.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/YassenEfremov/HTI-Project",
    teamName: "ArchBTW",
    projectName: "Planet Prospect",
    projectDescription:
      "Образователна игра/симулатор, която използва реална информация от космоса.",
    technologies: ["Unity3D", "C#", "Python"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T11:15:03.663Z",
    __v: 16,
    grishoPoints: 10,
    mentorName: "Милен Спасов",
  },
  {
    _id: "620264d5aae78464a6918baa",
    members: [
      {
        fullName: "Любомир Начев",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644319527596.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Адриан Стоев",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644324216447.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Ясен Спасов",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644339005585.svg?background=%2326a269&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Иво Иванов",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243626365.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Борис Кисьов",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644303942931.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/lubo04/jub",
    teamName: "60 metra",
    projectName: "Space Jub",
    projectDescription:
      "Проекта ни се състои от 3 основни части - Embedded, Backend и Frontend и връзката между предишните две. Embedded частта се състои от Arduino, Bluetooth slave модул,зумер и сензори, които измерват стойностти като, температура, влажност на въздуха, съдържание на метан, въглероден оксид и диоксид , запалими газове и пушек. Всичките тези данни се прехвърлят на компютър, благодарение на комуникацията между Bluetooth slave модул и com port на компютъра. Все още не сме уточнили как да вписваме информацията в базата данни, защото може да стане чрез php или специялен скрипт. След като е внесена информацията в базата данни, тя се визуализира от уеб сайт. Ако някоя от стойностите е под или над нормата, ще бъде визуализирана по различен начин и ще се задейства аларма, която е съставена от зумер.",
    technologies: [
      "JavaScript",
      "HTML",
      "Python",
      "CSS",
      "C",
      "C++",
      "Linux",
      "Embedded",
      "Arduino",
      "Etherium",
      "Blockchain",
      "Raspberry Pi",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T12:40:53.774Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Симеон Георгиев",
  },
  {
    _id: "62027e73aa76245d0028a31e",
    members: [
      {
        fullName: "Александър Шестаков",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644240708137.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Цветелина Петкова",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1646571449797.svg?background=%23645b86&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Павел Шаламанов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644326159834.svg?background=%23706da2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Преслав Атанасов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644329167590.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Алек Сивков",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644326194331.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/ProGamer2711/SpaceTime",
    teamName: "VAP",
    projectName: "SpaceTime",
    projectDescription: "A website where you can find information about rockets",
    technologies: ["Node.js", "HTML", "CSS", "JavaScript", "MongoDB"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T14:30:11.145Z",
    __v: 4,
    grishoPoints: 10,
    mentorName: "Костадин Костадинов",
  },
  {
    _id: "6202a042aa76245d0028e1c9",
    members: [
      {
        fullName: "Константин Щерев",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644338737479.svg?background=%233252d2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Крум Стефанов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644229094234.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Никола Керезов",
        studentClass: "10А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644347785656.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Калоян Сотиров",
        studentClass: "10В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237998482.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/KokoShterev/HackTUES_Infinity",
    teamName: "LoL",
    projectName: "",
    projectDescription:
      "Имаме сайт, в който си представяме проекта накратко и откъдето може да бъде изтеглен екзекютабъл файл, който да стартира нашето приложение(GUI). В приложението се ъплоудва снимка на галактиката, която искате да бъде определена. Тук вече в сила влиза нашият Machine Learing, който връща какъв тип е галактиката и информация за нея. Също така показва снимката, която сте избрали, за да можете да бъдете сигурни, че не сте качили грешната(user friendly).",
    technologies: ["Python", "C", "C++", "TensorFlow", "Machine Learing"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T16:54:26.130Z",
    __v: 6,
    grishoPoints: 0,
    mentorName: "Мартин Йорданов",
  },
  {
    _id: "6202bcacfc0d049cd278dd06",
    members: [
      {
        fullName: "Пламена Георгиева",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644345039372.svg?background=%23c78f8f&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Мартин Тарлев",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644568204235.svg?background=%23b90404&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Емилия Чукалева",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644345437125.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Ивайло Каньов",
        studentClass: "10Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644610351173.svg?background=%23d27f32&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Виктор Димитров",
        studentClass: "10Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644436624413.svg?background=%231ccecb&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Russian-SPUTNIK/Main_Repo",
    teamName: "SPUTNIK",
    projectName: "Safe Cosmos costume ",
    projectDescription:
      "Нашата идея е да разработим устройства, работещи в услуга на човека в космоса. В случай, че няма видимост, с помощта на гривна човек ще може да се ориентира.\n\nЩе отчита температурата на човека от китката и влажност, измерва пулса му и атмосферното налягане, както и часа. Тази система ще бъде под формата на гривна. \n \nЗа включване на цялата система отговаря фоторезистор, индикиращ дали е тъмно или не. \nС помощта на жироскоп и светодиог ще се осветява околността.\n\nПри засичане на обект на определено разстояние пред или зад нас ще има индикация под формата на светлина и/или звук. \n\nСлед събирането на тази необходима информация, тя се изпраща чрез request от Ардуиното към Дискорд, използвайки webhook и Дискорд бот.\n \nИдеята е системите да се прикачат към дреха и да работят по този начин. Поради ограниченото време в хакатона не сме сигурни, че ще успеем да ги прикачим, но в бъдеще ще развием идеята си и проекта, така че да има завършен вид и дизайн.",
    technologies: ["Python", "HTML", "C", "CSS", "Arduino"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T18:55:40.466Z",
    __v: 7,
    grishoPoints: 0,
    mentorName: "Красимир Иванов",
  },
  {
    _id: "6202d187fc0d049cd2790ac3",
    members: [
      {
        fullName: "Данаил Стоилов",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644238563001.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Александра Александрова",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644237506805.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Ивона Вълчева",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644238384103.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Християн Тодоров",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644253774644.svg?background=%233a1872&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Андрей Иванов",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644238077957.svg?background=%23ea3323&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/0DakatA0/Destroy-Uranus",
    teamName: "Destroy Uranus",
    projectName: "Quizzi",
    projectDescription:
      "Quizzi е мобилно приложение въпросник на тема космос, включващо интересни факти, въпроси от различни области на познанията за космоса и допълнителна информация.",
    technologies: ["React.js", "Django", "SQLite", "Node.js"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T20:24:39.576Z",
    __v: 6,
    grishoPoints: 35,
    mentorName: "Николай Марков",
  },
  {
    _id: "6202e67e4ca507718193f724",
    members: [
      {
        fullName: "Божидар Попниколов",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644243853771.svg?background=%23303388&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: true,
      },
      {
        fullName: "Никола Милков",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644438498710.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Велизар Йорданов",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644497654319.svg?background=%2352d6fc&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/BojoPopnikolov/HackTues8",
    teamName: "Lucky & Wild",
    projectName: "Space for everybody",
    projectDescription:
      "Space for everybody е уебсайт с различни функционалности. Предназначението му е да информира хората за космоса в миналото и в бъдещето, какъв е бил и какъв може или ще бъде. В сайта ще намерите информация за всички планети в соларната система, съзвездията и зодийте, както и информация за изминали експедиции. А ако ви омръзне да четете има развлекателна мини игричка с още информация за космоса.",
    technologies: [
      "C",
      "C++",
      "HTML",
      "CSS",
      "JavaScript",
      "SQL",
      "MySQL",
      "Arduino",
      "Embedded",
      "React.js",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-08T21:54:06.943Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Владислав Георгиев",
  },
  {
    _id: "62036d397587f76866c16c9b",
    members: [
      {
        fullName: "Боримир Ганчев",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644424249743.svg?background=%234a4b4f&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Радослав Цветанов",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644391089770.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Даниел Блъсков",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644423622570.svg?background=%23e22400&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Ивайло Русинчовски",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644434955391.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александър Стоянов",
        studentClass: "8А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644510311590.svg?background=%2332d267&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/amazingdanny/hacktues-.git",
    teamName: "D'BIRA",
    projectName: "The Wide Space",
    projectDescription:
      "Нашият проект е сайт и има за цел да покаже на колко обширен е космоса и колко на дълбоко стга. Като колкото на долу скролваш, толкова по навътре сигаш и опознаваш обятията на космоса.",
    technologies: ["C", "C++", "HTML", "CSS", "JavaScript"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-09T07:28:57.024Z",
    __v: 11,
    grishoPoints: 35,
    mentorName: "Калоян Манолов",
  },
  {
    _id: "6203b9c6842ecc5476670169",
    members: [
      {
        fullName: "Александър Георгиев",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644413696231.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Калоян Чакъров",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644356313424.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Георги Станачков",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644412591129.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Георги Янакиев",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1646910181535.svg?background=%23323dd2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Владислав Павлов",
        studentClass: "11В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644413217669.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Georgi1914/Olympus-Glory",
    teamName: "Olympus Glory",
    projectName: "RocketBooker",
    projectDescription:
      "Приложение за резервиране на двупосочни билети за полети с ракета извън орбита на Земята. Един полет включва достигане на избрана от потребителя дестинация, която представлява даден космически обект (Луната, Марс и т.н.).",
    technologies: [
      "C",
      "C++",
      "Java",
      "CSS",
      "JavaScript",
      "Python",
      "SQL",
      "MySQL",
      "SQLite",
      "Flutter",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-09T12:55:34.065Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Боян Георгиев",
  },
  {
    _id: "6203fb62842ecc5476673488",
    members: [
      {
        fullName: "Мартин Дойчинов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644250922442.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Александър Малинов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644412568958.svg?background=%2332d27d&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Антон Николов",
        studentClass: "8Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644426501715.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Amalino7/mazna_banichka.git",
    teamName: "Мазните",
    projectName: "Space recycler",
    projectDescription: "Игра, в която събираш боклук в Космоса като избягваш астероидите.",
    technologies: ["C", "C++", "JavaScript", "HTML", "Raspberry Pi"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-09T17:35:30.286Z",
    __v: 2,
    grishoPoints: 0,
    mentorName: "Антонио Милев",
  },
  {
    _id: "62040656842ecc5476673df3",
    members: [
      {
        fullName: "Валентин Асенов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644257420591.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Емил Момчев",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644232241210.svg?background=%2300ff00&mood[]=happy&rotate=16&translateX=-1&translateY=3\n      ",
        isCaptain: false,
      },
      {
        fullName: "Мартин Андонов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644430487524.svg?background=%23fbc05b&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Николай Иванов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644429936671.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Сашо Александров",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644430721422.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/alt-plus-f4/C-Hashtag-infinity",
    teamName: "C Hashtag",
    projectName: "Space S",
    projectDescription:
      "Сайт с новини за космоса и всичко в него. Има всякакви интересни факти за планетите, галактиките, спътници и др. Също така включва интерактивна карта на слънчевата ни система (rip Плутон).",
    technologies: ["JavaScript", "Python", "CSS", "HTML", "MySQL"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-09T18:22:14.850Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Георги Тончев",
  },
  {
    _id: "62051ab8319b85eb210187bf",
    members: [
      {
        fullName: "Християна Ангелова",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644918197995.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Михаел Иванов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644502520722.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Константин Хаджийски",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644502556163.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Чавдара Белева",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644523358261.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/Chochi44/6hure6",
    teamName: "6hüre6",
    projectName: "HurEirians",
    projectDescription:
      "Онлайн сайт, който използва лесни мини приложения, за да дава интересни факти за космоса.",
    technologies: ["Python", "C", "HTML", "CSS"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-10T14:01:28.806Z",
    __v: 5,
    grishoPoints: 0,
    mentorName: "Любослав Иванов",
  },
  {
    _id: "62053456319b85eb21018d7f",
    members: [
      {
        fullName: "Богдан Яков",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644574592083.svg?background=%230b0b56&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Георги Стоянов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644515173519.svg?background=%2321a1de&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Илина Мутафчиева",
        studentClass: "11Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644520190722.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Атанас Ружинов",
        studentClass: "8В",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644303493765.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "АннаМариа Димитрова",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1646933751963.svg?background=%2332d295&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/stoyanov787/tues",
    teamName: "OpenAI",
    projectName: "Space mini games",
    projectDescription:
      "Сайт с две опции. Първата опция е да играеш игра, която цел е да поправиш разбит кораб и да избягаш от Марс. А втората е да качиш снимка на планета от Слънчевата система и чрез невронни мрежи да ти каже коя е планетата.",
    technologies: [
      "Python",
      "HTML",
      "CSS",
      "C",
      "JavaScript",
      "TensorFlow",
      "Pytorch",
      "SQLite",
      "Linux",
      "Machine Learing",
      "IOT",
      "Raspberry Pi",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-10T15:50:46.732Z",
    __v: 9,
    grishoPoints: 0,
    mentorName: "Жулиета Атанасова",
  },
  {
    _id: "62053487319b85eb21018d95",
    members: [
      {
        fullName: "Йосиф Салиел",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644507798576.svg?background=%23f5c211&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Борислав Миланов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644509257026.svg?background=%23e3e34b&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Кристина Иванова",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644508769069.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Йоанна Стаменова",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644508034197.svg?background=%23b181fd&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Томислав Иванов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644527016431.svg?background=%23b80a0a&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/fifiikompaniq/HackTUES-Infinity-Project",
    teamName: "praskovki",
    projectName: "Planet Trip",
    projectDescription:
      "Мобилно приложение, показващо информация за планетите от слънчевата система, както и дневна снимка от вселената.",
    technologies: [
      "Python",
      "HTML",
      "CSS",
      "JavaScript",
      "C",
      "TensorFlow",
      "Pytorch",
      "MySQL",
      "Machine Learing",
      "Flutter",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-10T15:51:35.413Z",
    __v: 13,
    grishoPoints: 0,
    mentorName: "Александър Казанджиев",
  },
  {
    _id: "62053bee319b85eb2101934a",
    members: [
      {
        fullName: "Симеон Симеонов",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644507111943.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Георги Динков",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644510177265.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Иван Сираков",
        studentClass: "9Б",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644507152489.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/MoniMonkata126126/hackTues8",
    teamName: "Cirov gang",
    projectName: "",
    projectDescription:
      "Викторина на тема космос с образователна цел за деца от ранна училищна възраст. Сайтът ще има състезателен характер, като има месечно класиране, на което децата могат да вдигат техния ранг в зависимост от броя точки, натрупани от верни отговори на викторините.",
    technologies: ["Python", "Django", "HTML", "CSS"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-10T16:23:10.218Z",
    __v: 6,
    grishoPoints: 0,
    mentorName: "Александър Узунов",
  },
  {
    _id: "6206b589bbafd0c9a58a9c86",
    members: [
      {
        fullName: "Павел Симеонов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644664381113.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Георги Стоянов",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644615018814.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Димитър Желев",
        studentClass: "9Г",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644306251189.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/WaifuPower/HackTues-Spazzis",
    teamName: "Xhane Xhane tu raxha",
    projectName: "Spazzis",
    projectDescription:
      'Quiz app\nИМЕТО НА ПРОЕКТА Е: SPAZZIS\nQuiz app свързан с космоса на Java. Ще включва меню за избор на трудност (лесно, нормално трудно) и тема (космоса и планетите, космонавтите, ракетите).\nКогато се премине към въпросите те ще бъдат 2 вида:\n1)Въпрос с 1 верен и 3 грешни (въпросът е само текст)\n2)Въпрос с 1 верен и 3 грешни (въпросът ще бъде с текст и снимка)\nПри задаване на верен отговор правоъгълничето, което е било натиснато ще светне със зелено, а при задаване на грешен отговор правоъгълникът, който е бил натиснат ще светне в червено и ще светне в зелено отговорът който е бил правилен.\nСъщо така ще има и таймер започващ да засича от натискането на "Start Quizz" до complet-ването на 10тия въпрос.\nНакрая след complet-ване на Quizz-а ще има layout с космонавт протягайки ръце на горе държейки ни таба с:\n1)колко време ти е отнело да complet-неш Quizz-а\n2)колко въпроса са ти били верни и колко процента е това(Пример: 5/10 50%)\n3)ще има и надпис congratulations.',
    technologies: [
      "JavaScript",
      "Python",
      "HTML",
      "CSS",
      "C",
      "C++",
      "Django",
      "Unity3D",
      "Kotlin",
      "Java",
    ],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-11T19:14:17.932Z",
    __v: 13,
    grishoPoints: 0,
    mentorName: "Христо Борисов",
  },
  {
    _id: "6207c21f1266cd0c1d7de571",
    members: [
      {
        fullName: "Александър Христов",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644659524596.svg?background=%235ba4a9&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Александър Тодоров",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644669991615.svg?background=%234caa4b&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Петър Петров",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644700908374.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Симеон Симеонов",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1647034731743.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: false,
    repoLink: "https://github.com/AvatarZorak/HackTUES",
    teamName: "ASAP",
    projectName: "Learn To Space",
    projectDescription:
      "Образователна игра, чиято цел е да зароди интерес у младата аудитория на теми, свързани с Космос и астронавтика. Тя представлява игра тип Quest с различни нива, където трябва да се изпълняват определени мисии преди да може да се премине към следващото ниво. Когато някоя мисия бъде изпълнена успешно, потребителят получава награда - знание, свързана с Космоса.",
    technologies: ["Python", "HTML", "CSS", "JavaScript", "C"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-12T14:20:15.618Z",
    __v: 5,
    grishoPoints: 0,
    mentorName: "Самуил Георгиев",
  },
  {
    _id: "620815541266cd0c1d7df431",
    members: [
      {
        fullName: "Калоян Венков",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644698874473.svg?background=%23d55d5d&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Стилиян Мандалиев",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644697123617.svg?background=%2332d2c7&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Мартин Раднев",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644698364744.svg?background=%2301adb6&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Любомир Милев",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644748205070.svg?background=%23ffff00&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Росен Маринов",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644949908990.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/TopchetoEU/hacktues-8",
    teamName: "Nicer, inc.",
    projectName: "energ.io",
    projectDescription:
      "Мултиплейър игра, в която играчите са империи, борещи се за колонизация на галактиката, за да извлекат нужната им енергия",
    technologies: [],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-12T20:15:16.220Z",
    __v: 8,
    grishoPoints: 0,
    mentorName: "Димитър Ряпов",
  },
  {
    _id: "620a6c421266cd0c1d7e22bc",
    members: [
      {
        fullName: "Димитър Грозев",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644704299814.svg?background=%2329b352&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Силвия Антова",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644847509660.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Алекса Рашова",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644853403089.svg?background=%23e63b7a&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Далия Дацева",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644853381045.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Владимир Барев",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644778568122.svg?background=%230d44ab&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Hlebaria/HackTues8",
    teamName: "Хлебарите",
    projectName: "Здраве в космоса",
    projectDescription:
      "Уеб приложение, което дава възможност на потребителя да получи собствена генерирана диета с храни за космонавти. Също предлага и генериране на медицинска диагностика, базирана на въпроси, отговаряни от потрбителя, полезна за потенциални пътешествиници/туристи в космоса в бъдещето.",
    technologies: ["C", "HTML", "CSS", "JavaScript"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-14T14:50:42.678Z",
    __v: 7,
    grishoPoints: 0,
    mentorName: "Антон Янчев",
  },
  {
    _id: "620aae7b1266cd0c1d7e2abc",
    members: [
      {
        fullName: "Алекс Гечовски",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644866078748.svg?background=%23ff00c8&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Иваело Кръстев",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644868590364.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Данаил Божков",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644865973596.svg?background=%23ff0000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Валентин Найденов",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644938044392.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Лъчезар Велинов",
        studentClass: "11А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644939089737.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/Tulupite/HackTuesInfinity",
    teamName: "Tulupite",
    projectName: "Space Plazza",
    projectDescription: "Интерактивен сайт с образувателна цел!",
    technologies: ["HTML", "CSS", "Java", "SQL", "MySQL", "JavaScript", "Node.js", "React.js"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-14T19:33:15.758Z",
    __v: 14,
    grishoPoints: 0,
    mentorName: "Георги Аспарухов",
  },
  {
    _id: "620ac612d755c5e5b51c8d20",
    members: [
      {
        fullName: "Антоан Аргиров",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644845359933.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: true,
      },
      {
        fullName: "Екатерина Димитрова",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644873448728.svg?background=%23af32d2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "Явор Симеонов",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644869645369.svg?background=%23000000&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
      {
        fullName: "ВИКТОР ПЕНЕВ",
        studentClass: "9А",
        profilePicURL:
          "https://avatars.dicebear.com/api/adventurer/1644843554173.svg?background=%236F32D2&mood[]=happy&rotate=16&translateX=-1&translateY=3",
        isCaptain: false,
      },
    ],
    pendingInvites: [],
    pendingApplications: [],
    acceptsNewMembers: true,
    repoLink: "https://github.com/D14m0nDS/HackTues",
    teamName: "BDSM - Bible Discussion / Study Meeting",
    projectName: "Survival Galaxy",
    projectDescription:
      "Survival Galaxy е игра, в която играеш от гледната точка на човече, което бива изпращано на различни планети и техни спътници,\nза да плантира обработваеми платформи и да събира различни материали от тях. Но винаги трябва да бъдеш внимателен и да гледаш \nсвоето количество кислород, защото имаш определено количество от него, което можеш да презареждаш единствено в \nглавния космически кораб(кораба, с който пристигаш на планетата). Целта на играта е да събереш нужните ресурси от планетата \nи да отлетиш преди да ти е свършил кислорода, като постепенно можеш да подобряваш своите платформи, \nот които взимаш ресурси, и да подобряваш техния процент на получаване на ресурс.",
    technologies: ["Python"],
    approved: true,
    projectVotes: 0,
    createdAt: "2022-02-14T21:13:54.395Z",
    __v: 6,
    grishoPoints: 0,
    mentorName: "Тодор Балабанов",
  },
];
