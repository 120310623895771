<template>
  <div v-if="time >= 5">
    <form v-if="!adminAccess" @submit.prevent="submitForm">
      <p>Тайната дума:</p>
      <input v-model.trim="password" type="password" />
      <span v-if="wrongPassword">Wrong Password</span>
      <button @click="submitPassword">Submit</button>
    </form>
    <admin-content v-else :password="password"></admin-content>
  </div>
  <not-found v-else />
</template>

<script>
import axiosInstance from "../axiosInstance";
import AdminContent from "../components/AdminContent";
import NotFound from "../pages/NotFound.vue";

export default {
  data() {
    return {
      password: "",
      adminAccess: false,
      wrongPassword: false,
      errors: null,
      time: 0,
    };
  },
  components: {
    AdminContent,
    NotFound,
  },
  created() {
    this.updateTimer();
    this.timer = setInterval(this.updateTimer, 1000);
  },
  methods: {
    updateTimer() {
      this.time += 1;
    },
    async submitPassword() {
      try {
        await axiosInstance.post("/admin/verify-password", { password: this.password });

        this.adminAccess = true;
        this.wrongPassword = false;
      } catch (err) {
        this.$router.replace("/");
        this.adminAccess = false;
        this.wrongPassword = true;
      }
    },
  },
};
</script>

<style scoped>
span {
  margin: 10px;
  font-size: 12px;
  color: red;
  display: block;
}
button {
  display: block;
  margin: auto;
  margin-top: 10px !important;
}
</style>
