export default [
  {
    title: "Space Exploration",
    imgSrc: "/content/assets/space-exploration.webp",
    body: "Наблюдението на космическите тела е занимание, интересуващо учените хора от хилядолетия. Хората извършващи такива наблюдения, наречени от гръцки астрономи, се опитват, наблюдавайки звездите да достигнат до отговори на централни философски въпроси като произхода на вселената, времето, живота и човечеството, както и мястото му в света. Разбира се с напредването на времето в това занимание се вкарват все повече и повече подпомагащи технологии. Как бихте помогнали с вашите познания?",
    subBody:
      "Да се разработи решение, подпомагащо изследването на космически обекти (планети, астероиди, звезди). Всичко свързано с наблюдение и получаване на информация за космически обекти.",
  },
  {
    title: "Space Home",
    imgSrc: "/content/assets/space-home.webp",
    body: "Единственият начин за продължително пребиваване в космоса към момента е обитаването на космически станции. На тях се изучават дългосрочните ефекти върху човешкото тяло от живота в космоса, извършват се изследвания и наблюдения. Най-голямата такава станция е Международната космическа станция (МКС). В момента на нея пребивават 6-ма космонавти, като всеки екипаж прекарва там около половин година. Това със сигурност прави станцията дом на тези космонавти за известно време. А повече от всеки дом там има нужда от високотехнологични приспособления и джаджи, които да подпомагат живота на обитателите. Какви приспособления бихте използвали на МКС?",
    subBody:
      "Да се разработи решение, подпомагащо живота на хората в Международната космическа станция (МКС). ",
  },
  {
    title: "XSpace",
    imgSrc: "/content/assets/xspace.webp",
    body: "Космонавтите са хора като всички нас и като такива имат проблеми и нужда от решения. Подготовката за това да станеш космонавт е може би по-тежка от подготовката за коя да е друга професия. Един космонавт (за НАСА) трябва да има образователна степен в някоя STEM специалност или докторска степен в медицината, поне 1000 летателни часа, да премине физически изпит и да има много добре развити умения за общуване, работа в екип и лидерство. Излитането и престоя в космоса водят до различни промени в тялото и психиката на хората. Те понякога страдат от притеснение, безсъние и депресия, а физиологичните ефекти, изучавани на космическите станции, са една от най-големите пречки пред колонизацията на космоса. С какво можете да помогнете на тези хора?",
    subBody:
      "Да се разработи решение, подпомагащо астронавтите в професионален или в личен план след завръщането им от Космоса",
  },
  {
    title: "Wide Space",
    imgSrc: "/content/assets/wide-space.webp",
    body: "С напредването на времето хората имат възможността да зърнат космоса с все по-добри средства и да го гледат просто заради неговата красота. Много деца през последните 60 години са си мечтали да бъдат космонавти. За радост с развитието на технологиите за разпространение на данни има все повече информация, свързана с космоса, достигаща до все повече хора. Можете ли да направите решение, което да помогне на обикновения човек или детето, което иска да стане космонавт, да научи повече за космоса?",
    subBody:
      "Да се разработи решение, което помага на всички хора да се информират за теми свързани с космоса или да го наблюдават.",
  },
  {
    title: "Space Fun",
    imgSrc: "/content/assets/space-fun.webp",
    body: "Мисля че почти всички харесваме игрите, свързани с извънземни, космически кораби и междугалактически империи. Защо да не ги вкараме в любимия хакатон?",
    subBody: "Да се разработи развлекателен проект/игра на космическа тематика.",
  },
  {
    title: "Truly Everybody",
    imgSrc: "/content/assets/truly-everybody.webp",
    body: "Има хора по света, които за радост или беда са доста различни от повечето останали. Уврежданията в някои, вродени или придобити, не им позволяват да участват като другите в икономическия и социалния живот. С развитието на науката все повече от тях, благодарение на ново изобретение, получават шанс да живеят по-пълноценно. Можете ли да измислите високотехнологично решение, което да им помогне?",
    subBody:
      "Да се разработи решение, директно подпомагащо хората с увреждания, които им пречат да живеят и работят пълноценно.",
  },
];
