<template>
  <div :class="`CertificatePage ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <div class="BaseGlassModal Certificate" v-if="Object.keys(this.participant).length">
      <iframe
        height="100%"
        width="100%"
        type="application/pdf"
        view="Fit"
        :src="certificateLink"
        :alt="`Сертификат на ${participant.fullName}`"
      ></iframe>
    </div>

    <div class="BaseGlassModal CertificateBody" v-if="Object.keys(this.participant).length">
      Същестуването на горния сертификат потвърждава, че {{ this.participant.fullName }} е участник
      на Hack TUES Infinity.
    </div>

    <div v-else class="BaseGlassModal NoCertificate">Не съществува такъв сертификат</div>
  </div>
</template>

<script>
import participants from "../util/pageTexts/participants";

export default {
  name: "Certificate",
  data() {
    return {
      certificateLink: "",
      participant: {},
    };
  },
  beforeMount() {
    const { certificateId } = this.$route.params;
    const participant = participants.filter((e) => e._id == certificateId)[0];
    if (participant) {
      this.participant = participant;
      this.certificateLink = `https://infinity.hacktues.com/content/certs/${this.participant.fullName}.pdf#view=Fit`;
    }
  },
};
</script>

<style scoped>
.CertificatePage {
  position: relative;
  width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.Certificate {
  height: 70vh;
  min-width: 70vw;
  max-width: 90vw;
  padding: 1.5vw;
}

.Certificate img {
  height: 100%;
}

.Certificate,
.NoCertificate,
.CertificateBody {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.NoCertificate,
.CertificateBody {
  padding: 1vw;
  font-size: calc(var(--font-size) * 1.2);
}

.CertificateBody {
  max-width: 90vw;
  margin-top: 3vh;
}

.isMobile .CertificateBody,
.isMobile .NoCertificate {
  font-size: calc(var(--font-size) * 3);
}

.isMobile .Certificate {
  height: 90vh;
  width: 90vw;
}
</style>
