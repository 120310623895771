<template>
  <div class="svg" :style="[svgWrapperStyle, this.elemStyle]">
    <svg
      :viewBox="viewBox"
      :width="width"
      :height="height"
      :style="svgStyle"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath :id="this.id" clipPathUnits="objectBoundingBox" :transform="transform">
          <path :d="path" stroke="black" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Base-Svg",
  data() {
    return {
      id: Math.random().toString(16).substr(2, 9),
    };
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
    transform() {
      return `scale(${1 / this.width} ${1 / this.height})`;
    },
    svgStyle() {
      return `width: 100%; height: auto;`;
    },
    transformedStyle() {
      return JSON.stringify(this.elemStyle);
    },
    svgWrapperStyle() {
      return `shape-outside: url("#${this.id}"); clip-path: url("#${this.id}"); shape-rendering: crispEdges; overflow: visible; background-color: ${this.background}; backdrop-filter: blur(var(--blur));`;
    },
  },
  props: {
    width: {
      required: true,
    },
    height: {
      required: true,
    },
    path: {
      required: true,
    },
    background: {
      type: String,
      default: "var(--blur-background-color)",
    },
    elemStyle: {
      required: false,
    },
  },
};
</script>
