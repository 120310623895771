<template>
  <div>
    <div class="slider">
      <div class="slides">
        <div class="slide first">
          <img src="/content/site-images/our-team/whole-team/all1.avif" alt="Нашият Екип" />
        </div>
      </div>
    </div>
    <div class="person-boxes">
      <div
        class="person-box"
        v-for="(person, index) in ourteam"
        :key="index"
        :style="`${this.$store.getters.isMobile ? 'width: 100%;' : ''}`"
      >
        <span class="person-name">{{ person.name }}</span>
        <span class="person-department">{{ person.department }} </span>
        <div
          class="person-image-box"
          :style="`${this.$store.getters.isMobile ? 'height: 50vh;' : ''}`"
        >
          <img
            :src="person.pic"
            class="person-img"
            :alt="`Снимка на ${person.name}`"
            :style="`${this.$store.getters.isMobile ? 'top: 60%;' : ''} ${
              person.top ? `top: ${person.top};` : ''
            } `"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ourteam from "../util/pageTexts/ourteam";

export default {
  name: "OurTeam",
  data() {
    return {
      ourteam: null,
    };
  },
  beforeMount() {
    this.ourteam = [...ourteam];
  },
};
</script>

<style scoped>
.slider {
  width: 80%;
  margin: 0 auto;
  height: auto;
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c1753;
  padding: 1rem 0;
}

.slide {
  width: 100%;
  padding: 0.5rem 0.5rem;
}
.slide img {
  width: 100%;
  height: auto;
  border: var(--border);
  border-radius: var(--border-radius);
  -webkit-box-shadow: 5px 5px 19px 5px #000000;
  box-shadow: 0.1rem 0.1rem 1rem 0.1rem #000000;
}

.person-boxes {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: max-content;
  width: 85%;
  padding: 1rem 1rem;
  background-color: rgb(44, 23, 83, 0.5);
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: rgb(44, 23, 83, 0.7);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5em;
  margin: 0 auto;
  overflow: hidden;
}
.person-box {
  background-color: rgb(44, 23, 83, 0.5);
  border: var(--border);
  border-radius: var(--border-radius);
  width: 29%;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 21;
}
.person-name,
.person-department {
  color: white;
  background-color: var(--blur-background-color);
  font-size: 1.5rem;
  padding: 0.5rem;
  z-index: 21;
}

.person-image-box {
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
}

.person-img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 880px) {
  .person-name,
  .person-department {
    font-size: 3rem;
  }
  .slider {
    width: 95%;
  }
  .person-box {
    margin-top: 2rem;
    margin-right: 1rem;
  }
}
</style>
