export default [
  {
    title: null,
    body: "<span class='Span'>Скъпи съученици, поздравяваме Ви за решението да се  включите в предизвикателството, което предлага училищният хакатон. Тук ще намерите цялата информация, необходима за участие в хакатона и неговото провеждане. Поради епидемичната обстановка в страната откриването, работните дни, полуфиналите, финалите и награждаването на Hack TUES Infinity ще се проведат онлайн.</span>",
  },
  {
    title: "КАКВО Е Hack TUES?",
    body: "<span class='Span'>Hack TUES е хакатон (състезание по програмиране), в което отборите имат по-малко от 3 дни да направят авторски софтуерен/хардуерен проект по зададената от организаторите тема, използвайки всякакви технологии. Организира се ежегодно от ученици (най-често в 11 клас) от ТУЕС.</span>",
  },
  {
    title: "КАКВО ПРЕДСТОИ ПРЕДИ Hack TUES Infinity",
    body: ` <ul>
                <li><span class='Span'>Подготвили сме Ви за загрявка преди хакатона обучения на различни и интересни теми:</span></li>
                <ul class='Inner'>
                    <li><span class='Span'>Организация на работата и работа в екип - Милен Спасов  15.02 (вторник)  20:00 ч.</span></li>
                    <li><span class='Span'>Git & GitHub (много важно за хората, които не знаят как да ги използват*) - Мариян Видков - 17.02 (четвъртък)  20:00 ч.</span></li>
                    <li><span class='Span'>Embedded systems - Росен Витанов 24.02 (четвъртък) 20:00 ч.</span></li>
                    <li><span class='Span'>Работа с React JS - Лъчезар Топалов 28.02 (понеделник) 20:00 ч.</span></li>
                    <li><span class='Span'>Разработка на мобилни приложения с Flutter - Искрен Александров 04.03 (петък) 20:00 ч.</span></li>
                    <li><span class='Span'>Hack TUES - в какво се забърках? - цели да отговори на въпросите на всички, които ще участват за пръв път на събитието - Кирилка Ангелова и Калоян Георгиев - 07.03 (понеделник) 20:00 ч.</span></li>
                    <li><span class='Span'>Ще се проведат в Zoom. Ще откриете линк за стаята и повече информация за всеки един от тях на този линк. </span></li>
                </ul>
                <li><span class='Span'>Ще се проведат в Zoom. Формулярите за регистрация ще бъдат пуснати скоро, така че следете социалните мрежи и електронните си пощи.</span></li>
            </ul>
            <span class='Span'>* - Цялата си работа от хакатона трябва да съхранявате в GitHub хранилище, което ще ви бъде предоставено от организаторите в началото на времето за работа</span>
        `,
  },
  {
    title: "КАК ДА УЧАСТВАТЕ?",
    body: ` <span class='Span'>Първата стъпка е да се регистрирате:</span>
            <ul class='Inner'>
                <li><span class='Span'>Ако сте ученик от 8. до 12. клас в ТУЕС, можете да се регистрирате за участие на страницата за регистрация.</span></li>
                <li><span class='Span'>Регистрацията започва на 07.02 (понеделник) и свършва на 17.02 (четвъртък) 23:59:59. Лимитът на регистрираните отбори е 50, а ако се достигне преди крайния срок, регистрацията ще бъде затворена.</span></li>
                <li><span class='Span'>Когато се регистрирате, ще получите достъп до нова страница от сайта (Дискорд), на която ще получите инструкции за влизане и удостоверяване в Discord сървъра на събитието, което ще ви даде достъп до отборните ви канали. През Discord сървъра ще се осъществи цялата комуникация с организатори и ментори.</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Втората стъпка е да съставите потвърден отбор:</span>
            <ul class='Inner'>
                <li><span class='Span'>Лимитът на регистрираните отбори е 50.</span></li>
                <li><span class='Span'>Учениците имат правото сами да съставят отборите си, като в един отбор могат да участват между 3 и 5 човека. Отборът Ви е потвърден, когато в него участват минимум 3-ма души.</span></li>
                <li><span class='Span'>Помислили сме и за хората, които нямат отбор. Ето защо създадохме канал в Discord, чрез който можете да се включите в отбор, търсещ си още съотборници.</span></li>
                <li><span class='Span'>ВАЖНО: Ако до 17.02 включително не сте си намерили отбор, няма да можете да участвате на хакатона независимо дали сте се регистрирали като индивидуален участник.</span></li>
                <li><span class='Span'>Всеки индивидуален участник може да участва само в един отбор.</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Третата стъпка е предаването на декларация за участие:</span>
            <ul class='Inner'>
                <li><span class='Span'>Всеки участник в хакатона трябва да предаде декларация за участие по образец най-късно до 08.03. В противен случай няма да бъде допуснат до участие в Hack TUES Infinity. Декларациите можете да изтеглите от профила си в сайта.</span></li>
            </ul>
        `,
  },
  {
    title: "ДЕКЛАРАЦИЯ",
    body: `
        <span class="Span">За да участвате в Hack TUES Infinity, трябва да попълните декларация</span>
        <ul>
            <li><span class="Span"><a href="/content/documents/декларация-пълнолетни.odt">Декларация за пълнолетни</a></span></li>
            <li><span class="Span"><a href="/content/documents/декларация-непълнолетни.odt">Декларация за непълнолетни</a></span></li>
        </ul>
    `,
  },
  {
    title: "ПРОГРАМА",
    body: `
            <span class='Span'>Откриване:</span>
            <ul class='Inner'>
                <li><span class='Span'>Стрийма ще може да гледате в YouTube канала на училището.</span></li>
                <li><span class='Span'>Стартираме виртуално на 10.03 вечерта с откриваща церемония. </span></li>
                <li><span class='Span'>Тази година отново ще има лекция изненада със специални гости, която ще Ви въведе в темата на хакатона. Ще можете да задавате въпроси чрез платформата sli.do.</span></li>
                <li><span class='Span'>И тази година ще получите Вашата Hack TUES торбичка (тениски, стикери, различни рекламни материали). Очаквайте повече подробности от нас относно процедурата по раздаване.</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Работни дни:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Времето за работа по проектите започва след откриването и продължава до 13.03 07:59:59.</span></li>
                <li><span class='Span'>Главният комуникационен канал ще бъде Discord. В него ще получавате актуална информация за случващото се по време на събитието, ще можете да общувате с отбора си, с менторите и с организаторите.</span></li>
                <li><span class='Span'>ВАЖНО! - За проектите, участниците могат да използват каквито решат технологии и ресурси, но проектът ТРЯБВА да бъде АВТОРСКИ и да бъде реализиран В РАМКИТЕ НА хакатона. Нарушаването на тези правила може да доведе до дисквалификация.</span></li>
                <li><span class='Span'>По-важната информация ще Ви бъде изпратена и по имейл.</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Полуфинали:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Полуфиналите стартират  на 13.03 в 9:00.</span></li>
                <li><span class='Span'>За полуфиналите всеки отбор трябва да подготви презентация, която да е качена в GitHub хранилището и в която да са включени/описани:</span></li>
                <ul class='Inner'>
                    <li><span class='Span'>Главната идея и цел на проекта</span></li>
                    <li><span class='Span'>Използваните технологии</span></li>
                    <li><span class='Span'>Как е разпределена работата в екипа</span></li>
                    <li><span class='Span'>Снимки/видео на проекта</span></li>
                </ul>
                <li><span class='Span'>Те ще се провеждат паралелно. Ще получите график на 12.03 вечерта относно това кой отбор в кой полуфинал е и в какъв ред ще се представят проектите</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Финали: </span></li>
            <ul class='Inner'>
                <li><span class='Span'>Финалистите ще бъдат обявени по всички комуникационни канали след обедната почивка на 13.03. Те ще получат график в какъв ред ще представят проектите си.</span></li>
                <li><span class='Span'>Финалите стартират на 13.03 следобед.</span></li>
                <li><span class='Span'>Те ще бъдат излъчвани на живо и ще можете да ги гледате в YouTube канала на училището.</span></li>
            </ul>
            <br/><div class='Separator'></div><br/>
            <span class='Span'>Награждаване:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Ще се проведе  на 13.03 вечерта и ще бъде излъчвано в YouTube канала на училището.</span></li>
            </ul>
            <br /><div class='Seperator'>
            <span class='Span'>Технически подробности:</span>
            <ul class='Inner'>
                <li><span class='Span'>Полуфиналите и финалите ще се проведат в Zoom. Наградените отбори ще имат възможността да се включват в церемонията чрез платформата, за да “вземат” своята награда и да споделят какво беше за тях хакатонът.</span></li>
            </ul>
        `,
  },
  {
    title: "ТЕМАТИЧНИ МЕНТОРИ",
    body: `
        <ul>
            <li><span class='Span'>Освен чрез специалната си лекция, професионалистите в сферата на темите ще подпомогнат участниците като дадат обратна връзка за идеята на всеки един отбор.</span></li>
            <li><span class='Span'>Консултацията с менторите ще става по график и започва в 9:00 на първия работен ден - 11.03 (петък). Графикът ще бъде пуснат на участниците преди откриването.</span></li>
        </ul>
    `,
  },
  {
    title: "МЕНТОРИ",
    body: `
        <ul>
            <li><span class='Span'>Всеки отбор разполага с един ментор, който е част от отбора.</span></li>
            <li><span class='Span'>В случай, че Вашият ментор не може да помогне с проблем, който имате, всеки друг може да се отзове на помощ, след като опишете проблема си, свързан с проекта. Комуникацията с менторите ще се осъществява чрез нашия Discord сървър.</span></li>
            <li><span class='Span'>Освен ИТ-специалисти сред менторите тази година ще има и тематични ментори. Те няма да Ви помагат с техническите проблеми, но ще бъдат на Ваше разположение, за да доразвиете идеята на проектите си спрямо темата и Вашите цели.</span></li>
            <li><span class='Span'>Ще Ви бъде предоставен график на менторите, за да добиете представа каква активност ще има от тяхна страна в различните времеви диапазони:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>10:00 до 14:30</span></li>
                <li><span class='Span'>14:30 до 19:00</span></li>
            </ul>
            <li><span class='Span'>Целта на менторите е да Ви напътстват по време на работните дни.</span></li>
        </ul>
    `,
  },
  {
    title: "ОЦЕНЯВАНЕ ОТ МЕНТОРИТЕ",
    body: `
        <ul>
            <li><span class='Span'>В края на всеки един работен ден отборите ще получат оценка от специален ментор, специалист в бранша, който отговаря за съответния отбор.</li>
            <li><span class='Span'>Оценката се поставя на базата на следните критерии:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Техническа трудност - Труден за постигане ли е даденият проект в рамките на хакатона? Използват ли се неща за напреднали програмисти или е по-скоро проект за начинаещи?</span></li>
                <li><span class='Span'>Работа в екип - Разпределят ли се равномерно задачите в отбора? Сработват ли се участниците помежду си?</span></li>
                <li><span class='Span'>Качествен код/хардуер</span></li>
                <li><span class='Span'>Реализация - Каква част от проекта е завършена и работи? Ако отборът не е довършил проекта, то каква част е направена?</span></li>
            </ul>
            <li><span class='Span'>Оценката на менторите ще се използва за разпределяне на отборите в полуфиналите на хакатона и избор на “Финалист на менторите”.</span></li>
        </ul>
    `,
  },
  {
    title: "ПОЛУФИНАЛИ И ФИНАЛИ",
    body: ` <ul>
                <li><span class='Span'>Всеки отбор ще представи своя проект пред жури, съставено от преподаватели в ТУЕС, ТУЕС алумни, представители на компаниите-спонсори и специалисти в IT бранша.</span></li>
                <li><span class='Span'>На полуфиналите всеки отбор ще има 8 минути за представяне и 7 минути време за въпроси от страна на журито.</span></li>
                <li><span class='Span'>Времето за презентиране и въпроси на финалите ще бъде обявено когато свърши регистрацията.</span></li>
                <li><span class='Span'>ВАЖНО: Всеки отбор е длъжен да спазва времевия си диапазон. След изтичането на времето водещите ще прекъснат отбора и ще се премине към сесията за въпроси.</span></li>
                <li><span class='Span'>Най-добрите 3 проекта от финалите ще бъдат наградени съответно с 1., 2. и 3. място. </li>
                <li><span class='Span'>Ще бъде показана класация на отборите:</li>
                <ul class='Inner'>
                    <li><span class='Span'>отделни класации за всеки един полуфинал;</span></li>
                    <li><span class='Span'>обща класация за финалистите.</span></li>
                </ul>
                <li><span class='Span'>Каква част от проекта е завършена?</span></li>
                <li><span class='Span'>Качествена разработка - добре ли е разработен проектът (качествен код и добър хардуер)?</span></li>
                <li><span class='Span'>Организация (структура) на проекта - виждат ли се ясно отделните елементи в проекта и връзките между тях?</span></li>
                <li><span class='Span'>Удобен ли е да се адаптира и надгражда проектът - могат ли лесно да се правят промени и да се добавят нови елементи?</span></li>
                <li><span class='Span'>До колко е удобен за употреба (user experience)?</span></li>
                <li><span class='Span'>Работа в екип - разпределени ли са задачите според индивидуалните възможности на всеки от екипа?</span></li>
                <li><span class='Span'>Презентация - колко добре е представен проектът пред журито (0 - 5 т.):</span></li>
                <li><span class='Span'>Засегнати ли са въпросите от темплейта - учениците ще получат темплейт, в който е показано какво е задължително да включат в презентацията си.</span></li>
                <li><span class='Span'>Презентиране:</span></li>    
                <li><span class='Span'>Екипът подготвен ли е за презентиране?</span></li>
            </ul>   
        `,
  },
  {
    title: "ОЦЕНЯВАНЕ ОТ ЖУРИТО",
    body: ` <ul>
                <li><span class='Span'>Идея - какво е искал да направи отборът (0 - 2 т.):</span></li>
                <ul class='Inner'>
                    <li><span class='Span'>Доколко проектът е по темата?</span></li>
                    <li><span class='Span'>Иновативна ли е идеята - има ли много подобни идеи или не?</span></li>
                    <li><span class='Span'>Доколко е приложима идеята - може ли да се използва в действителност?</span></li>
                </ul>
                
                <li><span class='Span'>Функционалност - какво е успял да реализира отборът (0 - 3 т.):</span></li>
                <ul class='Inner'>
                    <li><span class='Span'>Обхват - какви функционалности има проектът?</span></li>
                    <li><span class='Span'>Доколко реализираните функционалности позволяват, проектът да се използва по предназначение?</span></li>
                </ul>
                <li><span class='Span'>Реализация - колко добре е реализиран проектът (0 - 10 т.):</span></li>
                <ul class='Inner'>
                    <li><span class='Span'>Каква част от проекта е завършена?</span></li>
                    <li><span class='Span'>Качествена разработка - добре ли е разработен проектът (качествен код и добър хардуер)?</span></li>
                    <li><span class='Span'>Организация (структура) на проекта - виждат ли се ясно отделните елементи в проекта и връзките между тях?</span></li>
                    <li><span class='Span'>Удобен ли е да се адаптира и надгражда проектът - могат ли лесно да се правят промени и да се добавят нови елементи?</span></li>
                    <li><span class='Span'>До колко е удобен за употреба (user experience)?</span></li>
                    <li><span class='Span'>Работа в екип - разпределени ли са задачите според индивидуалните възможности на всеки от екипа?</span></li>
                </ul>
                <ul class='Inner'>
                    <li><span class='Span'>Презентация - колко добре е представен проектът пред журито (0 - 5 т.):</span></li>
                    <li><span class='Span'>Засегнати ли са въпросите от темплейта - учениците ще получат темплейт, в който е показано какво е задължително да включат в презентацията си.</span></li>
                    <li><span class='Span'>Презентиране:</span></li>
                    <ul class='Inner'>
                        <li><span class='Span'>Екипът подготвен ли е за презентиране?</span></li>
                        <li><span class='Span'>Отговаря ли на въпросите на журито или се опитва да ги избегне?</span></li>
                    </ul>
                </ul>
            </ul>   
        `,
  },
  {
    title: "ОТГОВОРНОСТ",
    body: `
    <ul>
        <li><span class='Span'>Участниците са длъжни да се съобразяват с програмата за провеждане на хакатона (откриване, време за работа, представяне пред журито и др.), да спазват всички инструкции на организаторите на събитието и доброволците, които помагат за организирането му.</span></li>
        <li><span class='Span'>Организаторите не носят отговорност за Вашата комуникация със съотборниците Ви по време на хакатона. Препоръчваме Ви да спазвате наложените към момента противоепидемични мерки.</span></li>
    </il>`,
  },
  {
    title: "ДИСКВАЛИФИКАЦИЯ",
    body: `
        <span class='Span'>Трябва да знаете, че ще се налага дисквалификация в следните случаи:</span>
        <ul class='Inner'>
            <li><span class='Span'>Ако даден проект не е авторски, съответният отбор може да бъде дисквалифициран.</span></li>
            <li><span class='Span'>Организаторите на събитието може да дисквалифицират участник, ако поведението на участника е непристойно или пречи на провеждането на хакатона.</span></li>
            <li><span class='Span'>Организаторите си запазват правото да дисквалифицират отбор, ако проектът им не е подходящ за събитието, има неморално съдържание или зловредна цел.</span></li>
            <li><span class='Span'>Отбор, който не изпълнява инструкциите на организаторите, може да бъде дисквалифициран.</span></li>
        </ul>
    `,
  },
  {
    title: "ТОРМОЗ",
    body: `
        <ul>
            <li><span class='Span'>Не се толерира тормоз над екипа на Hack TUES Infinity, доброволците и  участниците в събитието, под каквато и да е форма.</span></li>
            <li><span class='Span'>Ако сте подложен на тормоз, забележите, че някой друг е подложен на тормоз, или имате някакви други проблеми, моля свържете се с член от екипа на хакатона незабавно.</span></li>
        </ul>
    `,
  },
  {
    title: "ПОЛИТИКА ЗА БИСКВИТКИ И ПОВЕРИТЕЛНОСТ",
    body: `
        <span class='Span'>За да подобрим представянето на уеб сайта си и Вашето потребителско преживяване, понякога използваме HTTP-бисквитки (HTTP cookies), или просто бисквитки. С използването на сайта Вие се съгласявате с това.</span> 
        <br /><br />
        <span class='Span'>Какво представляват бисквитките?</span>
        <ul class='Inner'>
            <li><span class='Span'>Бисквитките са малки текстови файлове, които се запазват на Вашия компютър или мобилно устройство, когато посещавате нашия уеб сайт. Те позволяват на уеб сайта да запаметява Вашите действия и предпочитания, за определен период от време, за да не се налага да ги въвеждате всеки път, когато посещавате сайта или преминавате от една страница към друга, което ни помага да Ви предоставяме съдържание, което смятаме, че ще бъде полезно и интересно за Вас.</span></li>
        </ul>
        <br />
        <span class='Span'>Как и какви бисквитки използваме?</span>
        <ul class='Inner'>
            <li><span class='Span'>Функционални бисквитки</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Използваме бисквитки, които позволяват на уеб сайта да запаметява Вашите действия и предпочитания (като например потребителско име, език, възраст, размер на шрифта и други настройки за показване) за определен период от време, за да не се налага да ги въвеждате всеки път, когато посещавате сайта или преминавате от една страница към друга.</span></li>
            </ul>
            <br/><br/>
            <li><span class='Span'>Бисквитки за сигурност</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Използваме бисквитки от гледна точка на сигурност, чиято цел е да предотвратяват измамническо използване на идентификационни данни за вход, както и за защита информацията от неупълномощени страни.</span></li>
            </ul>
        </ul>
        <br /><br />
        <span class='Span'>Лични данни от бисквитките</span>
        <ul class='Inner'>
            <li><span class='Span'>Личните данни събрани от бисквитките се използват единствено и само за осъществяването на конкретни функции в сайта, свързани със самия потребител.</span></li>
        </ul>
        <br /><br />
        <span class='Span'>Как да управлявате бисквитките</span>
        <ul class='Inner'>
            <li><span class='Span'>Повечето стандартни браузъри дават възможност да променяте настройките за „бисквитки“. Обикновено можете да намерите тези настройки в меню “опции” или “предпочитания” на вашия браузър. Повече информация относно това как да управлявате бисквитки (включително да как да ги изтриете) според вида на браузъра, който ползвате, може да намерите на следните линкове:</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Internet Explorer – <a href="https://support.microsoft.com/bg/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">тук</a></span></li>
                <li><span class='Span'>Microsoft Edge – <a href="https://support.microsoft.com/bg/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10">тук</a></span></li>
                <li><span class='Span'>Mozilla Firefox – <a href="https://support.mozilla.org/bg/kb/%D0%B1%D0%B8%D1%81%D0%BA%D0%B2%D0%B8%D1%82%D0%BA%D0%B8-%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F-%D1%81%D0%B0%D0%B9%D1%82%D0%BE%D0%B2%D0%B5-%D1%81%D1%8A%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BD%D0%B0-%D0%B2%D0%B0%D1%88%D0%B8%D1%8F-%D0%BA%D0%BE%D0%BC%D0%BF%D1%8E%D1%82%D1%8A%D1%80?redirectslug=Cookies&redirectlocale=en-US">тук</a></span></li>
                <li><span class='Span'>Google Chrome – <a href="https://support.google.com/chrome/answer/95647?hl=bg&ref_topic=14666">тук</a></span></li>
                <li><span class='Span'>Opera – <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/">тук</a></span></li>
                <li><span class='Span'>Safari – <a href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">тук</a></span></li>
                <li><span class='Span'>iOS – <a href="https://support.apple.com/en-us/HT201265">тук</a></span></li>
            </ul>
        </ul>
        <br /><br />
        <span class='Span'>Поверителност</span>
        <ul class='Inner'>
            <li><span class='Span'>Чрез регистрация в Hack TUES Infinity Вие се съгласявате с нашата политика за поверителност.</span></li>
            <ul class='Inner'>
                <li><span class='Span'>Hack TUES Infinity не предоставя Вашите данни на трети лица</span></li>
                <li><span class='Span'>Вашите данни не се използват за персонализация</span></li>
                <li><span class='Span'>Всичките данни, които сте предоставили на Hack TUES Infinity, се използват единствено за организацията на събитието от <a href="/ourteam">организационния екип</a></span></li>
                <li><span class='Span'>Всичките лични данни ще бъдат изтрити от базата данни на Hack TUES Infinity след края на събитието</span></li>
            </ul>
        </ul>
    `,
  },
  {
    title: null,
    body: `<span class='Span'>Искрено се надяваме, че ще се забавлявате и че ще измислите уникални проекти и решения.</span>`,
  },
];
