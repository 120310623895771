import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      refreshToken: null,
      id: null,
      discordId: null,
      emailVerified: true,
      email: null,
      hasTeam: false,
      teamId: null,
      isCaptain: false,
    };
  },
  mutations,
  actions,
  getters,
};
