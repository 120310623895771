import axiosInstance from "../../../axiosInstance";
import createTokenPair from "../../../util/createTokenPair";
import errorHandler from "../../../util/errorHandler";

export default {
  async logout(context) {
    localStorage.removeItem("refreshToken");

    context.commit("setUser", {
      refreshToken: null,
      id: null,
    });

    context.commit("setHasTeam", {
      hasTeam: false,
      teamId: null,
      isCaptain: false,
    });

    context.commit("setEmailVerified", {
      emailVerified: false,
      email: null,
    });

    context.commit("setDiscordId", { discordId: "" });
  },
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async signup(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },
  async tryLogin(context) {
    try {
      const { success, accessToken, refreshToken, id } = await createTokenPair(context);
      if (!success) {
        return;
      }
      const {
        data: {
          response: { emailVerified, email, hasTeam, teamId, discordId, isCaptain },
        },
      } = await axiosInstance.post("/user/get-primary-user-info", null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      await context.commit("setUser", {
        refreshToken,
        id,
      });

      context.commit("setHasTeam", {
        hasTeam,
        teamId,
        isCaptain,
      });

      await context.commit("setEmailVerified", {
        emailVerified,
        email,
      });

      context.commit("setDiscordId", { discordId });
    } catch (err) {
      if (errorHandler(err)?.errorNames?.includes("TokenExpiredError")) {
        localStorage.removeItem("refreshToken");
        await context.commit("setUser", {
          refreshToken: null,
          id: null,
        });
      }
    }
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let url = "/auth/sign-in/";
    let post_payload = {
      identifier: payload.identifier,
      password: payload.password,
    };

    if (mode === "signup") {
      url = "/auth/sign-up/";
      post_payload = {
        fullName: payload.fullName,
        email: payload.email,
        studentClass: payload.studentClass,
        password: payload.password,
        passwordConfirm: payload.passwordConfirm,
        telNumber: payload.telNumber,
        technologies: payload.technologies,
        shirtSize: payload.shirtSize,
        allergies: payload.allergies,
        eatingPreferences: payload.eatingPreferences,
        profilePicURL: payload.profilePicURL,
      };
    }

    const headers = {
      "Content-Type": "application/json",
    };

    const sendPostRequest = async () => {
      const {
        data: {
          response: { accessToken, refreshToken, id },
        },
      } = await axiosInstance.post(url, post_payload, {
        headers,
      });

      localStorage.setItem("refreshToken", refreshToken);

      await context.commit("setUser", {
        refreshToken,
        id,
      });
      const {
        data: {
          response: { emailVerified, email, hasTeam, teamId, discordId, isCaptain },
        },
      } = await axiosInstance.post("/user/get-primary-user-info", null, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      context.commit("setHasTeam", {
        hasTeam,
        teamId,
        isCaptain,
      });

      context.commit("setEmailVerified", {
        emailVerified,
        email,
      });

      context.commit("setDiscordId", { discordId });
    };
    await sendPostRequest();
  },
};
