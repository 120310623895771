<template>
  <base-svg-button
    @btnClicked="clicked"
    :wrapperStyle="style"
    width="86.01"
    height="86.01"
    strokeWidth="3"
    path="M2 28.5001V13.0001C2.5 9.33339 5.7 2.10006 14.5 2.50006H70.5C75 2.50004 84 5 84 15C84 25 84 56.1667 84 70.5C84.1667 74.6667 82 83.1 72 83.5H15C10.6667 83 2 79.9 2 71.5V56V55.5M7.5 42H64.5M64.5 42L43.5 21M64.5 42L43.5 63"
  />
</template>

<script>
export default {
  name: "ExitButton",
  props: {
    style: { required: false },
    clicked: {
      required: true,
    },
  },
};
</script>
