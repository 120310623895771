<template>
  <!-- <div class="BaseGlassModal"> -->
  <div :class="`DistributionPage ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <p class="title">Финали</p>
    <div class="Ranking">
      <!-- <p>
        {{semifinals}}
      </p> -->
      <div v-for="(finalist, index) in semifinals" :key="index" class="MentorWrapper">
        <div class="BaseGlassModal Semi">
          <br />
          <div v-for="(team, index) in finalist" :key="index">
            <div
              v-if="index == 0"
              style="display: flex; justify-content: space-between; padding: 0.5vw"
            >
              <p style="font-size: 1vw; color: white">Отбор</p>
              <p style="font-size: 1vw; color: white">Място</p>
            </div>
            <hr v-if="index == 0" style="margin-bottom: 1vw; background-color: white" />
            <div
              style="
                line-height: 1.3;
                display: flex;
                justify-content: space-between;
                padding: 0.5vw;
              "
            >
              <p>
                {{ team.teamName }}
              </p>
              <p>{{ index + 1 }}</p>
            </div>
            <!-- <br> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import semi from "../util/pageTexts/semifinals";

export default {
  name: "DistributionPage",
  data() {
    return {
      semifinals: [],
    };
  },
  beforeMount() {
    this.semifinals = [...semi];
  },
};
</script>

<style scoped>
.title {
  color: white;
  font-size: 5vw;
  padding-bottom: 2vw;
}

.isMobile .title {
  color: white;
  font-size: 15vw;
  padding-bottom: 2vw;
  margin-top: 15vw;
}

.SemifinalTitle {
  color: white;
  font-weight: bold;
}

.DistributionPage {
  position: relative;
  padding-top: 2vh;
  width: 99vw;
}

.MentorWrapper {
  position: relative;
  display: inline-block;
  width: max-content;
  margin-right: calc(1vh + 1vw);
  margin-bottom: calc(1vh + 1vw);
  vertical-align: top;
}

.TakenMentor {
  width: 100%;
  height: 10%;
  border-radius: var(--border-radius);
  position: absolute;
  margin: 0px !important;
  top: 0;
}

.DistributionPage span,
.DistributionPage input,
.DistributionPage button {
  font-size: 1.5rem;
}

.isMobile span,
.isMobile input,
.isMobile button {
  font-size: 4rem;
}
.isMobile .Semi {
  padding: 3vh;
  display: block;
  position: relative;
  margin-right: 0px;
  height: max-content;
  width: 80vw;
}

.isMobile .Semi span {
  max-width: 60vw;
}

.isMobile .Ranking {
  position: relative;
  display: block;
  width: 100vw;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
}

.isMobile .SetMentorButton {
  position: relative;
  display: block;
  left: 0%;
  bottom: 0%;
  margin-top: 3vh;
  transform: none;
  margin-left: auto;
  margin-right: auto;
}

.Semi {
  --font-size-here: calc(var(--font-size) * 1.7);
  position: relative;
  width: 25vw;
  padding: 2%;
  backdrop-filter: blur(var(--blur));
  overflow: hidden;
  vertical-align: top;
  min-height: 30vw;
  min-width: 15vw;
}

.Semi p {
  /* text-align: center; */
  display: flex;
  position: relative;
  /* margin-left: auto;
  margin-right: auto; */
  width: max-content;
  max-width: 22vw;
  margin-bottom: 1vh;
  font-size: 1rem;
  color: rgb(218, 218, 218);
}

.isMobile .Semi p {
  font-size: 5rem !important;
}

.Technologies {
  width: max-content;
  height: max-content;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vw;
}

.MentorImage {
  width: 80%;
  aspect-ratio: 1;
  height: auto;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin: 3vh auto;
}

.MentorImage img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SearchTermInput {
  all: unset;
  color: white;
  border: var(--default-ts) solid rgb(123, 87, 255);
  border-radius: var(--border-radius);
  font-size: calc(var(--font-size) * 1.3);
  text-align: left;
  width: max-content;
  background-color: rgb(79, 26, 177);
  margin-bottom: 1.5vh;
  padding: 1vw;
}

.SearchTermInput::placeholder {
  color: white;
}

.SetMentorButton {
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, 0%);
}
</style>
