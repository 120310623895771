<template>
  <div :class="`ThemePage ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <div class="MainThemeWrapper">
      <img id="Grisho" src="/content/assets/grisho.png" alt="Grisho" />
      <img id="Mayura" src="/content/assets/mayura.png" alt="Mayura" />
      <div class="BaseGlassModal MainTheme">
        <span>Space for everybody</span><br />
        <span id="Sub"
          >Да се разработи решение свързано с космоса, космологията или космонавтиката.</span
        >
      </div>
    </div>
    <div class="Themes">
      <div
        class="BaseGlassModal Theme"
        :style="`${!(index % 2) ? 'margin-right: 6.6vw' : ''} `"
        v-for="(theme, index) in themes"
        :key="index"
      >
        <div class="ThemeTitle">
          <img :src="theme.imgSrc" alt="theme.imgAlt" />
          <span>{{ theme.title }}</span>
        </div>
        <span class="ThemeBody">
          {{ theme.body }}
        </span>
        <br />
        <span class="ThemeBody">{{ theme.subBody }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import themes from "../util/pageTexts/themes";

export default {
  name: "ThemePage",
  data() {
    return {
      themes: [],
    };
  },
  mounted() {
    this.themes = [...themes];
  },
};
</script>

<style scoped>
.ThemePage {
  width: 100%;
  padding-top: 5vh;
}

.MainThemeWrapper {
  position: relative;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
}

.MainTheme {
  position: relative;
  width: 45vw;
  padding: 2vh;
  border: var(--thick-border);
  border-radius: 10vw;
}

.MainTheme span {
  font-size: calc(var(--font-size) * 3.5);
}

.MainTheme #Sub {
  font-size: calc(var(--font-size) * 1.5);
}

.MainThemeWrapper img {
  width: 5vw;
  height: auto;
  position: absolute;
  z-index: -1;
}

#Grisho {
  transform: rotate(-45deg);
  top: -5%;
  left: -5%;
}

#Mayura {
  transform: rotate(45deg);
  top: -5%;
  right: -5%;
}

.Themes {
  margin-top: 3vh;
}

.Theme {
  width: 40vw;
  min-height: 100vh;
  height: max-content;
  display: inline-block;
  vertical-align: top;
  border: var(--thick-border);
  margin-bottom: 6vh;
}

.ThemeTitle {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.ThemeTitle {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.ThemeTitle span,
.ThemeTitle img {
  display: inline-block;
  height: 100%;
}

.ThemeTitle img {
  height: 20vh;
  margin-right: 60%;
}

.ThemeTitle span {
  width: 45%;
  height: max-content;
  font-size: calc(var(--font-size) * 2);
  position: absolute;
  max-width: 55%;
  word-break: normal;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
}

.ThemeBody {
  display: block;
  position: relative;
  font-size: calc(var(--font-size) * 1.4);
  margin: 2.5vh;
}

.isMobile .Theme {
  width: 80vw;
  min-height: 0px;
  margin-right: auto !important;
  margin-bottom: 3vh;
}

.isMobile .ThemeBody {
  font-size: calc(var(--font-size) * 3.5);
}

.isMobile .MainTheme span,
.isMobile .ThemeTitle span {
  font-size: calc(var(--font-size) * 5);
}

.isMobile .MainTheme {
  width: 70vw;
}

.isMobile #Sub {
  font-size: calc(var(--font-size) * 3.5);
}

.isMobile .MainThemeWrapper img {
  width: 8vh;
}

.isMobile #Grisho {
  top: -8%;
  left: -8%;
}

.isMobile #Mayura {
  top: -8%;
  right: -8%;
}

.isMobile .ThemeTitle img {
  height: 15vh;
}
</style>
