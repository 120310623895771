<template>
  <div
    :class="`ArrayProp ${this.$store.getters.isMobile ? 'APisMobile' : ''}`"
    :style="`${
      isColored ? `background-color: rgba(${generateRgba(arrayProp)}, 0.6);` : ''
    }margin-right: ${this.$store.getters.isMobile ? '3vw' : '1vw'}; margin-bottom: ${
      this.$store.getters.isMobile ? '3vw' : '1vw'
    }; ${style}`"
  >
    <span :style="spanStyle">{{ arrayProp }}</span>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: calc(var(--dim) * 1.3); height: calc(var(--dim) * 1.3); transform: translate(82%, -82%);'
          : ''
      };`"
      v-if="isEdit"
      class="X"
      @click="this.$emit('clicked-x')"
    >
      <img src="/content/assets/X.svg" alt="X" />
    </div>
  </div>
</template>

<script>
import generateRgbaFromString from "../../util/generateRgbaFromString";

export default {
  name: "Prop",
  props: {
    type: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    arrayProp: {
      type: String,
      required: true,
    },
    isColored: {
      type: Boolean,
      default: false,
    },
    spanStyle: String,
    style: String,
  },
  methods: {
    generateRgba(string) {
      return generateRgbaFromString(string);
    },
  },
};
</script>

<style scoped>
.ArrayProp {
  position: relative;
  width: max-content;
  height: max-content;
  padding: 0vw 1vw 0vw 1vw;

  border: var(--border);
  border-radius: calc(var(--border-radius) * 0.5);
  display: inline-flex;
  color: white;
}

.ArrayProp span {
  font-size: 1rem;
  width: 100%;
  display: flex;
  padding: 0.5vw 0vw 0.5vw 0vw;
  justify-content: center;
  align-items: center;
}

.APisMobile span {
  font-size: 3.5rem;
}

.APisMobile .X {
  --dim: 3vw;
}

.X {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(75%, -75%);
  --dim: 1.32vw;
  width: var(--dim);
  height: var(--dim);
  border-radius: 50%;
  border: 0.1vw solid white;
  cursor: pointer;
}

.X img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}
</style>
