<template>
  <loading v-if="loading" />
  <div v-else class="Notifications">
    <h1 class="Notification">Известия</h1>
    <div v-if="userInfo.notifications.length > 0 && isNotifications()" style="margin-top: 4vw">
      <div
        v-for="(notification, index) in userInfo.notifications"
        :key="index"
        class="Notification"
      >
        <div v-if="notification.type == 'invite' && (notification.seen == false || this.showSeen)">
          <!-- {{ notification }} -->
          <div style="justify-content: space-between; display: flex">
            <img
              title="Покана"
              src="/content/assets/envelope-regular.svg"
              alt="X"
              class="filter-white icon"
              style=""
            />
            <span>Покана за отбор "{{ notification.information.teamName }}"</span>
            <img
              @click="seenRequest(notification)"
              title="Затваряне"
              src="/content/assets/X.svg"
              alt="X"
              class="icon"
            />
          </div>
          <button
            @click="redirectUser(notification.information.url)"
            class="BaseWhiteButton"
            style="width: 90%; margin-top: 2vw"
          >
            Приемане
          </button>
        </div>
        <div
          v-if="notification.type == 'application' && (notification.seen == false || this.showSeen)"
        >
          <!-- {{ notification }} -->
          <div style="justify-content: space-between; display: flex">
            <img
              title="Кандидат"
              src="/content/assets/hand-paper-regular.svg"
              alt="X"
              class="filter-white icon"
              style=""
            />
            <span
              ><span class="bold" style="padding: 0vw !important">{{
                notification.information.applicantName
              }}</span>
              кандидатства за присъединяване към отбора</span
            >
            <img
              @click="seenRequest(notification)"
              title="Затваряне"
              src="/content/assets/X.svg"
              alt="X"
              class="icon"
            />
          </div>
          <button
            @click="redirectUser(notification.information.url)"
            class="BaseWhiteButton"
            style="width: 90%; margin-top: 2vw"
          >
            Приемане
          </button>
        </div>
      </div>
    </div>
    <div v-else class="Notification" style="margin-top: 4vw; text-align: center; padding: 4vw">
      <span style="font-size: 1.5vw">Няма нови известия 😸</span>
      <br />
      <br />
      <button @click="this.showSeen = true" class="BaseWhiteButton">Покажи стари?</button>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import createTokenPair from "../util/createTokenPair";

export default {
  name: "Notifications",
  data() {
    return {
      userInfo: null,
      loading: true,
      removing: false,
      showSeen: false,
    };
  },
  async mounted() {
    this.loading = true;
    const { success, accessToken } = await createTokenPair(this.$store);
    if (!success) {
      this.$router.go(0);
      return;
    }
    const userInfo = await (
      await axiosInstance.get("/user/get-user-token", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
    ).data.response;

    this.userInfo = userInfo;

    this.loading = false;

    // console.log(userInfo.notifications);
  },
  methods: {
    async getTeamName(teamId) {
      const response = await axiosInstance.get(`/team/get-team/${teamId}`);
      return response.data.response.teamName;
    },
    async seenRequest(notification) {
      if (this.removing == true) {
        return;
      }

      this.removing = true;

      const notification_id = notification.id;

      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }

        await axiosInstance.post(
          "/user/see-notification",
          {
            id: notification_id,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        const notifications = this.userInfo.notifications;
        for (const not of notifications) {
          if (not == notification) {
            not.seen = true;
          }
        }
        this.removing = false;
      } catch (error) {
        console.log(error);
        this.removing = false;
      }
    },
    redirectUser(url) {
      window.location.href = url;
      // this.seenRequest();
    },
    isNotifications() {
      if (this.showSeen == true) {
        return true;
      }
      const notifications = this.userInfo.notifications;
      let flag = false;
      for (const not of notifications) {
        if (not.seen == false) {
          flag = true;
        }
      }
      return flag;
    },
  },
};
</script>

<style scoped>
h1 {
  padding: 1vw;
  font-size: calc(var(--font-size) * 2);
  width: 50vw !important;
}
.Notifications {
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.Notification {
  width: 35vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius);
  border: var(--border);
  color: white;
  background-color: var(--blur-background-color);
  backdrop-filter: blur(var(--blur));
  margin-bottom: 3vh;
  padding: 1.5vw;
}

.filter-white {
  filter: invert(100%) sepia(92%) saturate(0%) hue-rotate(194deg) brightness(107%) contrast(100%);
}

.icon {
  width: 2.5vw;
}

.Title span,
.Title h1 {
  font-size: calc(var(--font-size) * 2) !important;
}

span {
  font-size: 1.5vw;
  /* position: absolute; */
  padding: 1.25vw;
}

.bold {
  font-weight: 800;
}

@media only screen and (max-width: 750px) {
  .Notifications h1 {
    font-size: calc(var(--font-size) * 6);
    width: 70vw !important;
  }
  .Notification {
    width: 70vw;
  }
  .Title span,
  .Title h1 {
    font-size: calc(var(--font-size) * 5) !important;
  }
  span {
    font-size: 2.5vw;
  }
}
</style>
