<template>
  <div class="Wrapper" :style="style">
    <div :class="`AbsoluteWrapper ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
      <div class="SpansOutline"></div>
      <div class="ArrayProps">
        <div class="ArrayPropsWrapper">
          <div
            v-if="arrayProps && arrayProps?.length"
            class="gridWrapper"
            :style="`padding-bottom: ${
              isEdit ? (this.$store.getters.isMobile ? '7vh' : '1.5vw') : '0vw'
            }`"
          >
            <div class="grid">
              <Prop
                :key="index"
                @clicked-x="this.$emit('clicked-x', type, index)"
                v-for="(arrayProp, index) in arrayProps"
                :id="index"
                :isColored="isColored"
                :isEdit="isEdit"
                :arrayProp="arrayProp"
                :type="type"
              />
            </div>
          </div>
          <span class="Hmm" v-else-if="!isEdit && !arrayProps.length">
            Хмм, тук е доста празно
          </span>
          <button
            type="button"
            style="font-weight: 600"
            v-if="isEdit"
            @click="this.$emit('clicked-add', type)"
          >
            {{ `Добави ${type == "allergies" ? "алергия" : "технология"}` }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prop from "./ui/Prop.vue";

export default {
  name: "ArrayProps",
  emits: ["clicked-add", "clicked-x"],
  props: {
    arrayProps: {
      required: true,
      type: Array,
    },
    style: { required: false },
    isColored: {
      default: false,
    },
    isEdit: {
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    Prop,
  },
};
</script>

<style scoped>
.Wrapper {
  position: relative;
  scrollbar-width: var(--scrollbar-width);
  text-align: left;
  scrollbar-color: rgba(255, 255, 255, 0.6) rgba(255, 255, 255, 0);
}

.SpansOutline {
  height: var(--array-props-height);
  width: var(--spans-outline-width);
  border: var(--border);
  border-radius: var(--border-radius);
}
.ArrayProps {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  width: var(--array-props-width);
}

.ArrayPropsWrapper {
  max-height: var(--array-props-height);
  height: var(--array-props-height);
  z-index: 10;
  width: var(--array-props-width);
  max-width: var(--array-props-width);
  overflow-y: auto;
  overflow-x: hidden;
}

.isMobile,
.isMobile .SpansOutline,
.isMobile .ArrayPropsWrapper {
  max-height: calc(var(--array-props-height) * 3);
  height: calc(var(--array-props-height) * 3);
  z-index: 10;
  width: calc(var(--array-props-width) * 3);
  max-width: calc(var(--array-props-width) * 3);
  overflow-y: auto;
  overflow-x: hidden;
}

.isMobile .gridWrapper {
  width: calc(var(--array-props-width) * 3);
  padding-top: 3vw;
  padding-left: 1.5vw;
}

.isMobile .ArrayPropsWrapper button {
  width: calc(var(--array-props-width) * 3);
  height: 7vh;
  font-size: calc(var(--font-size) * 3);
}

.ArrayPropsWrapper button {
  all: unset;
  box-sizing: border-box;
  background: none;
  border: var(--border);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  position: absolute;
  width: var(--spans-outline-width);
  bottom: 0;
  left: 0;
  height: 2.5vw;
  padding: 0px;
  background-color: #482a8a;
  text-align: center;
  cursor: pointer;
  color: white;
}

.gridWrapper {
  padding-right: 0.5vw;
  padding-left: 1vw;
  padding-top: 1vw;
  width: var(--spans-outline-width);
}

.grid {
  display: inline;
}

.Hmm {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 99%;
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  border-radius: var(--border-radius);
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 99%;
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  border-radius: var(--border-radius);
  background: rgba(0, 151, 19, 0);
}
/*
@media only screen and (max-width: 750px) {
  .SpansOutline {
    height: var(--array-props-height);
    width: var(--spans-outline-width);
    border: var(--border);
    border-radius: var(--border-radius);
  }
  .ArrayPropsWrapper button {
    font-size: 3.5rem;
    position: absolute;
    width: var(--spans-outline-width);
    color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    left: 0;
    height: 2.5vw;
    padding: 0px;
    background-color: #482a8a;
    text-align: center;
    cursor: pointer;
    color: white;
  }
}
*/
</style>
