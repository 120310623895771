<template>
  <div>
    <div
      :style="`${this.$store.getters.isMobile ? 'background-color: #2C1753;' : ''} ${
        !this.$store.getters.isEmailVerified || !this.$store.getters.hasDiscordId
          ? this.$store.getters.isMobile
            ? 'top: 5.5vh'
            : 'top: 7vh;'
          : ''
      }`"
      class="BaseGlassModal ToggleNavbar"
      @click="showNavbar = !showNavbar"
    >
      <img src="/content/assets/hamburger.svg" />
    </div>
    <div
      v-if="this.$store.getters.isMobile"
      :class="`Nav BGImg ${this.$store.getters.isMobile ? 'isMobile' : ''} ${
        showNavbar !== undefined && showNavbar == true
          ? 'ShowNavbar'
          : showNavbar == false
          ? 'HideNavbar'
          : ''
      }`"
      alt=""
    />
    <nav
      :class="`BaseGlassModal Nav ${this.$store.getters.isMobile ? 'isMobile' : ''} ${
        showNavbar !== undefined && showNavbar == true
          ? 'ShowNavbar'
          : showNavbar == false
          ? 'HideNavbar'
          : ''
      }`"
    >
      <div class="LinksWrapper">
        <!--<router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          v-if="!this.$store.getters.isAuthenticated"
          :to="{ name: 'auth' }"
        >
          Вход</router-link
        > -->
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          :to="{ name: 'home' }"
        >
          Начало</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          :to="{ name: 'teams' }"
        >
          Отбори</router-link
        >
        <router-link @click="showNavbar = !showNavbar" class="BaseWhiteButton Links" to="/themes">
          Теми</router-link
        >
        <router-link @click="showNavbar = !showNavbar" class="BaseWhiteButton Links" to="/archives">
          Архив</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          :to="{ name: 'OurTeam' }"
        >
          Нашият екип</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          :to="{ name: 'Regulation' }"
        >
          Регламент</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          :to="{ name: 'Mentors' }"
        >
          Ментори</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          v-if="this.$store.getters.isAuthenticated"
          :to="{ name: 'profile' }"
        >
          Моят профил</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          to="/distribution"
        >
          Класация</router-link
        >

        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          v-if="this.$store.getters.isAuthenticated"
          :to="`/notifications`"
        >
          Известия</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          v-if="this.$store.getters.isAuthenticated"
          :to="`/discord`"
        >
          Дискорд</router-link
        >
        <router-link
          @click="showNavbar = !showNavbar"
          class="BaseWhiteButton Links"
          v-if="this.$store.getters.hasTeam"
          :to="`/team?teamId=${this.$store.getters.teamId}&myTeam=true`"
        >
          Моят отбор</router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showNavbar: undefined,
    };
  },
};
</script>

<style scoped>
/* SideBard */

.ToggleNavbar {
  position: fixed;
  z-index: 101;
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  border: 0.5vh solid white;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ToggleNavbar img {
  position: fixed;
  width: 3vh;
  height: 3vh;
  top: 61%;
  left: 61%;
}

.Nav {
  position: absolute;
  height: 100vh;
  width: 30vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border: var(--medium-border);
  transform: translate(-100%, 0%);
  border-radius: calc(var(--border-radius) * 2);
}

.ShowNavbar {
  animation: showNavbar 0.2s ease-in-out 0s 1;
  transform: translate(0%, 0%);
}

.HideNavbar {
  transform: translate(-100%, 0%);
  animation: hideNavbar 0.2s ease-in-out 0s 1;
}

.isMobile {
  width: 100vw;
}

.BGImg {
  background: url(/content/assets/mainBGBlur.png) no-repeat 50% fixed;
  background-position-x: 1%;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.Links {
  display: block;
  margin: 1vh;
  width: clamp(50%, 70%, 60vw);
  padding: 1vh;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 4vh;
  text-align: center;
  text-decoration: none;
  border-radius: 2vh;
  border: 0.2vh solid white;
}

.LinksWrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes showNavbar {
  0% {
    transform: translate(-100%, 0%);
    z-index: -1;
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes hideNavbar {
  0% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(-100%, 0%);
    z-index: -1;
  }
}
</style>
