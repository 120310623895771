export default [
  [
    {
      teamName: "Лемниската",
      points: 4.41,
    },
    {
      teamName: "Котки с палци",
      points: 4.36,
    },
    {
      teamName: "TtT",
      points: 3.82,
    },
    {
      teamName: "ERROR: name undefined",
      points: 3.66,
    },
    {
      teamName: "SPUTNIK",
      points: 3.64,
    },
    {
      teamName: "Картоф в кашон",
      points: 3.57,
    },
    {
      teamName: "C--",
      points: 3.52,
    },
    {
      teamName: "Feta cheese",
      points: 3.39,
    },
    {
      teamName: "TE-AM",
      points: 3.14,
    },
    {
      teamName: "ASAP",
      points: 3.14,
    },
  ],
];
