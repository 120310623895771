export default [
  {
    name: "Кирилка Ангелова",
    pic: "/content/site-images/our-team/personal-photos/mentor/kiki.webp",
    department: "Ментор",
  },
  {
    name: "Калоян Георгиев - 11Б",
    pic: "/content/site-images/our-team/personal-photos/coordinator/kaloyan.webp",
    department: "Координатор",
  },
  {
    name: "Лъчезар Топалов - 11В",
    pic: "/content/site-images/our-team/personal-photos/IT/luchezar.webp",
    department: "ИТ",
  },
  {
    name: "Искрен Александров - 11Б",
    pic: "/content/site-images/our-team/personal-photos/IT/iskren.webp",
    department: "ИТ 🟠@__isi.pisi__",
  },
  {
    name: "Мариян Видков - 11Г",
    pic: "/content/site-images/our-team/personal-photos/IT/marian.webp",
    department: "ИТ",
  },
  {
    name: "Златина Лилова - 11А",
    pic: "/content/site-images/our-team/personal-photos/PR/zlatina.webp",
    department: "ПР",
  },
  {
    name: "Цветина Джунакова - 11Г",
    pic: "/content/site-images/our-team/personal-photos/PR/tsvetina.webp",
    department: "ПР",
  },
  {
    name: "Калин Георгиев - 11А",
    pic: "/content/site-images/our-team/personal-photos/logistic/kalin.webp",
    department: "Логистика",
  },
  {
    name: "Атанас Атанасов - 11В",
    pic: "/content/site-images/our-team/personal-photos/logistic/atanas.webp",
    department: "Логистика",
  },
  {
    name: "Стефан Александров - 11В",
    pic: "/content/site-images/our-team/personal-photos/sponsors/stefan.webp",
    department: "Спонсори",
  },
  {
    name: "Калина Вълева - 11Б",
    pic: "/content/site-images/our-team/personal-photos/sponsors/kalina.webp",
    department: "Спонсори",
    top: "35%",
  },
  {
    name: "Александра Станчева - 11А",
    pic: "/content/site-images/our-team/personal-photos/sponsors/alex.webp",
    department: "Спонсори",
  },
  {
    name: "Мина Славова - 11В",
    pic: "/content/site-images/our-team/personal-photos/design/mina.webp",
    department: "Дизайн",
    top: "35%",
  },
  {
    name: "Йордан Марков - 11В",
    pic: "/content/site-images/our-team/personal-photos/design/yordan.webp",
    department: "Дизайн",
  },
];
