<template>
  <loading v-if="loading" />
  <div
    v-else
    :class="`BaseGlassModal AcceptTeamInvite ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
  >
    <span>
      {{ success ? "Кандидатът е приет" : "Грешка при приемане на кандидата" }}
    </span>
    <error-dialog v-if="errors" :errorMessages="errors.errorMessages" />
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import ErrorDialog from "../components/ui/ErrorDialog.vue";
import errorHandler from "../util/errorHandler";
export default {
  components: { ErrorDialog },
  name: "AcceptTeamApplication",
  data() {
    return {
      loading: true,
      success: false,
      errors: null,
    };
  },
  async created() {
    this.loading = true;
    try {
      await axiosInstance.get(`/team/accept-team-application/${this.$route.params.token}`);
      this.success = true;
      this.loading = false;
      this.errors = null;
    } catch (err) {
      const errors = errorHandler(err);
      if (errors?.errorNames?.includes("TokenExpiredError")) {
        errors.errorMessages[0] = "Изпратете имейла за потвърждение наново";
      }
      this.errors = errors;
      this.loading = false;
      this.success = false;
    }
  },
};
</script>

<style scoped>
.AcceptTeamInvite {
  padding: 2vw;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: calc(var(--font-size) * 1.2);
  transform: translate(-50%, -50%);
}

.AcceptTeamInvite span {
  font-size: inherit;
}

.isMobile {
  font-size: calc(var(--font-size) * 3);
}
</style>
