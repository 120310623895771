<template>
  <loading v-if="loading" />
  <div v-else>
    <div class="main" style="margin: 10px">
      <div class="info">
        <p>
          Брой:
          {{ currType === "всички отбори" ? filteredTeams.length || 0 : filteredUsers.length || 0 }}
        </p>
        <p>Брой потвърдени отбора: {{ teams.filter((team) => team.approved).length || 0 }}</p>
        <p v-if="currType == 'всички участници' || currType == 'всички нови участници'">
          XS: {{ getShirtSizeCount("XS") }}, S: {{ getShirtSizeCount("S") }}, M:
          {{ getShirtSizeCount("M") }}, L: {{ getShirtSizeCount("L") }}, XL:
          {{ getShirtSizeCount("XL") }}
        </p>
        <button @click="getUsers()">Вземи всички регистрирани</button>
        <button @click="getCaptains()">Вземи всички капитани</button>
        <button @click="getTeams()">Вземи всички отбори</button>
        <button @click="getParticipants()">Вземи всички участници</button>
        <button @click="getNewParticipants()">Вземи новите</button>
      </div>
      <div class="criteria" v-if="currType !== 'всички отбори'">
        <div>
          <label for="shirtSize">Размер тениска</label>
          <select v-model="data['shirtSize']" name="shirtSize">
            <option></option>
            <option>XS</option>
            <option>S</option>
            <option>M</option>
            <option>L</option>
            <option>XL</option>
          </select>
        </div>
        <div>
          <label for="eatingPreferences">Предпочитания за храна</label>
          <select v-model="data['eatingPreferences']" name="eatingPreferences">
            <option></option>
            <option>Няма</option>
            <option>Веган</option>
            <option>Вегетарианец</option>
          </select>
        </div>
        <div>
          <label for="studentClass">Клас</label>
          <select v-model="data['studentClass']" name="studentClass">
            <option :value="undefined"></option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </select>
        </div>
        <div>
          <label for="studentClassLetter">Паралелка</label>
          <select v-model="data['studentClassLetter']" name="studentClassLetter">
            <option :value="undefined"></option>
            <option>А</option>
            <option>Б</option>
            <option>В</option>
            <option>Г</option>
          </select>
        </div>
        <div>
          <label for="emailVerified">Верифициран имейл</label>
          <select v-model="data['emailVerified']" name="emailVerified">
            <option :value="undefined"></option>
            <option :value="true">Да</option>
            <option :value="false">Не</option>
          </select>
        </div>
        <div>
          <label for="allergies">Алергии</label>
          <select v-model="data['allergies']" name="allergies">
            <option :value="undefined"></option>
            <option value="true">Да</option>
          </select>
        </div>
        <div>
          <label for="showVerifiedTeam">Потвърден отбор</label>
          <select v-model="data['showVerifiedTeam']" name="showVerifiedTeam">
            <option :value="undefined"></option>
            <option :value="true">Да</option>
            <option :value="false">Не</option>
          </select>
        </div>
      </div>
      <div v-else class="criteria">
        <div>
          <label for="hasMentor">С ментор</label>
          <select v-model="data['hasMentor']" name="hasMentor">
            <option :value="undefined"></option>
            <option :value="true">Да</option>
            <option :value="false">Не</option>
          </select>
        </div>
      </div>
      <div v-if="!loading">
        <span style="text-decoration: underline">Сега се показват: {{ currType }}</span
        ><br />
        <button @click="showTeamName = !showTeamName">
          {{ showTeamName ? "Скрий" : "Покажи" }} отборите
        </button>
        <button @click="sortByTeamName = !sortByTeamName">Toggle Сортиране по отбори</button>
        <table
          style="margin-top: 20px; backdrop-filter: blur(50px)"
          v-if="currType !== 'всички отбори'"
        >
          <tr>
            <th>Име и фамилия</th>
            <th>Имейл</th>
            <th>Потвърден имейл</th>
            <th>Клас</th>
            <th>Размер на тениска</th>
            <th>Eating Preferances</th>
            <th>Алергии</th>
            <th>Телефонен номер</th>
            <th v-if="currType == 'всички нови участници'">Време на нова регистрация</th>
            <th v-if="showTeamName">Име на отбора</th>
            <th v-if="showTeamName">Потвърден отбор</th>
          </tr>
          <tr v-for="(user, index) in filteredUsers" :key="index" :user="user">
            <td>{{ user.fullName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.emailVerified }}</td>
            <td>{{ user.studentClass }}</td>
            <td>{{ user.shirtSize }}</td>
            <td>{{ user.eatingPreferences }}</td>
            <td>{{ user.allergies.join(", ") }}</td>
            <td>{{ user.telNumber }}</td>
            <td v-if="user.createdAt">{{ user.createdAt }}</td>
            <td v-if="showTeamName">{{ user.teamName }}</td>
            <td v-if="showTeamName">{{ user.approved }}</td>
          </tr>
        </table>
        <table v-else>
          <tr>
            <th>Име на отбора</th>
            <th>Име на капитан</th>
            <th>Имейл на капитан</th>
            <th>Номер на капитан</th>
            <th>Име на ментор</th>
            <th>Имейл на ментор</th>
            <th>Номер на ментор</th>
          </tr>
          <tr v-for="(team, index) in filteredTeams" :key="index">
            <td>{{ team.teamName }}</td>
            <td v-if="team.captain">{{ team.captain.fullName }}</td>
            <td v-if="team.captain">{{ team.captain.email }}</td>
            <td v-if="team.captain">{{ team.captain.telNumber }}</td>

            <td v-if="team.mentor">{{ team.mentor.fullName }}</td>
            <td v-if="team.mentor">{{ team.mentor.email }}</td>
            <td v-if="team.mentor">{{ team.mentor.telNumber }}</td>
          </tr>
        </table>
      </div>
      <span v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import "dotenv/config";

export default {
  data() {
    return {
      users: [],
      teams: [],
      error: "",
      loading: true,
      data: {},
      shirtSize: "",
      eatingPreferences: "",
      currType: "Нищо",
      showTeamName: false,
      sortByTeamName: false,
    };
  },
  props: {
    password: {
      required: true,
      type: String,
    },
  },
  computed: {
    filteredUsers() {
      return this.users
        ? this.users
            ?.filter(
              (user) =>
                (this.data.shirtSize ? user.shirtSize === this.data.shirtSize : true) &&
                (this.data.eatingPreferences
                  ? user.eatingPreferences === this.data.eatingPreferences
                  : true) &&
                (this.data.emailVerified !== undefined
                  ? user.emailVerified == this.data.emailVerified
                  : true) &&
                (this.data.allergies !== undefined ? user.allergies.length : true) &&
                (this.data.studentClass !== undefined
                  ? user.studentClass.startsWith(this.data.studentClass)
                  : true) &&
                (this.data.studentClassLetter !== undefined
                  ? user.studentClass.endsWith(this.data.studentClassLetter)
                  : true) &&
                (this.data.showVerifiedTeam !== undefined
                  ? user.approved == this.data.showVerifiedTeam
                  : true)
            )
            .sort((a, b) => {
              const nameA = a.fullName.toUpperCase();
              const nameB = b.fullName.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names must be equal
            })
            .sort((a, b) => {
              const classA = a.studentClass.length < 3 ? `0${a.studentClass}` : a.studentClass;
              const classB = b.studentClass.length < 3 ? `0${b.studentClass}` : b.studentClass;
              if (classA < classB) {
                return -1;
              }
              if (classA > classB) {
                return 1;
              }
              return 0; // names must be equal
            })
            .sort((a, b) => {
              if (this.sortByTeamName) {
                const teamA = a.teamName;
                const teamB = b.teamName;
                if (teamA < teamB) {
                  return -1;
                }
                if (teamA > teamB) {
                  return 1;
                }
                return 0;
              }
              return 0;
            })
        : [];
    },
    filteredTeams() {
      return this.teams.filter((team) =>
        this.data.hasMentor !== undefined ? !!team.mentor === this.data.hasMentor : true
      );
    },
  },
  mounted() {
    this.getParticipants();
  },
  methods: {
    getKeys(object) {
      if (object) {
        return Object.keys(object);
      } else {
        return [];
      }
    },
    getShirtSizeCount(shirtSize) {
      return this.users ? this.users.filter((e) => e.shirtSize === shirtSize).length : 0;
    },
    async getUsers() {
      this.loading = true;
      try {
        const prepayload = {
          shirtSize: this.data["shirtSize"],
          eatingPreferences: this.data["eatingPreferences"],
          emailVerified: this.data["emailVerified"],
          allergies: this.data["allergies"] ? { $exists: true, $ne: [] } : "",
          password: this.password,
        };

        let payload = Object.fromEntries(
          Object.entries(prepayload).filter(([, v]) => v != "" && typeof v !== "undefined")
        );

        const response = await axiosInstance.post("/admin/search-user", payload, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_ADMIN_TOKEN}`,
          },
        });
        this.users = response.data.response;
        this.currType = "всички регистрирани";
      } catch (err) {
        this.error = JSON.stringify({ ...err.response.data.errors });
      }
      this.loading = false;
    },
    async getCaptains() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(
          "/admin/get-captains",
          {
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_ADMIN_TOKEN}`,
            },
          }
        );
        this.users = response.data.captains;
        this.currType = "всички капитани";
      } catch (err) {
        this.error = JSON.stringify({ ...err.response.data.errors });
      }
      this.loading = false;
    },
    async getTeams() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(
          "/admin/get-teams",
          {
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_ADMIN_TOKEN}`,
            },
          }
        );
        this.teams = response.data.response.teams;
        this.currType = "всички отбори";
      } catch (err) {
        this.error = JSON.stringify({ ...err.response.data.errors });
      }
      this.loading = false;
    },
    async getParticipants() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(
          "/admin/get-participants",
          {
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_ADMIN_TOKEN}`,
            },
          }
        );
        this.users = response.data.response.participants;
        this.currType = "всички участници";
      } catch (err) {
        this.error = JSON.stringify({ ...err.response.data.errors });
      } finally {
        this.loading = false;
      }
    },
    async getNewParticipants() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(
          "/admin/get-new-participants",
          {
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_ADMIN_TOKEN}`,
            },
          }
        );
        this.users = response.data.response.newParticipants;
        this.currType = "всички нови участници";
      } catch (err) {
        this.error = JSON.stringify({ ...err.response.data.errors });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.criteria {
  display: block;
  margin: auto;
  margin-top: 20px !important;
}
.criteria > * {
  margin-right: 10px;
  display: inline-block;
}
.info > * {
  margin-right: 10px;

  display: inline-block;
}

table,
td {
  border: 1px solid black;
}
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 160px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
}

/* The navigation menu links */
.sidenav p {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav p:hover {
  color: #f1f1f1;
}

/* Style page content */
.main {
  margin-top: 20px; /* Same as the width of the sidebar */
  padding: 0px 10px !important;
  color: white;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
