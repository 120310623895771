export default (arr1, arr2) => {
  const arr = arr1.concat(arr2);
  const safeArr = [];
  arr.forEach((elem) => {
    if (!safeArr.includes(elem) && !arr1.includes(elem)) {
      safeArr.push(elem);
    }
  });
  return safeArr;
};
