<template>
  <div :class="`BaseGlassModal AddTeamMembers ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <form @submit.prevent="search">
      <input placeholder="Пълно име / Имейл на ученик" type="text" v-model="searchTerm" />
      <button
        type="submit"
        :disabled="!searchTerm.length"
        @click="search"
        class="BaseWhiteButton SearchButton"
      >
        Търси
      </button>
      <span id="SelectedCount">{{ selected.length }}/5 избрани</span>
    </form>
    <div v-if="!loading && lastSearchTerm === searchTerm" class="Members">
      <div
        v-for="(result, index) in results"
        :key="index"
        :style="`margin-bottom: 1vw; ${this.isSelected(index) ? 'outline: var(--border);' : ''} ${
          index !== results.length - 1 ? 'margin-right: 1vw;' : ''
        }`"
        @click="select(index)"
        class="Member"
      >
        <div v-if="result._id !== this.$store.getters.id" style="width: 100%; height: 100%">
          <img :src="result.profilePicURL" alt="" srcset="" />
          <span>{{ result.fullName }} - {{ result.studentClass }}</span> <br />
          <span>{{ result.emailVerified ? "Потвърден имейл" : "Непотвърден имейл" }}</span>
        </div>
      </div>
    </div>
    <div v-else-if="loading">Зареждане...</div>
    <div v-if="!loading && searched && !results.length">Няма ученик с такова име / такъв имейл</div>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, -75%);'
          : ''
      }`"
      class="ButtonXTick X"
      @click="clickedX"
    >
      <img src="/content/assets/X.svg" alt="X" />
    </div>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, 75%);'
          : ''
      }`"
      class="ButtonXTick Tick"
      @click="clickedTick"
    >
      <img src="/content/assets/Tick.svg" alt="Tick" />
    </div>
    <error-dialog v-if="errors?.errorMessages.length" :errorMessages="errors?.errorMessages" />
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import errorHandler from "../util/errorHandler";
// TODO Add Profile page for random users

export default {
  name: "AddTeamMembers",
  data() {
    return {
      searchTerm: "",
      selected: [],
      initial: [],
      results: [],
      errors: null,
      loading: false,
      searched: false,
      lastSearchTerm: "",
    };
  },
  props: {
    modelValue: {
      required: true,
      type: Array,
    },
    show: {
      required: true,
      type: Boolean,
    },
  },
  beforeMount() {
    this.selected = [...this.modelValue];
    this.initial = [...this.modelValue];
  },
  emits: ["update:modelValue", "update:show"],
  methods: {
    async search() {
      this.loading = true;
      try {
        let {
          data: { response },
        } = await axiosInstance.post("/user/search-user-identifier", {
          identifier: this.searchTerm,
        });
        this.results = [...response.filter((elem) => elem._id !== this.$store.getters.id)];
        this.loading = false;
        this.searched = true;
        this.lastSearchTerm = this.searchTerm;
      } catch (err) {
        this.errors = errorHandler(err);
        this.loading = false;
      }
    },
    getRepeatSelectedIndex(index) {
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i]._id === this.results[index]._id) {
          return i;
        }
      }
      return null;
    },
    isSelected(index) {
      return this.getRepeatSelectedIndex(index) != null;
    },
    select(index) {
      if (!this.isSelected(index) && this.selected.length !== 5) {
        this.selected.push(this.results[index]);
      } else if (this.isSelected(index)) {
        this.selected.splice(this.getRepeatSelectedIndex(index), 1);
      }
      this.$emit("update:modelValue", [...this.selected]);
    },
    clickedX() {
      this.selected = [...this.initial];
      this.$emit("update:modelValue", [...this.selected]);
      this.$emit("update:show", false);
    },
    clickedTick() {
      this.$emit("update:modelValue", [...this.selected]);
      this.$emit("update:show", false);
    },
  },
};
</script>

<style scoped>
.AddTeamMembers {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2vw;
  width: 60vw;
}

#SelectedCount {
  position: absolute;
  top: 1vw;
  right: 2vw;
}

.AddTeamMembers form button {
  margin-left: 1%;
}

.AddTeamMembers form input {
  all: unset;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  border-bottom: var(--border);
  text-align: center;
  width: 40%;
}

.AddTeamMembers span,
.AddTeamMembers button,
.AddTeamMembers input {
  font-size: calc(var(--font-size) * 1.2) !important;
}

.isMobile span,
.isMobile button,
.isMobile input {
  font-size: calc(var(--font-size) * 2) !important;
}

.isMobile input {
  width: 70% !important;
}

.isMobile #SelectedCount {
  position: absolute;
  top: 1vw;
  left: 2vw;
}

.Members {
  width: 90%;
  height: max-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
}

.Member {
  display: inline-block;
  height: max-content;
  padding: 1vw;
  cursor: pointer;
  outline: var(--border-dark);
  border-radius: var(--border-radius);
}

.Member:hover {
  outline: var(--border-dark);
}

.Member img {
  height: 5vw;
  display: flex;
  margin-right: 1vw;
  float: left;
  width: auto;
  border-radius: 50%;
}

.Member span {
  display: flex;
  margin-right: 1vw;
}

.isMobile .ButtonXTick {
  width: 5vw;
  height: 5vw;
}
</style>
