<template>
  <div v-if="allArchives" class="Archives" :key="Date.now()">
    <div class="Archive" v-for="(archive, index) in archives" :key="index">
      <p class="Title" v-html="archive.title"></p>
      <div class="ArchiveBody">{{ archive.description }}</div>
      <button
        class="BaseWhiteButton LearnMoreButton"
        v-if="allArchives"
        @click="() => routeTo(`/archive/${archive.name}`)"
      >
        Научи повече
      </button>
    </div>
  </div>
  <div v-else>
    <p v-html="archives.title" class="Title TitleBackground"></p>
    <button class="BaseWhiteButton GoBackButton" @click="() => routeTo('/archives')">
      Върни се назад
    </button>
    <div
      v-if="!allArchives"
      :class="`ArchiveTeams ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
    >
      <div v-for="(team, index) in archives.teams" :key="index" class="ArchiveElement">
        <span class="ArchiveTeamName">{{ getEmoji(index) }}{{ team.teamName }}</span>
        <div class="ArchiveTeamPic">
          <img :src="team.teamPic" v-if="team.teamPic" :alt="`Снимка за ${team.teamName}`" />
        </div>

        <span>Членове на отбора: {{ team.members }}</span>
        <span>Проект: {{ team.projectDescription }}</span>
      </div>
    </div>
    <div
      :class="`ArchiveElement ArchiveGeneralInformation ${
        this.$store.getters.isMobile ? 'isMobile' : ''
      }`"
    >
      <span style="text-align: center">Участници: {{ archives.stats.participants }}</span>
      <span style="text-align: center">Брой отбори: {{ archives.stats.teamCount }}</span>
      <span style="text-align: center">Отличени отбори: {{ archives.stats.awardedTeams }}</span>
    </div>
    <div
      :class="`ArchiveElement ArchiveGeneralInformation ${
        this.$store.getters.isMobile ? 'isMobile' : ''
      }`"
    >
      <span>{{ archives.body }}</span>
    </div>
    <button class="BaseWhiteButton GoBackButton" @click="scrollToTop">Върни се нагоре</button>
  </div>
</template>

<script>
import archives from "../util/pageTexts/archives";

export default {
  name: "Archive",
  data() {
    return {
      archives: null,
      allArchives: true,
    };
  },
  methods: {
    routeTo(link) {
      window.location.replace(link);
    },
    getEmoji(index) {
      switch (index + 1) {
        case 1:
          return "🥇";
        case 2:
          return "🥈";
        case 3:
          return "🥉";
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    },
    scrollToTopRapid() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    },
  },

  beforeMount() {
    const { archiveName } = this.$route.params;
    if (archiveName) {
      archives.forEach((archive) => {
        if (archive.name === archiveName) {
          this.archives = { ...archive };
          this.allArchives = false;
        }
        return;
      });
    } else {
      this.archives = [...archives];
    }
  },
  created() {
    this.scrollToTop();
  },
  mounted() {
    this.scrollToTop();
  },
  updated() {
    this.scrollToTop();
  },
};
</script>

<style>
.Archives {
  position: relative;
  margin-top: 10vh;
}
.Archive {
  text-align: center;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius);
  border: var(--border);
  color: white;
  background-color: var(--blur-background-color);
  backdrop-filter: blur(var(--blur));
  margin-bottom: 3vh;
}

.ArchiveTeams {
  width: 60%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.ArchiveElement {
  width: 100%;
  margin-bottom: 3vh;
  color: white;
  background-color: var(--blur-background-color);
  border-radius: var(--border-radius);
  backdrop-filter: blur(var(--blur));
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border: var(--medium-border);
}

.ArchiveElement p,
.ArchiveElement span {
  font-size: calc(var(--font-size) * 1.5);
  display: block;
  text-align: justify;
  position: relative;
  width: 80%;
  margin: 1vw;
  margin-left: auto;
  margin-right: auto;
}

.ArchiveTeamName {
  position: relative !important;
  margin-left: auto !important;
  text-align: center !important;
  margin-right: auto !important;
}

.ArchiveTeamPic {
  width: 80%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius);
  overflow: hidden;
  height: max-content;
}

.ArchiveTeamPic img {
  width: 100%;
  height: auto;
}

.Title span,
.Title sup {
  font-family: "LLPixel", sans-serif !important;
  font-size: calc(var(--font-size) * 4);
}

.Title {
  font-size: calc(var(--font-size) * 4);
  height: max-content;
}

.Title sup {
  font-size: calc(var(--font-size) * 2);
}

.ArchiveBody {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: calc(var(--font-size) * 1.5);
}

.ArchiveGeneralInformation {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 2vw 0vw;
  font-size: calc(var(--font-size) * 1.5);
}

.TitleBackground {
  padding: 0vw 2vw;
  margin: 2vw 0vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  border-radius: var(--border-radius);
  border: var(--medium-border);
  color: white;
  background-color: var(--blur-background-color);
  backdrop-filter: blur(var(--blur));
}

.LearnMoreButton {
  border: var(--medium-border);
  padding: 1vw;
  width: 30%;
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-radius: 2vw 2vw 10vw 10vw;
  font-size: calc(var(--font-size) * 1.5);
}

.isMobile {
  width: 80%;
}

.isMobile span,
.isMobile p {
  font-size: calc(var(--font-size) * 4);
}

.GoBackButton {
  color: white;
  padding: 1vw;
  font-size: calc(var(--font-size) * 1.3);
  border: var(--border);
  border-radius: var(--border-radius);
  backdrop-filter: blur(var(--blur));
  background-color: var(--blur-background-color);
  cursor: pointer;
  margin-bottom: 2vw;
}

@media only screen and (max-width: 850px) {
  .Archive {
    width: 85%;
  }
  .ArchiveBody,
  .LearnMoreButton {
    font-size: calc(var(--font-size) * 3);
  }
  .LearnMoreButton {
    width: 60%;
  }
}
</style>
