<template>
  <div class="DateElem">
    <span>{{ digit }}</span>
  </div>
</template>

<script>
export default {
  name: "DateElem",
  props: {
    digit: String,
  },
};
</script>

<style scoped>
.DateElem {
  display: inline-flex;
  width: min-content;
  max-width: 20vw;
  min-width: 8.1vw;
  margin-right: 0.4vw;
  transform: translateZ(0);
  text-align: center;
  border: var(--medium-border);
  border-radius: calc(var(--border-radius) * 1.5);
  color: white;
  background-color: var(--blur-background-color);
  backdrop-filter: blur(var(--blur));
}

.DateElem span {
  width: 100%;
  font-size: calc(3.5vw + 3.5vh);
  padding: 1.5vw;

  padding-top: 2.5vw;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
</style>
