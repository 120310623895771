export default [
  {
    _id: {
      $oid: "620932cd5536115044448a78",
    },
    fullName: "Александър Казанджиев",
    picURL: "/content/site-images/mentors/александър-казанджиев.avif",
    technologies: ["Bash", "C", "C++", "Linux", "Python"],
    tuesGradYear: "2000",
    yearsInPosition: "15",
    teamId: "62053487319b85eb21018d95",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620e3be461d126135a715a74",
    },
    fullName: "Виктор Найчев",
    picURL: "/content/site-images/mentors/александър-мицев.avif",
    technologies: [
      "Arduino",
      "Assembly",
      "C",
      "Java",
      "Linux",
      "Python",
      "Raspberry ",
      "Pi",
      "SQL",
    ],
    tuesGradYear: "2019",
    yearsInPosition: "2",
    teamId: "620216c8aae78464a6910008",
    graphic: "Петък 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a79",
    },
    fullName: "Александър Узунов",
    picURL: "/content/site-images/mentors/александър-узунов.avif",
    technologies: [
      "Bash",
      "C",
      "C#",
      "C++",
      "Golang",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "PHP",
      "Python",
      "React.js",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2018",
    yearsInPosition: "1+",
    teamId: "62053bee319b85eb2101934a",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 14:30",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cef",
    },
    fullName: "Ангел Пенчев",
    picURL: "/content/site-images/mentors/ангел-пенчев.avif",
    technologies: [
      "Bash",
      "C",
      "Flutter",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "Machine Learning",
      "Node.js",
      "Python",
      "Raspberry Pi",
      "React.js",
      "Web Development",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "0.5",
    teamId: "620101f59e09393546c25015",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7a",
    },
    fullName: "Антон Янчев",
    picURL: "/content/site-images/mentors/антон-янчев.avif",
    technologies: [
      "Bash",
      "C",
      "C#",
      "C++",
      "HTML",
      "CSS",
      "JavaScript",
      "Node.js",
      "React.js",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "1.5",
    teamId: "620a6c421266cd0c1d7e22bc",
    graphic: "Петък 10:00 - 19:00, Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7b",
    },
    fullName: "Антонио Милев",
    picURL: "/content/site-images/mentors/антонио-милев.avif",
    technologies: [
      "3D Art",
      "C",
      "C++",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "Node.js",
      "Python",
      "SQL",
      "Web Development",
      "Blockchain",
    ],
    tuesGradYear: "2019",
    yearsInPosition: "1+",
    teamId: "6203fb62842ecc5476673488",
    graphic: "Петък 10:00 - 19:00, Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7c",
    },
    fullName: "Боян Атанасов",
    picURL: "/content/site-images/mentors/боян-атанасов.avif",
    technologies: [
      "Arduino",
      "Bash",
      "HTML",
      "CSS",
      "Linux",
      "PHP",
      "Python",
      "SQL",
      "Web Development",
    ],
    yearsInPosition: "2+",
    teamId: "620172128f0775c3005a526e",
    tuesGradYear: "",
    graphic: "Петък 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7d",
    },
    fullName: "Боян Георгиев",
    picURL: "/content/site-images/mentors/боян-георгиев.avif",
    technologies: [
      "Java",
      "Spring",
      "Oracle",
      "postgreSQL",
      "Apache",
      "Kafka",
      "ElasticSearch",
      "git",
      "Kubernetes",
      "Firebase",
      "Angular",
      "React",
      "Node.js",
      "MongoDB",
      "Flutter",
    ],
    tuesGradYear: "2012",
    yearsInPosition: "6",
    teamId: "6203b9c6842ecc5476670169",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7e",
    },
    fullName: "Боян Миланов",
    picURL: "/content/site-images/mentors/боян-миланов.avif",
    technologies: ["Bash", "Blockchain", "C++", "Linux", "SQL"],
    tuesGradYear: "2018",
    yearsInPosition: "1+",
    teamId: "6200f74e4f7b5f2557e49abf",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 14:30 ",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a7f",
    },
    fullName: "Валентин Младенов",
    picURL: "/content/site-images/mentors/валентин-младенов.avif",
    technologies: [
      "C#",
      "HTML",
      "CSS",
      "JavaScript",
      "Machine Learning",
      "PHP",
      "SQL",
      "Web Development",
    ],
    yearsInPosition: "7",
    teamId: "62015ae31d06c5ac94f4b939",
    tuesGradYear: "",
    graphic: "Петък 14:30 - 19:00, Събота 14:30 - 19:00 ",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf0",
    },
    fullName: "Валери Добрев",
    picURL: "/content/site-images/mentors/валери-добрев.avif",
    technologies: [
      "Blockchain",
      "C",
      "C++",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "Node.js",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2020",
    yearsInPosition: "1",
    teamId: "62021b97aae78464a6910a22",
    graphic: "Събота 14:30 - 19:00, след 19:00",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf1",
    },
    fullName: "Велизар Стоянов",
    picURL: "/content/site-images/mentors/велизар-стоянов.avif",
    technologies: [
      "Arduino",
      "C#",
      "C++",
      "Embedded",
      "HTML",
      "CSS",
      "Machine Learning",
      "Python",
      "SQL",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "3",
    teamId: "62012a574e0611652f9c4f36",
    graphic: "Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a80",
    },
    fullName: "Владислав Георгиев",
    picURL: "/content/site-images/mentors/владислав-георгиев.avif",
    technologies: ["C++", "HTML", "CSS", "JavaScript", "Node.js", "React.js", "Web Development"],
    tuesGradYear: "2019",
    yearsInPosition: "1",
    graphic: "Събота 10:00 - 19:00",
    teamId: "6202e67e4ca507718193f724",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf2",
    },
    fullName: "Георги Аспарухов",
    picURL: "/content/site-images/mentors/георги-аспарухов.avif",
    technologies: ["Java", "JavaScript", "React.js", "SQL", "Web Development"],
    tuesGradYear: "2011",
    yearsInPosition: "10",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 14:30 ",
    teamId: "620aae7b1266cd0c1d7e2abc",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a81",
    },
    fullName: "Георги Стоилов",
    picURL: "/content/site-images/mentors/георги-стоилов.avif",
    technologies: [
      "C#",
      "C++",
      "HTML",
      "CSS",
      "JavaScript",
      "React.js",
      "Unity3D",
      "Unreal Engine",
    ],
    tuesGradYear: "2017",
    yearsInPosition: "1.5",
    teamId: "620155e11d06c5ac94f4b1ba",
    graphic: "Събота 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a82",
    },
    fullName: "Георги Тончев",
    picURL: "/content/site-images/mentors/георги-тончев.avif",
    technologies: ["C", "C++", "C#", "JavaScript", "Networking", "Linux"],
    tuesGradYear: "2005",
    yearsInPosition: "15",
    graphic: "",
    teamId: "62040656842ecc5476673df3",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a83",
    },
    fullName: "Димитър Калинков",
    picURL: "/content/site-images/mentors/димитър-калинков.avif",
    technologies: [
      "C#",
      "C++",
      "HTML",
      "CSS",
      "IoT",
      "JavaScript",
      "Node.js",
      "SQL",
      "Web Development",
      "DevOps",
      "Distributed Systems",
      "Azure Cloud",
    ],
    yearsInPosition: "8",
    graphic: "Петък 10:00 - 19:00",
    teamId: "6201535914391c71393a261f",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a84",
    },
    fullName: "Димитър Ряпов",
    picURL: "/content/site-images/mentors/димитър-ряпов.avif",
    technologies: [".NET", "Laravel"],
    tuesGradYear: "",
    yearsInPosition: "",
    graphic: "",
    teamId: "620815541266cd0c1d7df431",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf3",
    },
    fullName: "Димитър Терзиев",
    picURL: "/content/site-images/mentors/димитър-терзиев.avif",
    technologies: [
      "Bash",
      "C",
      "C#",
      "C++",
      "Django",
      "Embedded",
      "HTML",
      "CSS",
      "IoT",
      "Java",
      "Machine Learning",
      "Mobile Development",
      "Python",
      "SQL",
      "Web Development",
      "PyTorch",
      "Keras",
      "TensorFlow",
      "Sklearn",
      "Ruby on Rails",
    ],
    tuesGradYear: "2016",
    yearsInPosition: "0.5",
    teamId: "6201620d1d06c5ac94f4c789",
    graphic: "Петък 14:30 - 19:00, Събота 14:30 - 19:00 ",
  },
  {
    _id: {
      $oid: "620e39a161d126135a715a72",
    },
    fullName: "Евгени Димов",
    picURL:
      "/content/site-images/mentors/%D0%B5%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8-%D0%B4%D0%B8%D0%BC%D0%BE%D0%B2.avif",
    technologies: [
      "Arduino",
      "C",
      "C#",
      "C++",
      "Django",
      "ESP32",
      "ESP8266",
      "Embedded",
      "HTML",
      "CSS",
      "IoT",
      "Java",
      "JavaScript",
      "Linux",
      "Machine",
      "Learning",
      "Node.js",
      "Python",
      "Raspberry Pi",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2020",
    yearsInPosition: "0.9",
    teamId: "6200fe269e09393546c24a49",
    graphic: "Петък 10:00 - 19:00, Събота 10:00 - 19:00 ",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a85",
    },
    fullName: "Елвис Бабуров",
    picURL: "/content/site-images/mentors/елвис-бабуров.avif",
    technologies: ["SQL"],
    yearsInPosition: "8",
    graphic: "11.03 (петък) - от 10:00 до 14:30, 12.03 (събота) - от 10:00 до 14:30",
    teamId: "62011bd34e1616bf6cb2dfb7",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a86",
    },
    fullName: "Емил Доцев",
    picURL: "/content/site-images/mentors/емил-доцев.avif",
    technologies: ["C", "C++", "HTML", "CSS", "JavaScript", "Linux", "PHP", "Web Development"],
    yearsInPosition: "3",
    teamId: "620115cea766fb2963fa3648",
    tuesGradYear: "",
    graphic: "12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620e3be461d126135a715a75",
    },
    fullName: "Жулиета Атанасова",
    picURL: "/content/site-images/mentors/жулиета-атанасова.avif",
    technologies: ["HTML", "CSS", "Java", "SQL", "Web Development", "Kotlin"],
    tuesGradYear: "",
    yearsInPosition: "5-6",
    graphic: "12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
    teamId: "62053456319b85eb21018d7f",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a87",
    },
    fullName: "Иван Паунов",
    picURL: "/content/site-images/mentors/иван-паунов.avif",
    technologies: ["C", "C#", "C++", "Java"],
    yearsInPosition: "1",
    graphic: "12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
    teamId: "620104ecc13c431f628744ef",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a88",
    },
    fullName: "Иван Трендафилов",
    picURL: "/content/site-images/mentors/иван-трендафилов.avif",
    technologies: ["C#", "ASP.NET", "C++", "Python", "MS SQL", "ElasticSearch"],
    tuesGradYear: "2006",
    yearsInPosition: "8",
    graphic: "",
    teamId: "6201004b9e09393546c24e20",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a11",
    },
    fullName: "Иво Стратев",
    picURL: "/content/site-images/mentors/иво-стратев.avif",
    technologies: [
      "Golang",
      "Linux",
      "Node.js",
      "React.js",
      "SQL",
      "Web Development",
      "C++",
      "Python",
      "MongoDB",
      "Kafka",
      "Docker",
      "Kubernetes",
    ],
    tuesGradYear: "2016",
    yearsInPosition: "2.5",
    teamId: "6200f9194f7b5f2557e49e35",
    graphic:
      "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a89",
    },
    fullName: "Калоян Манолов",
    picURL: "/content/site-images/mentors/калоян-манолов.avif",
    technologies: [
      "Assembly",
      "Bash",
      "C",
      "C++",
      "HTML",
      "CSS",
      "JavaScript",
      "Linux",
      "Mobile Development",
      "Node.js",
      "React.js",
      "Web Development",
    ],
    yearsInPosition: "2.5",
    teamId: "62036d397587f76866c16c9b",
    tuesGradYear: "",
    graphic: "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf4",
    },
    fullName: "Костадин Костадинов",
    picURL: "/content/site-images/mentors/костадин-костадинов.avif",
    technologies: [
      "C#",
      "HTML",
      "CSS",
      "JavaScript",
      "Node.js",
      "React.js",
      "SQL",
      "Web Development",
      "TypeScript",
    ],
    tuesGradYear: "2020",
    yearsInPosition: "1.5",
    graphic: "Петък 14:30 - 19:00, Събота 10:00 - 14:30 ",
    teamId: "62027e73aa76245d0028a31e",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf5",
    },
    fullName: "Красимир Иванов",
    picURL: "/content/site-images/mentors/красимир-иванов.avif",
    technologies: [
      "Arduino",
      "Golang",
      "HTML",
      "CSS",
      "JavaScript",
      "PHP",
      "Raspberry Pi",
      "SQL",
      "Web Development",
      "IoT",
      "Video Streaming",
    ],
    tuesGradYear: "2005",
    yearsInPosition: "",
    teamId: "6202bcacfc0d049cd278dd06",
    graphic: "Петък 10:00 - 19:00, Събота 10:00 - 19:00 ",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8a",
    },
    fullName: "Кристиан Мариянов",
    picURL: "/content/site-images/mentors/кристиан-мариянов.avif",
    technologies: [
      "Blockchain",
      "C#",
      "HTML",
      "CSS",
      "JavaScript",
      "Raspberry Pi",
      "SQL",
      "Web Development",
      "Blazor",
      "Solidity",
      "Azure",
    ],
    yearsInPosition: "8",
    teamId: "62010540c13c431f628745bb",
    tuesGradYear: "",
    graphic:
      "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf6",
    },
    fullName: "Любомир Циров",
    picURL: "/content/site-images/mentors/любомир-циров.avif",
    technologies: [
      "Arduino",
      "Assembly",
      "Bash",
      "C",
      "C++",
      "Django",
      "ESP32",
      "ESP8266",
      "Embedded",
      "IoT",
      "Linux",
      "MicroPython",
      "Python",
      "Raspberry Pi",
      "SQL",
    ],
    tuesGradYear: "2007",
    yearsInPosition: "10",
    teamId: "620102139e09393546c25027",
    graphic: "Петък 10:00 - 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8b",
    },
    fullName: "Любослав Иванов",
    picURL: "/content/site-images/mentors/любослав-иванов.avif",
    technologies: [
      "Blockchain",
      "HTML",
      "CSS",
      "JavaScript",
      "Mobile Development",
      "Node.js",
      "SQL",
    ],
    tuesGradYear: "2015",
    yearsInPosition: "4",
    teamId: "62051ab8319b85eb210187bf",
    graphic: "11.03 (петък) - от 10:00 до 14:30, 12.03 (събота) - от 10:00 до 14:30",
  },
  {
    _id: {
      $oid: "620e3be461d126135a715a79",
    },
    fullName: "Мартин Йорданов",
    picURL: "/content/site-images/mentors/мартин-йорданов.avif",
    technologies: ["C", "C++", "Java", "Linux", "Machine Learning", "Python"],
    tuesGradYear: "2020",
    yearsInPosition: "",
    teamId: "6202a042aa76245d0028e1c9",
    graphic:
      "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf7",
    },
    fullName: "Милен Спасов",
    picURL: "/content/site-images/mentors/милен-спасов.avif",
    technologies: [
      "Arduino",
      "C",
      "C#",
      "Embedded",
      "IoT",
      "Java",
      "Machine Learning",
      "Python",
      "Raspberry Pi",
      "SQL",
      "Web Development",
    ],
    yearsInPosition: "12",
    teamId: "620250b7aae78464a691665e",
    tuesGradYear: "",
    graphic: "Петък 10:00 - 19:00, Събота 10:00 - 14:30",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8c",
    },
    fullName: "Морети Георгиев",
    picURL: "/content/site-images/mentors/морети-георгиев.avif",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "Linux",
      "Node.js",
      "PHP",
      "React.js",
      "SQL",
      "VueJS",
      "Laravel",
    ],
    tuesGradYear: "2016",
    yearsInPosition: "6",
    teamId: "620160481d06c5ac94f4c3d1",
    graphic:
      "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8d",
    },
    fullName: "Нейчо Калайджиев",
    picURL: "/content/site-images/mentors/нейчо-калайджиев.avif",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "Linux",
      "Node.js",
      "PHP",
      "Python",
      "SQL",
      "Web Development",
    ],
    yearsInPosition: "1",
    graphic: "12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
    teamId: "620129244e1616bf6cb2ea74",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8e",
    },
    fullName: "Николай Марков",
    picURL: "/content/site-images/mentors/николай-марков.avif",
    technologies: [
      "Bash",
      "C",
      "Django",
      "Embedded",
      "HTML",
      "CSS",
      "IoT",
      "JavaScript",
      "Linux",
      "MicroPython",
      "PHP",
      "Python",
      "Raspberry Pi",
      "React.js",
      "SQL",
      "Web Development",
      "Flask",
      "Networking",
    ],
    yearsInPosition: "25",
    teamId: "6202d187fc0d049cd2790ac3",
    tuesGradYear: "",
    graphic:
      "11.03 (петък) - от 10:00 до 14:30, 11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a8f",
    },
    fullName: "Огнян Барух",
    picURL: "/content/site-images/mentors/огнян-барух.avif",
    technologies: [
      "C",
      "Flutter",
      "Dart",
      "Go",
      "HTML",
      "CSS",
      "IoT",
      "Java",
      "JavaScript",
      "Python",
      "React.js",
      "SQL",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "1",
    teamId: "62017ab9837e403248a36fe4",
    graphic:
      "11.03 (петък) - от 10:00 до 14:30, 11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a90",
    },
    fullName: "Огнян Чиков",
    picURL: "/content/site-images/mentors/огнян-чиков.avif",
    technologies: [
      "Arduino",
      "Blockchain",
      "ESP32",
      "ESP8266",
      "HTML",
      "CSS",
      "IoT",
      "JavaScript",
      "Linux",
      "Mobile Development",
      "Node.js",
      "PHP",
      "Python",
      "Raspberry Pi",
      "React.js",
      "SQL",
      "Web Development",
    ],
    yearsInPosition: "10",
    graphic: "11.03 (петък) - от 10:00 до 14:30",
    teamId: "62012da14e0611652f9c5314",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a91",
    },
    fullName: "Павлин Николов",
    picURL: "/content/site-images/mentors/павлин-николов.avif",
    technologies: ["Bash", "Linux", "Python", "SQL"],
    tuesGradYear: "2018",
    yearsInPosition: "3+",
    graphic: "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30",
    teamId: "62015d811d06c5ac94f4be44",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a92",
    },
    fullName: "Пламен Данов",
    picURL: "/content/site-images/mentors/пламен-данов.avif",
    technologies: [
      "Arduino",
      "ESP32",
      "ESP8266",
      "IoT",
      "Linux",
      "Raspberry Pi",
      "SQL",
      "IBM Cloud",
      "Node-red",
    ],
    yearsInPosition: "10",
    graphic: "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30",
    teamId: "620168da2bec3ecee3428496",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a93",
    },
    fullName: "Самуил Георгиев",
    picURL: "/content/site-images/mentors/самуил-георгиев.avif",
    technologies: [
      "Bash",
      "C#",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "Node.js",
      "Python",
      "React.js",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "2",
    teamId: "6207c21f1266cd0c1d7de571",
    graphic:
      "11.03 (петък) - от 10:00 до 14:30, 11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30, 12.03 (събота) - от 14:30 до 19:00",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a94",
    },
    fullName: "Симеон Георгиев",
    picURL: "/content/site-images/mentors/симеон-георгиев.avif",
    technologies: [
      "Arduino",
      "C",
      "C++",
      "Embedded",
      "IoT",
      "JavaScript",
      "Linux",
      "Node.js",
      "Python",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2021",
    yearsInPosition: "",
    graphic: "11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30",
    teamId: "620264d5aae78464a6918baa",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf8",
    },
    fullName: "Станислав Милев",
    picURL: "/content/site-images/mentors/станислав-милев.avif",
    technologies: ["Java", "Machine Learning", "Mobile Development", "Python"],
    tuesGradYear: "2007",
    yearsInPosition: "15",
    teamId: "62017b09837e403248a37045",
    graphic: "Петък 10:00 - 14:30, Събота 10:00 - 14:30 ",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a95",
    },
    fullName: "Стойко Димитров",
    picURL: "/content/site-images/mentors/стойко-димитров.avif",
    technologies: [
      "3D Art",
      "Arduino",
      "Bash",
      "Blender",
      "C",
      "C++",
      "ESP32",
      "ESP8266",
      "Go",
      "Java",
      "Linux",
      "Python",
      "Godot",
      "Microcontrollers",
      "ATtiny85",
      "Blue Pill",
    ],
    graphic: "11.03 (петък) - от 10:00 до 14:30, 11.03 (петък) - от 14:30 до 19:00",
    teamId: "6200f9164f7b5f2557e49e17",
    tuesGradYear: "",
    yearsInPosition: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a96",
    },
    fullName: "Стоян Рузманов",
    picURL: "/content/site-images/mentors/стоян-рузманов.avif",
    technologies: ["C#", "HTML", "CSS", "JavaScript", "SQL", "Web Development"],
    yearsInPosition: "4",
    graphic:
      "11.03 (петък) - от 10:00 до 14:30, 11.03 (петък) - от 14:30 до 19:00, 12.03 (събота) - от 10:00 до 14:30",
    teamId: "620105ccc13c431f62874795",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cf9",
    },
    fullName: "Теодора Йовчева",
    picURL: "/content/site-images/mentors/теодора-йовчева.avif",
    technologies: [
      "C",
      "C++",
      "HTML",
      "CSS",
      "Java",
      "JavaScript",
      "Linux",
      "Mobile Development",
      "Node.js",
      "React.js",
      "SQL",
      "Web Development",
    ],
    tuesGradYear: "2020",
    yearsInPosition: "1",
    teamId: "62023488aae78464a691323e",
    graphic: "Петък 10:00 - 14:30, Събота 10:00 - 14:30 ",
  },
  {
    _id: {
      $oid: "621b5508a85cbc38e46e7cfa",
    },
    fullName: "Тодор Балабанов",
    picURL: "/content/site-images/mentors/тодор-балабанов.avif",
    technologies: ["C", "C++", "Java", "Linux", "Mobile Development", "PHP", "SQL"],
    tuesGradYear: "1998",
    yearsInPosition: "20",
    graphic: "Петък 10:00 - 19:00",
    teamId: "620ac612d755c5e5b51c8d20",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a97",
    },
    fullName: "Тодор Димитров",
    picURL: "/content/site-images/mentors/тодор-димитров.avif",
    technologies: ["Java", "Mobile Development", "Swift"],
    yearsInPosition: "1.5",
    graphic: "11.03 (петък) - от 10:00 до 14:30",
    teamId: "62023b34aae78464a6913d74",
    tuesGradYear: "",
  },
  {
    _id: {
      $oid: "620932cd5536115044448a99",
    },
    fullName: "Христо Борисов",
    picURL: "/content/site-images/mentors/христо-борисов.avif",
    technologies: [
      "Arduino",
      "Java",
      "JavaScript",
      "Mobile Development",
      "Node.js",
      "SQL",
      "Web Development",
      "Kotlin",
    ],
    tuesGradYear: "2012",
    yearsInPosition: "5-6",
    teamId: "6206b589bbafd0c9a58a9c86",
    graphic: "Петък 10:00 - 19:00",
  },
];
