// not found page
<template>
  <div :class="`BaseGlassModal NotFound ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <span id="s404">404</span> <br />
    <span>Няма такава страница</span>
  </div>
</template>

<style scoped>
.NotFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.7vw;
  text-align: center;
}

#s404 {
  font-weight: 900;
}

.NotFound span {
  font-size: calc(var(--font-size) * 1.5);
}

.isMobile span {
  font-size: calc(var(--font-size) * 4);
}
</style>
