<template>
  <loading v-if="loading" />
  <div
    v-else
    :class="`BaseGlassModal ConfirmEmail ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
  >
    <span>{{ success ? "Имелът е потвърден" : "Грешка при потвърджаване" }}</span>
    <error-dialog v-if="errors" :errorMessages="errors.errorMessages" />
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import ErrorDialog from "../components/ui/ErrorDialog.vue";
import errorHandler from "../util/errorHandler";
export default {
  components: { ErrorDialog },
  name: "ConfirmEmail",
  data() {
    return {
      loading: true,
      success: false,
      errors: null,
    };
  },
  async created() {
    this.loading = true;
    try {
      await axiosInstance.get(`/email/confirm-email/${this.$route.params.token}`);
      this.success = true;
      this.loading = false;
      this.errors = null;
      this.$store.commit("setEmailVerified", {
        email: this.$store.getters.email,
        emailVerified: true,
      });
    } catch (err) {
      const errors = errorHandler(err);
      if (errors.errorNames.includes("TokenExpiredError")) {
        errors.errorMessages[0] = "Изпратете имейла за потвърждение наново";
      }
      this.errors = errors;
      this.loading = false;
      this.success = false;
    }
  },
};
</script>

<style scoped>
.ConfirmEmail {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  padding: 1vw;
  font-size: calc(var(--font-size) * 1.5);
}

.ConfirmEmail span {
  font-size: inherit;
}

.isMobile {
  font-size: calc(var(--font-size) * 4);
  width: 80%;
}
</style>
