import { createApp } from "vue";

import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import ErrorDialog from "./components/ui/ErrorDialog.vue";
import Multiselect from "@vueform/multiselect";
import Password from "./components/ui/Password.vue";
import Loading from "./components/ui/Loading.vue";
import VerifyEmailDiscord from "./components/ui/VerifyEmailDiscord.vue";
import BaseSvg from "./components/ui/BaseSvg.vue";
import BaseSvgChild from "./components/ui/BaseSvgChild.vue";
import BaseSvgButton from "./components/ui/BaseSvgButton.vue";
import BaseSelect from "./components/ui/BaseSelect.vue";

const app = createApp(App);
app.use(store);
if (!window.location.hostname.startsWith("infinity.")) {
  window.location.href = "https://hacktues.bg";
} else {
  store
    .dispatch("tryLogin")
    .then(() => {
      app.component("base-svg", BaseSvg);
      app.component("base-svg-child", BaseSvgChild);
      app.component("base-svg-button", BaseSvgButton);
      app.component("multi-select", Multiselect);
      app.component("base-button", BaseButton);
      app.component("base-dialog", BaseDialog);
      app.component("error-dialog", ErrorDialog);
      app.component("password", Password);
      app.component("loading", Loading);
      app.component("verify-email", VerifyEmailDiscord);
      app.component("base-select", BaseSelect);

      app.use(router);
      app.mount("#app");
    })
    .catch((err) => window.alert(err));
}
