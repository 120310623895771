export default (err) => {
  if (err?.response?.data?.errors) {
    const errors = { ...err?.response?.data?.errors };
    const errorNames = Object.keys(errors);
    const errorMessages = [];
    Object.keys(errors).forEach((key) => errorMessages.push(`${errors[key]}`));
    const handledErrors = {
      errorNames: [...errorNames],
      errorMessages: [...errorMessages],
    };
    return handledErrors;
  } else {
    alert(
      `${err?.name || "Unknown server error"}: ${
        err?.message || "Please report to site administrator"
      }`
    );
    return null;
  }
};
