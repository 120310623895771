<template>
  <div :class="`VerifyEmail ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <span>{{
      !this.$store.getters.isEmailVerified
        ? "Вашият имейл не е потвърден"
        : "Не сте си свързали дискорд акаунта"
    }}</span>
    <button
      v-if="!this.$store.getters.isEmailVerified"
      :disabled="sending"
      class="BaseWhiteButton"
      @click="verifyEmail"
    >
      {{ sending ? "Изпращане..." : "Потвърди" }}
    </button>
    <button v-else class="BaseWhiteButton" @click="this.$router.push('/discord')">Свързане</button>
  </div>
</template>

<script>
import errorHandler from "../../util/errorHandler";
import sendVerificationEmail from "../../util/sendVeficationEmail";
import createTokenPair from "../../util/createTokenPair";

export default {
  name: "VerifyEmail",
  data() {
    return {
      sending: false,
    };
  },
  props: {
    message: String,
  },
  methods: {
    async verifyEmail() {
      this.sending = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        await sendVerificationEmail(accessToken, this.$store.getters.email);

        this.sending = false;
      } catch (err) {
        const error = errorHandler(err);
        if (error) {
          this.sending = false;
          alert(error.errorMessages);
        }
      }
    },
  },
};
</script>

<style scoped>
.VerifyEmail {
  position: fixed;
  top: 0;
  height: max-content;
  width: 100%;
  background: rgb(5, 85, 255);
  background: linear-gradient(
    162deg,
    rgba(5, 85, 255, 1) 0%,
    rgba(158, 6, 6, 1) 0%,
    rgba(88, 2, 81, 1) 75%,
    rgba(81, 2, 88, 1) 100%
  );
  z-index: 200;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 7vh;
  padding: 1.4rem;
  padding: auto;
  overflow: hidden;
}

.VerifyEmail button {
  padding-right: 1rem;
  padding: 0.5vw 1vw;
  float: center;
  color: white;
  border: 0.2vh solid gray;
}

.VerifyEmail span {
  color: white;
  padding-right: 1rem;
  font-size: calc(var(--font-size) * 1.5) !important;
}
.BaseWhiteButton {
  font-size: calc(var(--font-size) * 1.5) !important;
}
.isMobile {
  height: 5.5vh;
}
.isMobile button,
.isMobile span {
  font-size: 3.5rem;
  padding: 1vw;
}
@media only screen and (max-width: 650px) {
  .VerifyEmail span {
    font-size: calc(var(--font-size) * 3) !important;
  }
  .BaseWhiteButton {
    font-size: calc(var(--font-size) * 3) !important;
  }
}
</style>
