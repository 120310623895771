import axiosInstance from "../axiosInstance";

export default async (context) => {
  try {
    const oldRefreshToken = localStorage.getItem("refreshToken");
    if (oldRefreshToken) {
      const {
        data: {
          response: { accessToken, refreshToken, id },
        },
      } = await axiosInstance.post("/auth/generate-token-pair", {
        refreshToken: oldRefreshToken,
      });
      localStorage.setItem("refreshToken", refreshToken);
      await context.commit("setUser", {
        refreshToken,
        id,
      });
      return { success: true, accessToken, refreshToken, id };
    }
    return { success: false, accessToken: null, refreshToken: null, id: null };
  } catch (err) {
    localStorage.removeItem("refreshToken");
    await context.commit("setUser", {
      refreshToken: null,
      id: null,
    });
    return { success: false, accessToken: null, refreshToken: null, id: null };
  }
};
