<template>
  <loading v-if="loading" />
  <div v-else :class="`MentorsPage ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <div class="Control">
      <input class="SearchTermInput" type="text" placeholder="Търсене..." v-model="searchTerm" />
    </div>
    <error-dialog v-if="errors?.errorNames" :errorMessages="errors.errorMessages" />
    <div class="Mentors">
      <div v-for="(mentor, index) in filteredMentors" :key="index" class="MentorWrapper">
        <div
          :style="`${mentor.teamId?.length ? 'background-color: red' : 'background-color: green'}`"
          class="TakenMentor"
        ></div>
        <div class="BaseGlassModal Mentor">
          <span>{{ mentor.fullName }}</span>
          <span
            >{{ mentor.position }}
            {{ mentor.yearsInPosition ? `- ${mentor.yearsInPosition}г.` : "" }}</span
          >
          <div class="MentorImage">
            <img :src="mentor.picURL" :alt="`Снимка на ${mentor.fullName}`" />
          </div>
          <span v-if="mentor.tuesGradYear">ТУЕС `{{ mentor.tuesGradYear }}</span>
          <span>{{ mentor.company }}</span>
          <span>{{ mentor.graphic }}</span>
          <div class="Technologies">
            <array-props
              :isEdit="false"
              :isColored="true"
              :arrayProps="mentor.technologies"
              type="technologies"
            />
          </div>
          <button
            v-if="this.$store.getters.isCaptain"
            :disabled="mentor.teamId.length"
            class="BaseWhiteButton SetMentorButton"
            @click="setMentor(index, mentor._id)"
          >
            {{ mentor.teamId ? `${mentor.fullName} e зает` : `Вземи за ментор` }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import ArrayProps from "../components/ArrayProps.vue";
import errorHandler from "../util/errorHandler";
import createTokenPair from "../util/createTokenPair";
import mentors from "../util/pageTexts/mentors";

export default {
  components: { ArrayProps },
  name: "Mentors",
  data() {
    return {
      loading: false,
      mentors: [],
      searchTerm: "",
      errors: {},
    };
  },
  computed: {
    filteredMentors() {
      return this.mentors.filter(
        (mentor) =>
          mentor.fullName.startsWith(this.searchTerm) ||
          !!mentor.technologies.filter((tech) => tech.startsWith(this.searchTerm)).length
      );
    },
  },
  async beforeMount() {
    this.loading = true;
    try {
      this.mentors = mentors;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errors = errorHandler(err);
    }
  },
  methods: {
    async setMentor(mentorIndex, mentorId) {
      this.loading = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        const {
          data: {
            response: { mentor },
          },
        } = await axiosInstance.post(
          "/mentor/set-mentor",
          {
            mentorId,
            teamId: this.$store.getters.teamId,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.mentors[mentorIndex] = { ...mentor };
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
  },
};
</script>

<style scoped>
.MentorsPage {
  position: relative;
  padding-top: 2vh;
  width: 100vw;
}

.MentorWrapper {
  position: relative;
  display: inline-block;
  width: max-content;
  margin-right: calc(1vh + 1vw);
  margin-bottom: calc(1vh + 1vw);
}

.TakenMentor {
  width: 100%;
  height: 10%;
  border-radius: var(--border-radius);
  position: absolute;
  margin: 0px !important;
  top: 0;
}

.MentorsPage span,
.MentorsPage input,
.MentorsPage button {
  font-size: 1.5rem;
}

.isMobile span,
.isMobile input,
.isMobile button {
  font-size: 4rem;
}
.isMobile .Mentor {
  padding: 3vh;
  display: block;
  position: relative;
  margin-right: 0px;
  height: max-content;
}

.isMobile .Mentor span {
  max-width: 60vw;
}

.isMobile .Mentors {
  position: relative;
  display: block;
  width: 100vw;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
}

.isMobile .SetMentorButton {
  position: relative;
  display: block;
  left: 0%;
  bottom: 0%;
  margin-top: 3vh;
  transform: none;
  margin-left: auto;
  margin-right: auto;
}

.Mentor {
  --font-size-here: calc(var(--font-size) * 1.7);
  position: relative;
  width: max-content;
  padding: 2%;
  backdrop-filter: blur(var(--blur));
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
  min-height: 62vw;
  min-width: 27vw;
}

.Mentor span {
  text-align: center;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  max-width: 22vw;
  margin-bottom: 1vh;
}

.Technologies {
  width: max-content;
  height: max-content;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vw;
}

.MentorImage {
  width: 80%;
  aspect-ratio: 1;
  height: auto;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin: 3vh auto;
}

.MentorImage img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SearchTermInput {
  all: unset;
  color: white;
  border: var(--default-ts) solid rgb(123, 87, 255);
  border-radius: var(--border-radius);
  font-size: calc(var(--font-size) * 1.3);
  text-align: left;
  width: max-content;
  background-color: rgb(79, 26, 177);
  margin-bottom: 1.5vh;
  padding: 1vw;
}

.SearchTermInput::placeholder {
  color: white;
}

.SetMentorButton {
  position: absolute;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%, 0%);
}
</style>
