<template>
  <div class="Select">
    <select
      class="SelectElem"
      v-model="content"
      @input="handleInput"
      :style="`width: ${content?.length + 4 || 0 + 4}vw`"
    >
      <option
        v-for="(option, index) in options"
        :value="option.value === 'true' ? true : option.value === 'false' ? false : option.value"
        :key="index"
      >
        {{ option.name }}
      </option>
    </select>
    <img src="/content/assets/DownArrow.svg" alt="" />
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    modelValue: {
      required: true,
    },
    options: {
      required: true,
    },
  },
  beforeCreate() {
    this.content = this.modelValue;
  },
  emits: ["update:modelValue"],
  methods: {
    handleInput(e) {
      this.content = e.target.value;
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style scoped>
.Select {
  position: relative;
  color: white !important;
  font-size: inherit !important;
  width: max-content;
}

.SelectElem {
  all: unset;
  color: inherit;
  background: none;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.4vw 1vw;
  cursor: pointer;
  margin: 0px;
  background-color: var(--blur-background-color);
  padding-right: calc(1.2vw);
  text-align: center;
}

img {
  width: 1vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 85%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  cursor: default;
}

option {
  color: #000;
  padding: 0 3vw;
  font-size: inherit;
  background: none;
  border: var(--border);
  border-radius: var(--border-radius);
}
</style>
