<template>
  <loading v-if="loading" />
  <div
    v-else
    :class="`BaseGlassModal ForgotPassword ${
      this.$store.getters.isMobile ? 'isMobile' : 'isMobile'
    }`"
  >
    <span
      >На имейлът, който поставите отдолу, ще бъдат изпратени инструкции за ресетване на паролата,
      ако съществува акаунт с такъв имейл.</span
    >
    <form @submit.prevent="sendPasswordResetEmail">
      <div class="form-control">
        <label for="email">Имейл адрес</label>
        <input
          :style="this.errors?.errorNames?.includes('email') ? 'background-color: red' : null"
          required
          type="email"
          name="email"
          v-model="email"
        />
      </div>

      <button class="BaseWhiteButton FPButton" type="submit">Промени парола</button>
      <button class="BaseWhiteButton FPButton" type="button" @click="this.$router.replace('/auth')">
        Отказ
      </button>

      <error-dialog
        v-if="this.errors?.errorMessages?.length"
        :errorMessages="this.errors?.errorMessages"
      />
    </form>
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import errorHandler from "../util/errorHandler";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      loading: false,
      errors: {},
    };
  },
  methods: {
    async sendPasswordResetEmail() {
      this.loading = true;
      try {
        await axiosInstance.get(`/email/send-password-reset-email/${this.email}`);
        this.errors = null;
        this.loading = false;
        alert(`Имейл изпратен на ${this.email}`);
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
  },
};
</script>

<style scoped>
.ForgotPassword {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.2vw;
  width: 60%;
}

.ForgotPassword input {
  all: unset;
  display: flex;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  border-bottom: var(--border);
  text-align: center;
  width: 60%;
}

.FPButton {
  padding: 0.6vw;
  margin: 0.5vw;
  margin-top: 1vw;
  margin-bottom: 0;
}

.ForgotPassword span,
.ForgotPassword label,
.ForgotPassword input,
.FPButton {
  font-size: calc(var(--font-size) * 1.2);
}

.isMobile span,
.isMobile label,
.isMobile input,
.isMobile button {
  font-size: calc(var(--font-size) * 1.2);
}
</style>
