export default {
  alphaSponsors: [
    {
      src: "/content/site-images/sponsors/acronis.svg",
      alt: "Acronis",
      padding: "3vh",
      link: "https://acronis.com",
    },
    {
      src: "/content/site-images/sponsors/appolica.svg",
      padding: "2.3vh",
      alt: "Appolica",
      link: "https://appolica.com/",
    },
    {
      src: "/content/site-images/sponsors/chaos.svg",
      padding: "0.3vh",
      alt: "Chaos",
      link: "https://chaos.com",
    },
    {
      src: "/content/site-images/sponsors/experian.svg",
      alt: "Experian",
      padding: "1vh",
      link: "https://experian.com",
    },
    {
      src: "/content/site-images/sponsors/m2m.svg",
      alt: "M2M",
      padding: "0.3vh",
      link: "https://m2mservices.com/M2M/",
    },
    {
      src: "/content/site-images/sponsors/progress.svg",
      alt: "Progress",
      padding: "1vh",
      link: "https://progress.com",
    },
    {
      src: "/content/site-images/sponsors/sap.svg",
      padding: "0.3vh",
      alt: "SAP",
      link: "https://www.sap.com/index.html",
    },
    {
      src: "/content/site-images/sponsors/schwarz.svg",
      alt: "Schwarz IT Hub",
      link: "https://ro.it.schwarz/",
    },
    {
      src: "/content/site-images/sponsors/telebidpro.svg",
      padding: "3vh",
      alt: "TelebidPro",
      link: "https://telebid-pro.com/",
    },
    {
      src: "/content/site-images/sponsors/vmware.svg",
      alt: "VMware",
      padding: "3vh",
      link: "https://www.vmware.com/",
    },
  ],
  betaSponsors: [
    {
      src: "/content/site-images/sponsors/a1.svg",
      alt: "A1",
      padding: "1.3vh",
      link: "https://www.a1.bg/bg?home",
    },
    {
      src: "/content/site-images/sponsors/cpd.svg",
      alt: "CPD",
      padding: "2.3vh",
      link: "http://web.cpdbg.com/",
    },
    {
      src: "/content/site-images/sponsors/limechain.svg",
      padding: "1.3vh",
      alt: "LimeChain",
      link: "https://limechain.tech/",
    },
    {
      src: "/content/site-images/sponsors/mentormate.svg",
      padding: "2.3vh",
      alt: "MentorMate",
      link: "https://mentormate.com/bg/?utm_campaign=homepage&utm_medium=referral&utm_source=hack-tues",
    },
    {
      src: "/content/site-images/sponsors/mm-solutions.svg",
      padding: "1.5vh",
      alt: "MM-Solutions",
      link: "https://www.mm-sol.com/",
    },
    {
      src: "/content/site-images/sponsors/nemetschek.svg",
      alt: "Nemetschek",
      padding: "2vh",
      link: "https://www.nemetschek.bg/",
    },
    {
      src: "/content/site-images/sponsors/paysafe.svg",
      padding: "3.3vh",
      alt: "Paysafe",
      link: "https://www.paysafe.com/us-en/",
    },
    {
      src: "/content/site-images/sponsors/telelink.svg",
      padding: "2vh",
      alt: "Telelink",
      link: "https://www.tbs.tech/",
    },
  ],
  gammaSponsors: [
    {
      src: "/content/site-images/sponsors/astea.svg",
      padding: "1.8vh",
      alt: "Astea",
      link: "https://asteasolutions.com/",
    },
    {
      src: "/content/site-images/sponsors/bosch.svg",
      padding: "2vh",
      alt: "Bosch IOT",
      link: "https://bosch.io/",
    },
    {
      src: "/content/site-images/sponsors/devrix.svg",
      padding: "2vh",
      alt: "Devrix",
      link: "https://devrix.com/",
    },
    {
      src: "/content/site-images/sponsors/ibm.svg",
      alt: "IBM",
      link: "https://www.ibm.com/bg-en",
    },
    {
      src: "/content/site-images/sponsors/mina-soft.svg",
      padding: "2.8vh",
      alt: "Mina Software",
      link: "https://mina-soft.eu",
    },
    {
      src: "/content/site-images/sponsors/ocado-technology.webp",
      padding: "2.4vh",
      alt: "Ocado Technology",
      link: "https://www.ocadogroup.com/about-us/ocado-technology",
    },
    {
      src: "/content/site-images/sponsors/ubisoft.svg",
      padding: "2vh",
      alt: "Ubisoft",
      link: "https://www.ubisoft.com/en-us/",
    },
  ],
  partners: [
    {
      src: "/content/site-images/partners/elsys.svg",
      alt: "ELSYS",
      padding: "1.5vh",
      link: "https://elsys-bg.org/",
    },
    {
      src: "/content/site-images/partners/aztues.svg",
      alt: "АЗТУЕС",
      padding: "1.4h",
      link: "https://aztues.bg/",
    },
    {
      src: "/content/site-images/partners/smartkom.avif",
      alt: "Смартком",
      padding: "1vh",
      link: "https://www.hacktues.com",
    },
    {
      src: "/content/site-images/partners/sofia-tech-park.avif",
      alt: "Sofia Tech Park",
      padding: "0vh",
      link: "https://sofiatech.bg/",
    },
    {
      src: "/content/site-images/partners/streamer-bg.avif",
      alt: "StreamerBG",
      padding: "1.2vh",
      link: "https://streamer.bg/bg/home",
    },
    {
      src: "/content/site-images/partners/ora.avif",
      alt: "Ora",
      padding: "1vh",
      link: "https://ora.pm/",
    },
    {
      src: "/content/site-images/partners/brain-foods.avif",
      alt: "BrainFoods",
      padding: "0.5vh",
      link: "https://www.brainfoods.bg/",
    },
    {
      src: "/content/site-images/partners/bulged.avif",
      alt: "Bulged",
      padding: "0.5vh",
      link: "https://bulged.net",
    },
    {
      src: "/content/site-images/partners/comet-electronics.svg",
      alt: "Comet Electronics",
      padding: "1.8vh",
      link: "https://www.comet.bg/",
    },
    {
      src: "/content/site-images/partners/dominos.svg",
      alt: "Dominos",
      padding: "2.5vh",
      link: "https://dominos.bg",
    },
    {
      src: "/content/site-images/partners/observatory.svg",
      alt: "Национална астрономическа обсерватория Рожен",
      padding: "1vh",
      link: "http://nao-rozhen.org/",
    },
  ],
  mediaPartners: [
    {
      src: "/content/site-images/media-partners/bnr.svg",
      padding: "3.2vh",
      alt: "Българско национално радио",
      link: "https://bnr.bg",
    },
    {
      src: "/content/site-images/media-partners/bnt.svg",
      alt: "Българска национална телевизия",
      padding: "1.7vh",
      link: "https://bnt.bg/",
    },
    {
      src: "/content/site-images/media-partners/devstyler.avif",
      alt: "DevStyleR",
      padding: "2vh",
      link: "https://devstyler.bg/",
    },
  ],
  cloudPartners: [
    {
      src: "/content/site-images/cloud-partners/digital-ocean.svg",
      alt: "DigitalOcean",
      padding: "2.5vh",
      link: "https://www.digitalocean.com/",
    },
  ],
};
