export default [
  "JavaScript",
  "Python",
  "HTML",
  "CSS",
  "Java",
  "C",
  "C++",
  "C#",
  "Rust",
  "Perl",
  "Go",
  "Node.js",
  "Vue.js",
  "React.js",
  "Assembler",
  "Kotlin",
  "PHP",
  "Objective C",
  "Swift",
  "TypeScript",
  "R",
  "Lua",
  "Hadoop",
  "Django",
  "Flask",
  "Angular.js",
  "TensorFlow",
  "Unity3D",
  "Pytorch",
  "Scala",
  "Flutter",
  "PWA",
  "Ruby",
  "SQL",
  "NoSQL",
  "MySQL",
  "Postgre SQL",
  "MongoDB",
  "Cassandra",
  "MapReduce",
  "RocksDB",
  "InfluxDB",
  "Kafka",
  "Samza",
  "RDS",
  "SQLite",
  "Docker",
  "Kubernetes",
  "Linux",
  "Machine Learing",
  "IOT",
  "Redis",
  "Etherium",
  "Blockchain",
  "Embedded",
  "Arduino",
  "Raspberry Pi",
  "VR",
  "AR",
];
