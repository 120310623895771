<template>
  <div :class="sponsorType">
    <div class="SponsorWrapper" :key="index" v-for="(sponsor, index) in sponsorsSvgs">
      <img
        @click="sendTo(sponsor.link)"
        :src="sponsor.src"
        :alt="sponsor.alt"
        :style="`padding: ${sponsor.padding || '2.7vh'}`"
      />
    </div>
  </div>
</template>

<script>
import sponsors from "../util/pageTexts/sponsors";

export default {
  name: "SponsorsSvg",
  data() {
    return {
      sponsorsSvgs: [],
    };
  },
  props: {
    sponsorType: String,
  },
  beforeMount() {
    this.sponsorsSvgs = [...sponsors[this.sponsorType]];
  },
  methods: {
    sendTo(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.SponsorWrapper {
  display: inline-block;
  width: max-content;
  height: max-content;
  min-width: auto;
  cursor: pointer;
  transition: ease outline 0.3s;
  padding: 1vw;
  height: max-content;
  border-radius: var(--border-radius);
}

.SponsorWrapper:hover {
  outline: var(--border);
}

img {
  height: 12vh;
  max-width: 85vw;
  width: auto;
  background-image: contain;
  display: block;
}
</style>
