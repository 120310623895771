<template>
  <div>
    <input
      @keypress.space.prevent
      :required="required"
      :placeholder="placeholder"
      :type="visibility"
      :value="modelValue"
      :style="inputStyle"
      @input="(e) => this.$emit('update:modelValue', e.target.value)"
    />
    <p
      @click="toggleVisibility()"
      :style="`
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
        font-size: ${this.$store.getters.isMobile ? '3rem' : '2rem'};
      `"
    >
      {{ passwordEye }}
    </p>
  </div>
</template>

<script>
export default {
  name: "password",
  data() {
    return {
      visibility: "password",
    };
  },
  methods: {
    toggleVisibility() {
      this.visibility === "password" ? (this.visibility = "text") : (this.visibility = "password");
    },
  },
  computed: {
    passwordEye() {
      return this.visibility === "password" ? "ʘ" : "Ø";
    },
  },
  props: {
    required: {
      type: Boolean,
      required: true,
      default: false,
    },
    modelValue: String,
    name: {
      type: String,
      default: "password",
    },
    placeholder: String,
    inputStyle: { required: false },
  },
};
</script>
