<template>
  <div :class="`app ${this.$store.getters.isMozilla ? 'isMozilla' : ''}`">
    <verify-email
      v-if="!this.$store.getters.isEmailVerified || !this.$store.getters.hasDiscordId"
    />
    <Navigation />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e51;
}

.isMozilla {
  --white-dark: #2c1753;
  --blur-background-color: var(--white-dark);
}
</style>

<script>
import Navigation from "./components/ui/Navbar";
export default {
  name: "App",
  components: {
    Navigation,
  },
};
</script>
