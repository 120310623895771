<template>
  <div class="LoadingWrapper">
    <div class="Loading">
      <img id="PlanetBig" src="/content/assets/LoadingPlanetBig.svg" />
      <img id="PlanetSmall" src="/content/assets/LoadingPlanetSmall.svg" />
    </div>
  </div>
</template>

<style scoped>
.LoadingWrapper {
  height: 100vh;
}

.Loading {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#PlanetBig {
  width: 25vh;
  max-width: 40vw;
  height: auto;
  z-index: 20;
}

#PlanetSmall {
  width: 12vh;
  max-width: 20vw;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(110%, -50%);
  animation: orbit 2s ease-in-out 0s infinite;
}

@keyframes orbit {
  0% {
    transform: translate(110%, -50%);
    z-index: -1;
  }

  50% {
    transform: translate(-210%, -50%);
  }
}
</style>
