<template>
  <loading v-if="loading" />
  <div
    v-else-if="!this.$store.getters.isMobile && !this.isEditPassword && !this.isAddArrayProps"
    class="container"
  >
    <div class="ProfileInfo">
      <base-svg
        height="883.5"
        width="1492.56"
        path="M1.06195 82C10.262 16.8 58.2286 1.50002 81.062 2.00002L1411.06 1C1476.26 5.4 1493.23 58.5 1493.56 84.5V572.5C1487.16 634.9 1436.23 651.167 1411.56 651.5H907.562C839.962 656.7 824.062 712 824.562 739V802C819.362 867.6 766.729 884.333 741.062 884.5H83.562C14.7619 880.1 -0.104714 821 1.06195 792V82Z"
        elemStyle="width: var(--pm-width); position:absolute; top: 0; left: 0;"
      />
      <div class="SpansInfo" v-if="!this.isEditAccount">
        <br />
        <span style="font-size: calc(var(--font-size) * 1.6); font-weight: 900">
          {{ userInfo?.fullName }} </span
        ><br /><br />
        <span style="color: rgb(222, 222, 222)"
          >{{ userInfo?.email }} ({{ userInfo?.emailVerified ? "потвърден" : "непотвърден" }})</span
        ><br />
        <span style="color: rgb(222, 222, 222)">Клас: {{ userInfo?.studentClass }} </span><br />
        <span style="color: rgb(222, 222, 222)">Номер: {{ userInfo?.telNumber }} </span><br />
        <span style="color: rgb(222, 222, 222)">Размер тениска: {{ userInfo?.shirtSize }} </span
        ><br />

        <br />
        <button
          :disabled="isSendingVerEmail"
          class="BaseWhiteButton VerifyEmailButton"
          v-if="!userInfo?.emailVerified"
          @click="this.verifyEmail"
        >
          {{ isSendingVerEmail ? "Изпращане..." : "ПОТВЪРДИ" }}
        </button>
      </div>
      <form
        @keypress.esc.prevent
        @keypress.enter.prevent
        v-else
        autocomplete="off"
        @submit.prevent="editUser"
      >
        <base-select
          v-model="editUserInfo.shirtSize"
          :options="[
            { name: 'XS', value: 'XS' },
            { name: 'S', value: 'S' },
            { name: 'M', value: 'M' },
            { name: 'L', value: 'L' },
            { name: 'XL', value: 'XL' },
          ]"
        />
        <input
          @keydown.space.prevent
          type="text"
          name="telNumber"
          :style="
            this.errors?.errorNames?.includes('telNumber')
              ? 'background-color: red'
              : this.editUserInfo.telNumber.length !== 13
              ? 'border-bottom: 0.1vw solid yellow'
              : null
          "
          :value="getTelNumber"
          @input="(e) => (this.editUserInfo.telNumber = e.target.value)"
        />
        <button
          class="BaseWhiteButton EditUser"
          v-if="this.isEditAccount"
          style="background-color: rgba(186, 137, 255, 0.659)"
          :disabled="!anyEdits"
          @click="this.editUser"
        >
          Редактирай
        </button>
      </form>
      <button
        style="
          position: absolute;
          border-radius: 1vw;
          padding: 0px 1.5vw;
          bottom: calc(var(--top) + 18%);
          right: 3.5vw;
          font-size: 1.7vw;
        "
        class="BaseWhiteButton ProfileControlButton"
        v-if="!this.isEditPassword && this.isEditAccount"
        @click="this.switchEditPassword"
      >
        Смяна на парола
      </button>
      <div class="Allergies">
        <ArrayProps
          style="position: absolute; top: 0; left: 0"
          v-if="isEditAccount"
          :isEdit="isEditAccount"
          @clicked-add="setAddArrayProps"
          @clicked-x="spliceUserInfoArray"
          type="allergies"
          :isColored="false"
          :arrayProps="isEditAccount ? editUserInfo?.allergies : userInfo?.allergies"
        />
      </div>
      <div class="Technologies">
        <ArrayProps
          style="position: absolute; top: 0; left: 0"
          :isEdit="isEditAccount"
          @clicked-add="setAddArrayProps"
          @clicked-x="spliceUserInfoArray"
          type="technologies"
          :isColored="true"
          :arrayProps="isEditAccount ? editUserInfo?.technologies : userInfo?.technologies"
        />
      </div>
      <div class="ProfilePictureWrapper">
        <div class="ProfilePicture">
          <RegenSeedButton
            v-if="isEditAccount"
            :color="editUserInfo?.profilePicColor"
            @click="() => setProfilePic(true)"
          />
          <img
            :src="this.editUserInfo?.profilePicURL"
            style="width: 100%; height: 100%"
            alt="Профилна снимка"
          />
          <input
            v-if="isEditAccount"
            id="colorPicker"
            type="color"
            name="color"
            :value="`${editUserInfo.profilePicColor}`"
            @input="
              (e) => {
                editUserInfo.profilePicColor = e.target.value;
                this.setProfilePic(false);
              }
            "
          />
          <div v-if="getEdits.includes('profilePicURL')" class="X" @click="resetProfilePic">
            <img src="/content/assets/X.svg" alt="X" />
          </div>
        </div>
      </div>

      <EditXButton
        :clicked="switchEditMode"
        :condition="!this.isEditAccount"
        :clickedX="switchEditMode"
        style="
          width: calc(var(--pm-width) / 8);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(60%, 120%);
          cursor: pointer;
        "
      />
      <ExitXButton
        :clicked="logoutUser"
        style="
          width: calc(var(--pm-width) / 8);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(170%, 120%);
          cursor: pointer;
        "
      />
      <DeleteXButton
        :clicked="deleteUser"
        style="
          width: calc(var(--pm-width) / 8);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(280%, 120%);
          cursor: pointer;
        "
      />
    </div>

    <error-dialog
      v-if="this.errors?.errorMessages?.length && this.isEditAccount"
      :errorMessages="this.errors?.errorMessages"
    />

    <div class="AddArrayProps" v-if="isAddArrayProps">
      <AddArrayProps
        :arrayProps="
          currAPType == 'technologies' ? editUserInfo?.technologies : editUserInfo?.allergies
        "
        @clicked-main-x="this.nullAddAP"
        @clicked-add="this.addArrayProp"
        @clicked-x="this.spliceUserInfoArray"
        :type="currAPType"
        :isColored="currAPType === 'technologies'"
      />
    </div>
  </div>
  <form
    v-else-if="this.isEditPassword"
    @submit.prevent="this.editPassword"
    class="BaseGlassModal ChangePassword"
  >
    <password
      required
      :inputStyle="`all: unset; width: max-content; margin-bottom: 1vh; color: white; margin-top: calc(var(--between-elems-margin) * 0.9); border-bottom: 0.2vh white solid; font-size: ${
        this.$store.getters.isMobile ? '3rem' : '2rem'
      }; background: none; ${
        this.errors?.errorNames?.includes('password') ? 'background-color: red' : null
      } `"
      v-model.trim="newPassword"
      placeholder="Нова парола"
    />
    <password
      required
      :inputStyle="`all: unset; width: max-content; margin-bottom: 1vh; color: white; margin-top: calc(var(--between-elems-margin) * 0.9); border-bottom: 0.2vh white solid; font-size: ${
        this.$store.getters.isMobile ? '3rem' : '2rem'
      }; background: none; ${
        this.errors?.errorNames?.includes('password') ? 'background-color: red' : null
      } `"
      name="newPasswordConfirm"
      v-model.trim="newPasswordConfirm"
      placeholder="Повтори нова парола"
    />
    <button
      :style="`${this.$store.getters.isMobile ? 'font-size: 3rem' : 'font-size: 2rem'}`"
      class="BaseWhiteButton ProfileControlButton"
      type="submit"
    >
      Смени Парола
    </button>
    <button
      :style="`${this.$store.getters.isMobile ? 'font-size: 3rem' : 'font-size: 2rem'}`"
      class="BaseWhiteButton ProfileControlButton"
      v-if="this.isEditPassword"
      type="button"
      @click="this.switchEditPassword"
    >
      Отказ
    </button>
  </form>
  <div v-else>
    <div v-if="!this.isEditPassword && !this.isAddArrayProps">
      <div class="BaseGlassModal MyProfileMobile">
        <div class="" v-if="!this.isEditAccount">
          <br />
          <span style="font-size: 4rem; font-weight: 900"> {{ userInfo?.fullName }} </span
          ><br /><br />
          <span style="color: rgb(222, 222, 222)"
            >{{ userInfo?.email }} ({{
              userInfo?.emailVerified ? "потвърден" : "непотвърден"
            }})</span
          ><br />

          <span style="color: rgb(222, 222, 222)">Клас: {{ userInfo?.studentClass }} </span><br />
          <span style="color: rgb(222, 222, 222)">Номер: {{ userInfo?.telNumber }} </span><br />
          <span style="color: rgb(222, 222, 222)">Размер тениска: {{ userInfo?.shirtSize }} </span
          ><br />

          <br />
          <button
            style="margin-right: 2vw"
            :disabled="isSendingVerEmail"
            class="BaseWhiteButton VerifyEmailButton"
            v-if="!userInfo?.emailVerified"
            @click="this.verifyEmail"
          >
            {{ isSendingVerEmail ? "Изпращане..." : "ПОТВЪРДИ" }}
          </button>
        </div>

        <div @keypress.esc.prevent @keypress.enter.prevent v-else>
          <input
            @keydown.space.prevent
            type="text"
            id="TelNumber"
            name="telNumber"
            :style="
              this.errors?.errorNames?.includes('telNumber')
                ? 'background-color: red'
                : this.editUserInfo.telNumber.length !== 13
                ? 'border-bottom: 0.4vh solid yellow'
                : null
            "
            :value="getTelNumber"
            @input="(e) => (this.editUserInfo.telNumber = e.target.value)"
          />
        </div>
        <base-select
          v-if="isEditAccount"
          class="SelectMobile"
          v-model="editUserInfo.shirtSize"
          :options="[
            { name: 'XS', value: 'XS' },
            { name: 'S', value: 'S' },
            { name: 'M', value: 'M' },
            { name: 'L', value: 'L' },
            { name: 'XL', value: 'XL' },
          ]"
        />
        <div
          class="ProfilePictureWrapperMobile"
          :style="`${isEditAccount ? 'margin-top: 5vh;' : 'margin-top: 3vh;'}`"
        >
          <div class="ProfilePicture">
            <RegenSeedButton
              v-if="isEditAccount"
              :color="editUserInfo?.profilePicColor"
              @click="() => setProfilePic(true)"
            />
            <img
              :src="this.editUserInfo.profilePicURL"
              style="width: 100%; height: 100%"
              alt="Профилна снимка"
            />
            <input
              v-if="isEditAccount"
              id="colorPicker"
              type="color"
              name="color"
              :value="`${editUserInfo.profilePicColor}`"
              @input="
                (e) => {
                  editUserInfo.profilePicColor = e.target.value;
                  this.setProfilePic(false);
                }
              "
            />
            <div v-if="getEdits.includes('profilePicURL')" class="X" @click="resetProfilePic">
              <img src="/content/assets/X.svg" alt="X" />
            </div>
          </div>
        </div>
        <button
          class="BaseWhiteButton EditUserMobile"
          v-if="this.isEditAccount"
          style="background-color: rgba(186, 137, 255, 0.659); margin-right: 2vw"
          :disabled="!anyEdits"
          @click="this.editUser"
        >
          Редактирай
        </button>
        <button style="margin-right: 2vw" class="BaseWhiteButton" @click="logoutUser">Изход</button>
        <button
          class="BaseWhiteButton"
          style="background-color: rgb(219, 7, 7)"
          @click="deleteUser"
        >
          Изтрий Профил
        </button>
        <div class="AllergiesMobile">
          <ArrayProps
            v-if="isEditAccount"
            :isEdit="isEditAccount"
            @clicked-add="setAddArrayProps"
            @clicked-x="spliceUserInfoArray"
            type="allergies"
            :isColored="false"
            :arrayProps="isEditAccount ? editUserInfo?.allergies : userInfo?.allergies"
          />
        </div>
        <div class="TechnologiesMobile">
          <ArrayProps
            :isEdit="isEditAccount"
            @clicked-add="setAddArrayProps"
            @clicked-x="spliceUserInfoArray"
            type="technologies"
            :isColored="true"
            :arrayProps="isEditAccount ? editUserInfo?.technologies : userInfo?.technologies"
          />
        </div>

        <button
          style="margin-right: 2vw"
          class="BaseWhiteButton ProfileControlButton"
          v-if="!this.isEditPassword && this.isEditAccount"
          @click="this.switchEditPassword"
        >
          Смяна на парола
        </button>
        <button class="BaseWhiteButton" @click="switchEditMode">
          {{ isEditAccount ? "Отказ" : "Редактирай" }}
        </button>
      </div>
    </div>

    <error-dialog
      v-if="this.errors?.errorMessages?.length && this.isEditAccount"
      :errorMessages="this.errors?.errorMessages"
    />

    <div class="AddArrayProps" v-if="isAddArrayProps">
      <AddArrayProps
        :arrayProps="
          currAPType == 'technologies' ? editUserInfo?.technologies : editUserInfo?.allergies
        "
        @clicked-main-x="this.nullAddAP"
        @clicked-add="this.addArrayProp"
        @clicked-x="this.spliceUserInfoArray"
        :type="currAPType"
        :isColored="currAPType === 'technologies'"
      />
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosInstance";
import getChangedProps from "../util/getChangedProps";
import errorHandler from "../util/errorHandler";
import sendVerificationEmail from "../util/sendVeficationEmail";
import createTokenPair from "../util/createTokenPair";
import ArrayProps from "../components/ArrayProps.vue";
import DeleteXButton from "../components/DeleteButton.vue";
import ExitXButton from "../components/ExitButton.vue";
import EditXButton from "../components/EditButton.vue";
import AddArrayProps from "../components/AddArrayProps.vue";
import RegenSeedButton from "../components/RegenSeedButton.vue";
import getColorFromDBURL from "../util/getColorFromDBURL";

export default {
  name: "UserInfo",
  data() {
    return {
      userInfo: null,
      editUserInfo: null,
      loading: true,
      errors: {},
      isEditAccount: false,
      isEditPassword: false,
      isAddArrayProps: false,
      newPassword: "",
      newPasswordConfirm: "",
      isSendingVerEmail: false,
    };
  },
  async beforeCreate() {
    try {
      window.addEventListener("keydown", (e) => {
        if (e.code === "Escape") {
          this.nullEditAccount();
        }
        if (e.code === "Enter") {
          this.editUser();
        }
      });
      const { success, accessToken } = await createTokenPair(this.$store);
      if (!success) {
        this.$router.go(0);
        return;
      }
      const userInfo = await (
        await axiosInstance.get("/user/get-user-token", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      ).data.response;
      this.errors = null;
      this.userInfo = {
        ...userInfo,
      };
      this.userInfo.technologies = [...userInfo.technologies];
      this.userInfo.allergies = [...userInfo.allergies];
      this.editUserInfo = {
        ...userInfo,
      };
      this.editUserInfo.technologies = [...userInfo.technologies];
      this.editUserInfo.allergies = [...userInfo.allergies];
      this.editUserInfo.profilePicColor = getColorFromDBURL(userInfo.profilePicURL);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.errors = errorHandler(err);
      this.$router.replace("/");
      this.$store.dispatch("tryLogin");
    }
  },
  components: {
    ArrayProps,
    DeleteXButton,
    ExitXButton,
    EditXButton,
    AddArrayProps,
    RegenSeedButton,
  },
  computed: {
    getEdits() {
      return Object.keys(getChangedProps({ ...this.userInfo }, { ...this.editUserInfo }));
    },
    anyEdits() {
      return !!this.getEdits.length && this.editUserInfo.telNumber.length === 13;
    },
    getTelNumber() {
      let telNumber = this.editUserInfo.telNumber;
      if (!telNumber.startsWith("+359")) {
        this.setTelNumber(3);
      }
      if (telNumber.length !== 13) {
        this.setTelNumber(4);
        return this.editUserInfo.telNumber.slice(0, 13);
      }
      return this.editUserInfo.telNumber;
    },
  },
  methods: {
    spliceUserInfoArray(arrayInfoName, index) {
      this.editUserInfo[arrayInfoName].splice(index, 1);
    },
    setAddArrayProps(type) {
      this.currAPType = type;
      this.isAddArrayProps = true;
    },
    setTelNumber(index) {
      this.editUserInfo.telNumber = "+359" + this.editUserInfo.telNumber.slice(index);
      this.editUserInfo.telNumber = this.editUserInfo.telNumber.slice(0, 13);
    },
    setProfilePic(resetAll) {
      if (resetAll) {
        this.editUserInfo.profilePicURL = `https://avatars.dicebear.com/api/adventurer/${Date.now()}.svg?background=%23${
          this.editUserInfo.profilePicColor.split("#")[1]
        }&mood[]=happy&rotate=16&translateX=-1&translateY=3`;
      } else {
        this.editUserInfo.profilePicURL = this.editUserInfo.profilePicURL.replace(
          `?background=%23${getColorFromDBURL(this.editUserInfo.profilePicURL).slice(1)}`,
          `?background=%23${this.editUserInfo.profilePicColor.slice(1)}`
        );
      }
    },
    resetProfilePic() {
      this.editUserInfo.profilePicURL = this.userInfo.profilePicURL;
      this.editUserInfo.profilePicColor = getColorFromDBURL(this.editUserInfo.profilePicURL);
    },
    addArrayProp(type, arrayProp) {
      this.editUserInfo[type].push(arrayProp);
    },
    changeTechnologies(technologiesData) {
      this.editUserInfo.technologies = [...technologiesData];
    },
    switchEditPassword() {
      this.newPassword = "";
      this.newPasswordConfirm = "";
      this.isEditPassword = !this.isEditPassword;
    },
    nullEditAccount() {
      this.switchEditMode();
      this.isEditAccount = false;
      this.nullAddAP();
    },
    nullAddAP() {
      this.isAddArrayProps = false;
      this.currAPType = null;
    },
    async editUser() {
      const editProps = getChangedProps(this.userInfo, this.editUserInfo);
      if (this.anyEdits) {
        this.loading = true;
        try {
          const { success, accessToken } = await createTokenPair(this.$store);
          if (!success) {
            this.$router.go(0);
            return;
          }
          await axiosInstance.post(
            "/user/edit-user",
            {
              editProps,
            },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          this.errors = null;
          if (editProps.email) {
            this.userInfo.emailVerified = false;
          }
          this.userInfo = { ...this.userInfo, ...this.editUserInfo };
          this.userInfo.allergies = [...this.editUserInfo.allergies];
          this.userInfo.technologies = [...this.editUserInfo.technologies];
          this.loading = false;
          this.isEditAccount = false;
        } catch (err) {
          this.loading = false;
          this.errors = errorHandler(err);
        }
      }
    },
    async editPassword() {
      this.loading = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        await axiosInstance.post(
          "/user/reset-password",
          {
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPasswordConfirm,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        this.errors = null;
        this.loading = false;
        this.$store.dispatch("logout");
        this.$router.replace("/");
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
    logoutUser() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    async verifyEmail() {
      this.isSendingVerEmail = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        await sendVerificationEmail(accessToken, this.$store.getters.email);
        this.isSendingVerEmail = false;
      } catch (err) {
        this.errors = errorHandler(err);
        this.isSendingVerEmail = false;
      }
    },
    async deleteUser() {
      try {
        let accountNameCheck = "";
        accountNameCheck = window.prompt(
          `За да изтриете акаунта си, напишете ${this.userInfo.fullName}`,
          ""
        );
        if (accountNameCheck === null) {
          return;
        }
        if (accountNameCheck.trim() === this.userInfo.fullName && this.userInfo._id) {
          try {
            this.loading = true;
            const { success, accessToken } = await createTokenPair(this.$store);
            if (!success) {
              this.$router.go(0);
              return;
            }
            await axiosInstance.delete("/user/delete-user", {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            this.$store.dispatch("logout");
            this.$router.replace("/");
            this.errors = null;
            this.userInfo = null;
            this.editUserInfo = null;
            this.loading = false;
          } catch (err) {
            this.errors = errorHandler(err);
            this.loading = false;
          }
        } else {
          window.alert("Изтриването на акаунта прекратено");
        }
      } catch (err) {
        alert(err);
      }
    },
    switchEditMode() {
      if (this.isEditAccount) {
        this.editUserInfo = {
          ...this.userInfo,
        };
        this.editUserInfo.allergies = [...this.userInfo.allergies];
        this.editUserInfo.technologies = [...this.userInfo.technologies];
        this.editUserInfo.profilePicColor = getColorFromDBURL(this.editUserInfo.profilePicURL);
      }
      this.isEditAccount = !this.isEditAccount;
    },
  },
};
</script>
<style scoped>
.container {
  --top: 10%;
  --left: 6%;
  --pm-width: 60vw;
  --pi-height: calc(var(--pm-width) / 1.666);
  --between-elems-margin: 1.3vw;
  --my-profile-modal-width: 60%;
}

.ProfileInfo {
  width: var(--pm-width);
  height: var(--pi-height);
  margin-left: calc((100vw - var(--pm-width)) / 2);
  margin-top: calc((100vh - var(--pi-height)) / 2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  --font-size: calc(var(--my-profile-modal-width) + 1.2vw);
  font-size: var(--font-size);
  mix-blend-mode: normal;
  text-align: left;
}

.BaseWhiteButton {
  font-size: var(--font-size);
  padding: calc(var(--font-size) * 0.02);
  border: var(--medium-border);
}

.SpansInfo {
  width: 34vw;
  position: absolute;
  top: calc(var(--top) - 2vw);
  left: var(--left);
}

.Technologies {
  position: absolute;
  left: var(--left);
  bottom: calc(var(--top) + var(--between-elems-margin) + var(--array-props-height) - 1vw);
}

.AddArrayProps {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Allergies {
  position: absolute;
  left: var(--left);
  bottom: calc(
    var(--top) + var(--between-elems-margin) * 2 + var(--array-props-height) +
      var(--array-props-height)
  );
}

.ProfilePictureWrapper {
  position: absolute;
  top: var(--top);
  left: 61.5%;
}

.ProfilePicture {
  position: relative;
  width: 19vw;
  height: 19vw;
  border-radius: 50%;
  border: 0.3vw white solid;
}

.ProfilePicture button {
  position: absolute;
  top: 0;
  right: 0;
  width: 5vw;
  transform: translate(40%, -40%);
}

.ProfilePicture button:hover {
  border: var(--border);
}

.ProfilePicture input {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(10%, 10%);
}

.X {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-40%, -40%);
  --dim: 5vw;
  width: var(--dim);
  height: var(--dim);
  border-radius: 50%;
  border: 0.2vw solid white;
  cursor: pointer;
}

.X img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}

#colorPicker {
  border-radius: 50%;
  height: 3vw;
  width: 3vw;
  border: none;
  outline: none;
  -webkit-appearance: none;
}

.VerifyEmailButton {
  border-radius: calc(var(--border-radius) * 0.7);
  padding: 0.7vw;
}

#colorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
}
#colorPicker::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.ProfilePicture img {
  border-radius: 50%;
  mix-blend-mode: normal;
}

span {
  color: rgba(255, 255, 255, 0.9);
  font-size: var(--font-size);
}

.ProfileInfo form {
  position: absolute;
  top: var(--between-elems-margin);
  left: var(--left);
  font-size: inherit;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: var(--spans-outline-width);
}

.ProfileInfo form input {
  all: unset;
  color: white;
  margin-top: calc(var(--between-elems-margin) * 0.9);
  width: calc(13.6vw);
  border-bottom: 0.1vw white solid;
  font-size: inherit;
  background: none;
}

.ProfileInfo form label {
  display: flex;
  visibility: hidden;
}

.EditUser {
  position: absolute;
  font-size: calc(var(--font-size) * 0.7);
  width: 75%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 1100%);
  border: 0.2vw solid rgb(255, 255, 255);
}

button:disabled {
  color: rgba(255, 255, 255, 0.5);
  border: 0.2vw solid rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.ProfilePictureWrapperMobile {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: max-content;
  margin-bottom: 2vh;
}

.ProfilePictureWrapperMobile form {
  display: flex;
}

.ProfilePictureWrapperMobile .ProfilePicture {
  height: 20vh;
  width: 20vh;
}

.ProfilePictureWrapperMobile .Button,
.ProfilePictureWrapperMobile .X {
  height: 7vh;
  width: 7vh;
}
.ProfilePictureWrapperMobile #colorPicker {
  height: 5vh;
  width: 5vh;
}

.ProfilePictureWrapperMobile .X {
  transform: translate(-55%, -55%);
}

.ProfilePictureWrapperMobile .Button {
  transform: translate(55%, -55%);
}

.MyProfileMobile,
.MyProfileMobile span,
.MyProfileMobile input,
.MyProfileMobile button {
  font-size: 4rem;
}

.MyProfileMobile,
.MyProfileMobile button {
  padding: 1.2vh;
}

.MyProfileMobile {
  margin-top: 30%;
  margin-left: auto;
  margin-right: auto;
}

.TechnologiesMobile,
.AllergiesMobile {
  width: max-content;
  position: relative;
  margin: 1vh;
  margin-left: auto;
  margin-right: auto;
}

.SelectMobile {
  display: flex;
  margin: 1vh;
  font-size: 3rem !important;
  margin-left: auto;
  margin-right: auto;
}

#TelNumber {
  all: unset;
  color: white;
  margin-top: calc(var(--between-elems-margin) * 0.9);
  border-bottom: 0.4vh white solid;
  font-size: 4rem;
  background: none;
}

.ChangePassword {
  margin-left: auto;
  margin-right: auto;
  padding: 1.5vh;
  margin-top: 41.5vh;
}

.ChangePassword button {
  font-size: 3rem;
  padding: 0.5vh;
  margin-right: 0.5vh;
}
</style>
