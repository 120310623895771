<template>
  <loading v-if="this.loading" />
  <form
    v-else
    autocomplete="off"
    @submit.prevent="resetPassword"
    :class="`BaseGlassModal ResetPassword ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
  >
    <label for="newPassword">Нова парола</label>
    <password
      name="newPassword"
      v-model.trim="newPassword"
      required
      :inputStyle="this.errors?.errorNames?.includes('password') ? 'background-color: red' : null"
    />

    <br />

    <label for="newPasswordConfirm">Повтори нова парола</label>
    <password
      :inputStyle="this.errors?.errorNames?.includes('password') ? 'background-color: red' : null"
      name="newPasswordConfirm"
      v-model.trim="newPasswordConfirm"
      required
    />

    <br />

    <button class="BaseWhiteButton" id="MainButton" type="submit">Презапиши парола</button>
    <button class="BaseWhiteButton" type="button" @click="this.$router.replace('/auth')">
      Отказ
    </button>

    <error-dialog v-if="errors?.errorMessages?.length" :errorMessages="errors?.errorMessages" />
  </form>
</template>

<script>
import axiosInstance from "../axiosInstance";
import errorHandler from "../util/errorHandler";

export default {
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      newPasswordConfirm: "",
      loading: false,
      errors: null,
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      const { token } = this.$route.params;
      try {
        await axiosInstance.post(
          "/user/reset-password",
          {
            newPassword: this.newPassword,
            newPasswordConfirm: this.newPasswordConfirm,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.loading = false;
        this.newPassword = "";
        this.newPasswordConfirm = "";
        this.errors = null;
        this.$router.replace("/auth");
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
  },
};
</script>

<style scoped>
.ResetPassword {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  padding: 1.2vw;
  text-align: center;
}

#MainButton {
  margin-right: 1vw;
}

.isMobile span,
.isMobile button,
.isMobile label {
  font-size: calc(var(--font-size) * 2);
}
</style>
