<template>
  <loading v-if="loading" />
  <div v-else :class="`BaseGlassModal Discord ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
    <span id="bold"
      >Hack TUES Infinity <br />
      Discord</span
    >
    <img src="/content/assets/Discord.svg" alt="Discord Лого" id="DiscordLogo" />
    <div style="display: block; height: max-content" v-if="userInfo.discordId == ''">
      <span>1. Добави Discord акаунта си в профила</span>
      <br />
      <br />
      <a
        target="_blank"
        :href="
          'https://discord.com/api/oauth2/authorize?client_id=925672049760927845&redirect_uri=' +
          encodeURIComponent(this.currentRouteName) +
          '&response_type=code&scope=identify'
        "
        class="BaseWhiteButton giant"
        >Влез с дискорд</a
      >
    </div>

    <div v-else>
      <br />
      <span>2. Влез в сървъра ни</span>
      <br />
      <br />
      <a href="https://discord.gg/3WUYXTkCsb" target="_blank" class="BaseWhiteButton giant"
        >Към Дискорд</a
      >
    </div>
    <error-dialog v-if="errors?.errorMessages.length" :errorMessages="errors?.errorMessages" />
    <div>
      <br />
      <br />
      <p class="forgor">
        Нямаш роли в Дискорд? <i class="clickable" v-on:click="deleteDiscord">Забрави ме</i>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import axiosInstance from "../axiosInstance";
import createTokenPair from "../util/createTokenPair";
import errorHandler from "../util/errorHandler";

export default {
  name: "Discord",
  data() {
    return {
      loading: true,
      userInfo: null,
      errors: null,
    };
  },
  methods: {
    async getAccessToken(code) {
      try {
        const formData = new URLSearchParams({
          client_id: "925672049760927845",
          client_secret: "Lao3k6PmpQMTUvtH-ta1spu9dI1dFOFZ",
          grant_type: "authorization_code",
          code: code.toString(),
          redirect_uri: this.currentRouteName,
        });

        const response = await axios.post(
          "https://discord.com/api/v8/oauth2/token",
          formData.toString(),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        return response.data.access_token;
      } catch (err) {
        console.log(err);
      }
    },
    async updateDiscordId(code) {
      // Get the access token from the code in the query
      const access_token = await this.getAccessToken(code);

      // Get user info via Discord API
      try {
        const response = await axios.get("https://discord.com/api/users/@me", {
          headers: { Authorization: `Bearer ${access_token}` },
        });

        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }

        // Add user's discord id to the database
        await axiosInstance.post(
          "/user/edit-user",
          {
            editProps: { discordId: response.data.id },
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.userInfo.discordId = response.data.id;
        this.$store.commit("setDiscordId", { discordId: response.data.id });
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDiscord() {
      try {
        const { success, accessToken } = await createTokenPair(this.$store);

        if (!success) {
          this.$router.go(0);
          return;
        }

        // Add user's discord id to the database
        await axiosInstance.post(
          "/user/edit-user",
          {
            editProps: { discordId: "" },
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        this.userInfo.discordId = "";
        this.$store.commit("setDiscordId", { discordId: "" });
      } catch (err) {
        print(err);
      }
    },
  },
  computed: {
    currentRouteName() {
      //   return location.toString();
      return location.protocol + "//" + location.host + location.pathname;
    },
    redirectUrl() {
      return (
        "https://discord.com/api/oauth2/authorize?client_id=925672049760927845&redirect_uri=" +
        encodeURIComponent(this.currentRouteName) +
        "&response_type=code&scope=identify"
      );
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const { success, accessToken } = await createTokenPair(this.$store);
      if (!success) {
        this.$router.go(0);
        return;
      }
      const userInfo = await (
        await axiosInstance.get("/user/get-user-token", {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      ).data.response;

      this.userInfo = userInfo;
      if (this.$route.query.code && userInfo.discordId == "") {
        await this.updateDiscordId(this.$route.query.code);
      }
      this.loading = false;
    } catch (err) {
      this.errors = errorHandler(err);
    }
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.forgor {
  bottom: 0;
}

.Discord {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2vw 4vw;
  text-align: center;
}

#bold {
  font-weight: 900;
}

.Discord span {
  font-size: calc(var(--font-size) * 2.5);
}

.isMobile span {
  font-size: calc(var(--font-size) * 4);
}

.isMobile i {
  font-size: calc(var(--font-size) * 4) !important;
}

.giant {
  position: relative;
  font-size: calc(var(--font-size) * 1.5);
  color: beige;
  font-weight: bolder;
  border: var(--medium-border);
}

#DiscordLogo {
  position: absolute;
  top: 1vw;
  left: 1vw;
  width: 10%;
}
@media only screen and (max-width: 850px) {
  .Discord span {
    font-size: calc(var(--font-size) * 4);
  }

  .isMobile span {
    font-size: calc(var(--font-size) * 6);
  }
  .giant {
    font-size: calc(var(--font-size) * 4.5);
  }
}
</style>
