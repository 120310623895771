<template>
  <div class="Wrapper">
    <div :class="`AbsoluteWrapper ${this.$store.getters.isMobile ? 'isMobile' : ''}`">
      <div class="SpansOutline"></div>
      <div class="ArrayProps">
        <div class="ArrayPropsWrapper">
          <div class="gridWrapper paddingTop">
            <div class="grid">
              <Prop
                :key="index"
                @clicked-x="this.$emit('clicked-x', type, index)"
                @click="this.$emit('clicked-x', type, index)"
                v-for="(arrayProp, index) in arrayProps"
                :id="index"
                :isColored="isColored"
                :isEdit="true"
                :arrayProp="arrayProp"
                :type="type"
                :style="index === leftoverOptions.length - 1 ? 'margin-bottom: 0px;' : ''"
              />
            </div>
          </div>
        </div>
        <div class="Border"></div>
        <div class="ArrayPropsWrapper">
          <div class="gridWrapper">
            <div class="grid">
              <Prop
                :key="index"
                @click="this.$emit('clicked-add', type, arrayProp)"
                v-for="(arrayProp, index) in leftoverOptions"
                :isEdit="false"
                :id="index"
                :isColored="isColored"
                :arrayProp="arrayProp"
                :style="`cursor: pointer;${
                  index === leftoverOptions.length - 1 ? 'margin-bottom: 0px;' : ''
                }`"
                :type="type"
              />
              <span v-if="!leftoverOptions.length">{{
                type === "technologies"
                  ? "Брей, много технологии познаваш... Браво!"
                  : "Няма повече алергии."
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, -75%);'
          : ''
      }`"
      class="ButtonXTick X"
      @click="this.$emit('clicked-main-x')"
    >
      <img src="/content/assets/X.svg" alt="X" />
    </div>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, 75%);'
          : ''
      }`"
      class="ButtonXTick Tick"
      @click="this.$emit('clicked-main-x')"
    >
      <img src="/content/assets/Tick.svg" alt="Tick" />
    </div>
  </div>
</template>

<script>
import Prop from "./ui/Prop.vue";
import allergiesOptions from "../util/allergiesOptions";
import technologiesOptions from "../util/technologiesOptions";
import getUniqueArrayElements from "../util/getUniqueArrayElements";

export default {
  name: "AddArrayProps",
  components: {
    Prop,
  },
  props: {
    type: String,
    isColored: Boolean,
    arrayProps: {
      required: true,
    },
  },
  computed: {
    leftoverOptions() {
      const leftoverOptions = [
        ...getUniqueArrayElements(
          this.arrayProps,
          this.type == "allergies" ? allergiesOptions : technologiesOptions
        ),
      ];
      return leftoverOptions;
    },
  },
};
</script>

<style scoped>
.Wrapper {
  scrollbar-width: var(--scrollbar-width);
  scrollbar-color: rgba(255, 255, 255, 0.6) rgba(255, 255, 255, 0);
}

.isMobile span {
  font-size: calc(var(--font-size) * 4);
}

.isMobile,
.isMobile .SpansOutline,
.isMobile .ArrayPropsWrapper {
  max-height: calc(var(--array-props-height) * 3.5);
  height: calc(var(--array-props-height) * 3.5);
  width: calc(var(--array-props-width) * 3.5);
  max-width: calc(var(--array-props-width) * 3.5);
  overflow-y: auto;
  overflow-x: hidden;
}

.isMobile .SpansOutline {
  max-height: calc(var(--array-props-height) * 9);
  height: calc(var(--array-props-height) * 9);
  z-index: 10;
  width: calc(var(--array-props-width) * 3.5);
  max-width: calc(var(--array-props-width) * 3.5);
}

.isMobile {
  max-height: calc(var(--array-props-height) * 9);
  height: calc(var(--array-props-height) * 9);
}

.isMobile .Border {
  width: calc(var(--array-props-width) * 3.5);
  max-width: calc(var(--array-props-width) * 3.5);
}

.isMobile .ArrayProps {
  width: calc(var(--array-props-width) * 3.5);
}

.isMobile .gridWrapper {
  width: calc(var(--array-props-width) * 3.5);
  padding-top: 3vw;
  padding-left: 1.5vw;
}

.isMobile .ArrayPropsWrapper {
  max-height: calc(var(--array-props-height) * 4.5);
  height: calc(var(--array-props-height) * 4.5);
}

.SpansOutline {
  height: var(--add-technologies-height);
  width: var(--add-technologies-width);
  border: var(--border);
  border-radius: var(--border-radius);
  backdrop-filter: blur(var(--blur));
}

.Border {
  width: var(--add-technologies-width);
  border-top: var(--border);
  height: 0;
}

.ArrayProps {
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  color: white;
  width: var(--add-technologies-width);
}

.ArrayPropsWrapper {
  max-height: calc(var(--add-technologies-height) / 2);
  height: calc(var(--add-technologies-height) / 2 - 0.1vw);
  z-index: 10;
  width: calc(var(--add-technologies-width) + 3vw);
  overflow-y: auto;
  overflow-x: hidden;
}

.ArrayPropsWrapper button {
  all: unset;
  box-sizing: border-box;
  background: none;
  border: var(--border);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  position: absolute;
  width: var(--add-technologies-width);
  color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  height: 2.5vw;
  padding: 0px;
  background-color: white;
  text-align: center;
  cursor: pointer;
}

.gridWrapper {
  padding-right: 0.5vw;
  padding-left: 1vw;
  padding-top: 1vw;
  width: var(--add-technologies-width);
}

.paddingTop {
  padding-top: 1.5vw;
}

.grid {
  display: inline;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background: rgba(0, 151, 19, 0);
  height: 99%;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  border-radius: var(--border-radius);
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  background: rgba(0, 151, 19, 0);
  border-radius: var(--border-radius);
}
</style>
