import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/HomePage.vue";
import Teams from "../pages/AllTeamsPage.vue";
//import Auth from "../pages/auth/UserAuth.vue";
import NotFound from "../pages/NotFound.vue";
import Profile from "../pages/MyProfile.vue";
//import TeamRegistration from "../pages/TeamRegistration.vue";
import Admin from "../pages/Admin.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import ResetPassword from "../pages/ResetPassword.vue";
import ConfirmEmail from "../pages/ConfirmEmail.vue";
import AcceptTeamInvite from "../pages/AcceptTeamInvite.vue";
import acceptTeamApplication from "../pages/AcceptTeamApplication.vue";
import OurTeam from "../pages/OurTeam.vue";
import Mentors from "../pages/Mentors.vue";
import store from "../store/index.js";
import Team from "../pages/TeamPage.vue";
import Archive from "../pages/Archive.vue";
import Regulation from "../pages/Regulation.vue";
import Discord from "../pages/Discord.vue";
import Notifications from "../pages/Notifications.vue";
import DistributionPage from "../pages/DistributionPage.vue";
import ThemePage from "../pages/ThemePage.vue";
import CertificatePage from "../pages/CertificatePage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/teams",
    name: "teams",
    component: Teams,
  },
  /*{
    path: "/auth",
    name: "auth",
    component: Auth,
    meta: { requiresUnauth: true },
  },*/
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  /*
  {
    path: "/team-registration",
    name: "teamRegistration",
    component: TeamRegistration,
    meta: { requiresAuth: true, requiresNotHasTeam: true },
  },*/
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/oejksuakqabkspwhzksmvfoaauzeiyy",
    name: "admin",
    component: Admin,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: { requiresUnauth: true },
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:token",
    name: "resetPassword",
    meta: { requiresUnauth: true },
    component: ResetPassword,
  },
  {
    path: "/confirm-email/:token",
    name: "confirmEmail",
    component: ConfirmEmail,
  },
  {
    path: "/regulation",
    name: "Regulation",
    component: Regulation,
  },
  {
    path: "/discord",
    name: "discord",
    meta: { requiresAuth: true },
    component: Discord,
  },
  {
    path: "/notifications",
    name: "notifications",
    mata: { requiresAuth: true },
    component: Notifications,
  },
  {
    path: "/team/accept-team-invite/:token",
    name: "acceptTeamInvite",
    component: AcceptTeamInvite,
  },
  {
    path: "/archives",
    name: "archives",
    component: Archive,
  },
  {
    path: "/archive/:archiveName",
    name: "archive",
    component: Archive,
  },
  {
    path: "/team/accept-team-application/:token",
    name: "acceptTeamApplication",
    component: acceptTeamApplication,
  },
  {
    path: "/:notFound(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/ourteam",
    name: "OurTeam",
    component: OurTeam,
  },
  {
    path: "/mentors",
    name: "Mentors",
    component: Mentors,
  },
  {
    path: "/distribution",
    name: "DistributionPage",
    component: DistributionPage,
  },
  {
    path: "/themes",
    name: "ThemePage",
    component: ThemePage,
  },
  {
    path: "/certificate/:certificateId",
    name: "CertificatePage",
    component: CertificatePage,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  mode: "history",
  // eslint-disable-next-line
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0)
  },
  routes,
});

router.beforeEach(async (to, _, next) => {
  if (to.meta.requiresNotHasTeam && store.getters.hasTeam) {
    next("/");
  } else if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/auth");
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next("/");
  } else {
    next();
  }
});

export default router;
