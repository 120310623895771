<template>
  <div class="Regulation" :style="`${this.firefox ? '' : ''}`">
    <div
      v-for="(segment, index) in regulation"
      :key="index"
      :class="`SegmentWrapper ${this.$store.getters.isMobile ? 'Mobile' : ''}`"
      :style="`${'width: 90% !important;'}`"
    >
      <div class="BaseGlassModal Segment Question" v-if="segment.title">
        <span> {{ segment.title }}</span>
        <button
          :class="`${showSegments.includes(index) ? 'Rotated' : ''} ${
            !this.$store.getters.isMobile ? 'Hover' : ''
          }`"
          @click="toggleShowSegment(index)"
        >
          <span>+</span>
        </button>
      </div>
      <div
        class="BaseGlassModal Segment SegmentBody"
        v-if="showSegments.includes(index) || !segment.title"
        v-html="segment.body"
      ></div>
    </div>
  </div>
</template>

<script>
import regulation from "../util/pageTexts/regulation";

export default {
  name: "Regulation",
  data() {
    return {
      regulation,
      showSegments: [],
      firefox: true,
    };
  },
  methods: {
    toggleShowSegment(index) {
      if (this.showSegments.includes(index)) {
        this.showSegments = this.showSegments.filter((elem) => elem != index);
      } else {
        this.showSegments.push(index);
      }
    },
  },
  mounted() {
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      this.firefox = true;
    }
  },
};
</script>

<style>
.SegmentWrapper {
  --font-size-here: calc(var(--font-size) * 2);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4vh;
  width: clamp(30vw, 60%, 80vw);
}

.Regulation {
  margin-top: 5vh;
  width: 100%;
}

.Mobile {
  --font-size-here: calc(var(--font-size) * 4.2);
}

.Segment {
  width: 100%;
  color: white;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 2vh 10%;
  backdrop-filter: blur(var(--blur));
  text-align: left;
}

.Segment {
  display: inline-block !important;
  width: 80% !important;
}

.Question {
  position: relative;
  text-align: center;
  margin-bottom: 1vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Question span {
  display: inline-block;
  width: calc(100% - 10vh) !important;
}

.Question button {
  all: unset;
  display: inline-block;
  --dim: 5vh;
  width: var(--dim);
  height: var(--dim);
  border: var(--medium-border);
  background: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  transition: ease all 0.3s;
  position: absolute;
  top: 50%;
  right: 1%;
  margin-top: calc(var(--dim) * -0.55);
  text-align: center;
}

.SegmentBody li {
  margin-top: 2vh;
}

.Question button span {
  position: absolute;
  top: 50%;
  font-size: calc(var(--dim) * 0.8);
  left: 29%;
  transform: translate(-50%, -50%);
}

.Hover:hover {
  border: var(--medium-border-dark);
  color: var(--white-dark);
}

.Rotated {
  transform: rotateZ(45deg) !important;
}

.Question span {
  font-size: calc(var(--font-size-here) * 0.9);
}

.Span {
  font-size: calc(var(--font-size-here) * 0.7) !important;
  text-align: justify;
  display: block;
}

.Span a {
  color: white;
  font-size: inherit;
}

.Separator {
  width: 100%;
  height: 0.15vh;
  background-color: white;
}

.Inner {
  padding-left: 5%;
}
</style>
