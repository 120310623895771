<template>
  <div class="BaseGlassModal Members">
    <div
      v-for="(result, index) in content"
      :key="index"
      :style="`margin-bottom: 1vw; outline: var(--border); ${
        index !== content.length - 1 ? 'margin-right: 1vw;' : ''
      }`"
      class="Member"
    >
      <div v-if="result._id !== this.$store.getters.id" style="width: 100%; height: 100%">
        <img class="MemberImg" :src="result.profilePicURL" alt="" srcset="" />
        <span>{{ result.fullName }} - {{ result.studentClass }}</span> <br />
        <span>{{ result.emailVerified ? "Потвърден имейл" : "Непотвърден имейл" }}</span>
      </div>
      <div v-if="isEdit" class="ButtonXTick X" @click="clickedX(index)">
        <img src="/content/assets/X.svg" alt="X" />
      </div>
      <div v-if="isApprovable && isEdit" class="ButtonXTick Tick" @click="approve(result._id)">
        <img src="/content/assets/Tick.svg" alt="Tick" />
      </div>
    </div>
    <span v-if="!this.content.length && this.modelValue.length">Няма какво друго да изтриеш</span>
    <span v-if="!this.modelValue.length">Доста е празно тук</span>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, -75%);'
          : ''
      }`"
      class="ButtonXTick X"
      @click="clickedMainX()"
    >
      <img src="/content/assets/X.svg" alt="X" />
    </div>
    <div
      :style="`${
        this.$store.getters.isMobile
          ? 'width: 9vw; height: 9vw; transform: translate(75%, 75%);'
          : ''
      }`"
      class="ButtonXTick Tick"
      @click="clickedMainTick()"
    >
      <img src="/content/assets/Tick.svg" alt="Tick" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamMembersList",
  emits: ["update:modelValue", "update:show"],
  data() {
    return {
      content: null,
      initial: null,
    };
  },
  beforeMount() {
    this.content = [...this.modelValue];
    this.initial = [...this.modelValue];
  },
  props: {
    modelValue: {
      required: true,
      typr: Array,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    isApprovable: {
      required: true,
      type: Boolean,
    },
    approve: {
      required: false,
    },
  },
  methods: {
    clickedMainTick() {
      this.$emit("update:modelValue", this.content);
      this.$emit("update:show", false);
    },
    clickedX(index) {
      this.content.splice(index, 1);
    },
    clickedMainX() {
      this.$emit("update:modelValue", this.initial);
      this.$emit("update:show", false);
    },
  },
};
</script>

<style scoped>
.Members {
  height: max-content;
  padding: 5vw;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: calc(var(--font-size) * 1.2);
  transform: translate(-50%, -50%);
}

.Member {
  position: relative;
  display: inline-block;
  height: max-content;
  padding: 1vw;
  cursor: pointer;
  outline: var(--border-dark);
  border-radius: var(--border-radius);
}

.Member:hover {
  outline: var(--border-dark);
}

.MemberImg {
  height: 5vw;
  display: flex;
  margin-right: 1vw;
  float: left;
  width: auto;
  border-radius: 50%;
}

.Member span {
  display: flex;
  margin-right: 1vw;
}
</style>
