import getters from "./getters.js";

export default {
  state() {
    let browser;
    let userAgent = navigator.userAgent;

    if (userAgent.indexOf("Firefox") > -1) {
      browser = "Mozilla";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      browser = "Samsung_Internet";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browser = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
      browser = "Internet_Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
      browser = "Microsoft_Edge_Legacy";
    } else if (userAgent.indexOf("Edg") > -1) {
      browser = "Microsoft_Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browser = "Safari";
    } else {
      browser = "unknown";
    }
    return {
      browser,
    };
  },
  getters,
};
