export default [
  { _id: "6200f6fd4f7b5f2557e49a4f", fullName: "Стоян Тинчев" },
  { _id: "6200f8294f7b5f2557e49bed", fullName: "Захари Момчилов" },
  { _id: "6200f9b64f7b5f2557e49f3d", fullName: "Кристиян Стоименов" },
  { _id: "6200f9ec4f7b5f2557e49f9d", fullName: "Виолета Кабаджова" },
  { _id: "6200fc649e09393546c247f2", fullName: "Мартин Вайер" },
  { _id: "6200f1f74f7b5f2557e49862", fullName: "Иван Петков" },
  { _id: "6200f8bb4f7b5f2557e49d09", fullName: "Александър Ангелов" },
  { _id: "6200f23e4f7b5f2557e4988c", fullName: "Александър Асенов" },
  { _id: "62011d404e1616bf6cb2e0f4", fullName: "Адриан Пенев" },
  { _id: "620104d2c13c431f628744b2", fullName: "Иван Генов" },
  { _id: "6200f62c4f7b5f2557e49a06", fullName: "Борислав Борисов" },
  { _id: "6200f77c4f7b5f2557e49af7", fullName: "Габриел Петров" },
  { _id: "6200f8e54f7b5f2557e49d87", fullName: "Велизар Цикловски" },
  { _id: "6201510e14391c71393a240d", fullName: "Никола Алексов" },
  { _id: "620157b91d06c5ac94f4b430", fullName: "Ясен Цветков" },
  { _id: "6200fd119e09393546c24898", fullName: "Никола Петров" },
  { _id: "6200fd199e09393546c248a5", fullName: "Тихомир Гърменлиев" },
  { _id: "6200fe049e09393546c24a04", fullName: "Искрен Русимов" },
  { _id: "6200fe789e09393546c24ac8", fullName: "Браян Монтичелли" },
  { _id: "6203c6ea842ecc5476670c94", fullName: "Иван Постолов" },
  { _id: "6200fca19e09393546c24822", fullName: "Йоан Иванов" },
  { _id: "6200ff7c9e09393546c24ca1", fullName: "Живко Нушев" },
  { _id: "620102f89e09393546c2512d", fullName: "Димитър Димитров" },
  { _id: "62018f81ef5b208903bcfd06", fullName: "Георги Фиданов" },
  { _id: "6204c166f01e20ff9b51efa9", fullName: "Радослав Филипов" },
  { _id: "6200fc0d9e09393546c2477e", fullName: "Калоян Дойчинов" },
  { _id: "6200f2b54f7b5f2557e498b8", fullName: "Мартин Божилов" },
  { _id: "6200f7614f7b5f2557e49acd", fullName: "Алеко Георгиев" },
  { _id: "6200fa9a4f7b5f2557e4a05f", fullName: "Александър Иванов" },
  { _id: "620b6b5548c1b761c43f01e8", fullName: "Кристиян Богданов" },
  { _id: "6200f9c14f7b5f2557e49f67", fullName: "Кристиан Кирчев" },
  { _id: "620103b79e09393546c253aa", fullName: "Ивайло Георгиев" },
  { _id: "620126764e1616bf6cb2e710", fullName: "Димо Йорданов" },
  { _id: "620a541a1266cd0c1d7e1cd4", fullName: "Даниел Димитров" },
  { _id: "6200f8e94f7b5f2557e49d9a", fullName: "Тодор Стоименов" },
  { _id: "6200f9024f7b5f2557e49dd2", fullName: "Стоян Бабанов" },
  { _id: "6200f8894f7b5f2557e49c85", fullName: "Иван Ламбев" },
  { _id: "6201107ec13c431f628750b4", fullName: "Борис Стоянов" },
  { _id: "6202ccd2fc0d049cd27902ae", fullName: "Антон Станков" },
  { _id: "6200f9444f7b5f2557e49e9b", fullName: "Габриел Мартинов" },
  { _id: "6200f88f4f7b5f2557e49c9a", fullName: "Кристиан Кирилов" },
  { _id: "6200f8d54f7b5f2557e49d5c", fullName: "Билян Костадинов" },
  { _id: "620103489e09393546c25233", fullName: "Николай Белперчинов" },
  { _id: "6201038e9e09393546c2530e", fullName: "Мартин Йосифов" },
  { _id: "620104cf9e09393546c256da", fullName: "Рая Литова" },
  { _id: "62010647c13c431f6287481e", fullName: "Андрей Тодоров" },
  { _id: "62010809c13c431f62874989", fullName: "Матей Вълов" },
  { _id: "62018d53ef5b208903bcfb7c", fullName: "Александър Асса" },
  { _id: "6206759ebbafd0c9a58a97a5", fullName: "Ирина Ахамад" },
  { _id: "62010e2ac13c431f62874e63", fullName: "Ивайла Панайотова" },
  { _id: "62011140c13c431f628751a2", fullName: "Яна Илиева" },
  { _id: "62011252a766fb2963fa3238", fullName: "Милица Кирякова" },
  { _id: "620110ccc13c431f62875105", fullName: "Андрей Ежков" },
  { _id: "6201132ca766fb2963fa3357", fullName: "Александър Велев" },
  { _id: "620112eba766fb2963fa32fe", fullName: "Станислав Иванов" },
  { _id: "62011008c13c431f62874ff6", fullName: "Елена Чернева" },
  { _id: "62015a641d06c5ac94f4b848", fullName: "Стефани Пенчева" },
  { _id: "62010feac13c431f62874fde", fullName: "Магдалена Никифорова" },
  { _id: "6202f3084ca507718193fe90", fullName: "Емилиана Петренко" },
  { _id: "620128e74e1616bf6cb2ea39", fullName: "Николай Лазаров" },
  { _id: "62010a45c13c431f62874b76", fullName: "Християн Радев" },
  { _id: "620178338f0775c3005a5708", fullName: "Йосиф Хамед" },
  { _id: "620173378f0775c3005a53b5", fullName: "Йоан Джелекарски" },
  { _id: "62012bb04e0611652f9c514a", fullName: "Стефан Касамаков" },
  { _id: "620170038f0775c3005a4edc", fullName: "Михаил Бошкилов" },
  { _id: "620199c652a41f218f3f9303", fullName: "Михаел Динков" },
  { _id: "62012a834e0611652f9c4f77", fullName: "Антон Христов" },
  { _id: "62012cd14e0611652f9c520c", fullName: "Михаил Георгиев" },
  { _id: "62012abf4e0611652f9c4fe2", fullName: "Стоян Иванов" },
  { _id: "6200fc369e09393546c247a0", fullName: "Матей Захариев" },
  { _id: "620149037cc0967467b9ad1e", fullName: "Десислава Стефанова" },
  { _id: "620147bf7cc0967467b9ac85", fullName: "Александра Стойчева" },
  { _id: "6201833eb0bff19fabacee6a", fullName: "Симеон Йорданов" },
  { _id: "6200f9bd4f7b5f2557e49f53", fullName: "Иван Георгиев" },
  { _id: "620102f89e09393546c25139", fullName: "Мартин Наков" },
  { _id: "62010e29c13c431f62874e57", fullName: "Димитър Николов" },
  { _id: "62010fb4c13c431f62874f81", fullName: "Венелин Новаков" },
  { _id: "62010e4bc13c431f62874e80", fullName: "Емил Дойчинов" },
  { _id: "620102de9e09393546c25103", fullName: "Алекс Огнянов" },
  { _id: "620157c11d06c5ac94f4b443", fullName: "Иван Матев" },
  { _id: "620157c61d06c5ac94f4b45b", fullName: "Виктор Велков" },
  { _id: "62015be71d06c5ac94f4bafd", fullName: "Калоян Атанасов" },
  { _id: "620124944e1616bf6cb2e54c", fullName: "Крис Петков" },
  { _id: "62015cba1d06c5ac94f4bc7c", fullName: "Боян Вачков" },
  { _id: "62014a987cc0967467b9ae41", fullName: "Светослав Илиев" },
  { _id: "62015a001d06c5ac94f4b773", fullName: "Никола Георгиев" },
  { _id: "62015bdd1d06c5ac94f4bae8", fullName: "Радослав Димитров" },
  { _id: "6203c4d1842ecc5476670ae7", fullName: "Николай Вълканов" },
  { _id: "620158711d06c5ac94f4b556", fullName: "Евгения Голубева" },
  { _id: "62014d777cc0967467b9af19", fullName: "Калоян Миладинов" },
  { _id: "62015a021d06c5ac94f4b781", fullName: "Денис Мирчев" },
  { _id: "620161751d06c5ac94f4c594", fullName: "Божидар Павлов" },
  { _id: "620161c51d06c5ac94f4c6e7", fullName: "Никола Сачков" },
  { _id: "62015a931d06c5ac94f4b8a0", fullName: "Иван Иванов" },
  { _id: "62015c4b1d06c5ac94f4bba2", fullName: "Любомир Станев" },
  { _id: "6201591d1d06c5ac94f4b682", fullName: "Викторио Миланов" },
  { _id: "620127fd4e1616bf6cb2e905", fullName: "Веселин Владимиров" },
  { _id: "620159151d06c5ac94f4b665", fullName: "Николай Йорданов" },
  { _id: "62016c2a8f0775c3005a4a68", fullName: "Филостратос Титопулос" },
  { _id: "62016cce8f0775c3005a4b3a", fullName: "Евгени Попов" },
  { _id: "62016cef8f0775c3005a4b5b", fullName: "Александра Лазарова" },
  { _id: "62015c551d06c5ac94f4bbbc", fullName: "Огнян Разсадов" },
  { _id: "62016e758f0775c3005a4c4c", fullName: "Михаил Петров" },
  { _id: "62016dc28f0775c3005a4be7", fullName: "Стенли Христов" },
  { _id: "62017a18837e403248a36edf", fullName: "Валентин Маринов" },
  { _id: "6201858aef5b208903bcf3d0", fullName: "Михаил Василев" },
  { _id: "62066ae5bbafd0c9a58a94ce", fullName: "Христо Ангелов" },
  { _id: "62012cae4e0611652f9c51f9", fullName: "Васил Колев" },
  { _id: "62012d564e0611652f9c52b3", fullName: "Иван Иванов" },
  { _id: "6201ae004b0006c2bc408699", fullName: "Теодор Тотев" },
  { _id: "62020becaae78464a690ebcf", fullName: "Виктория Димитрова" },
  { _id: "6202bfcdfc0d049cd278e470", fullName: "Яна Балджиева" },
  { _id: "6202156faae78464a690fd37", fullName: "Димитър Цветков" },
  { _id: "62021675aae78464a690ff3d", fullName: "Никола Белев" },
  { _id: "6202153aaae78464a690fcbc", fullName: "Деян Атанасов" },
  { _id: "620215e0aae78464a690fe4e", fullName: "Дейвид Гигов" },
  { _id: "620101459e09393546c24f8e", fullName: "Калоян Анастасов" },
  { _id: "620251f2aae78464a6916882", fullName: "Таня Колева" },
  { _id: "62021d0caae78464a6910c7a", fullName: "Виктор Златев" },
  { _id: "6203cdd7842ecc547667123c", fullName: "Васил Велев" },
  { _id: "6203d0ee842ecc54766715e1", fullName: "Константин Златев" },
  { _id: "62014feb14391c71393a2264", fullName: "Дарий Топузов" },
  { _id: "62021e14aae78464a6910e41", fullName: "Дамян Мяшков" },
  { _id: "620358437587f76866c16168", fullName: "Антонио Готев" },
  { _id: "620a262f1266cd0c1d7e1727", fullName: "Кристиян Андреев" },
  { _id: "62039b417587f76866c18c11", fullName: "Стефан Крусашки" },
  { _id: "62015a201d06c5ac94f4b7c9", fullName: "Злати Златев" },
  { _id: "6201126fa766fb2963fa3271", fullName: "Никола Кръстанов" },
  { _id: "62023ae4aae78464a6913cc7", fullName: "Борис Антов" },
  { _id: "62015e861d06c5ac94f4c02f", fullName: "Даниел Икономов" },
  { _id: "6201120fa766fb2963fa31d7", fullName: "Александър Мечкаров" },
  { _id: "6202309aaae78464a6912ba1", fullName: "Ясен Ефремов" },
  { _id: "62025199aae78464a69167cc", fullName: "Ивайло Генчев" },
  { _id: "6202e2ff4ca507718193f3f4", fullName: "Явор Пачеджиев" },
  { _id: "6206c80460eb003085caad0e", fullName: "Симеон Христов" },
  { _id: "62010902c13c431f62874a4b", fullName: "Александър Христов" },
  { _id: "620254f3aae78464a6916f0f", fullName: "Любомир Начев" },
  { _id: "620266beaae78464a6918fc2", fullName: "Адриан Стоев" },
  { _id: "62029f0aaa76245d0028df87", fullName: "Ясен Спасов" },
  { _id: "62012e274e0611652f9c5375", fullName: "Иво Иванов" },
  { _id: "62021706aae78464a69100a0", fullName: "Борис Кисьов" },
  { _id: "62011f8e4e1616bf6cb2e295", fullName: "Александър Шестаков" },
  { _id: "620119f24e1616bf6cb2deaa", fullName: "Цветелина Петкова" },
  { _id: "62026d5faae78464a6919baa", fullName: "Павел Шаламанов" },
  { _id: "62027d27aa76245d0028a0bf", fullName: "Преслав Атанасов" },
  { _id: "62026e18aae78464a6919ce9", fullName: "Алек Сивков" },
  { _id: "62029e95aa76245d0028deb0", fullName: "Константин Щерев" },
  { _id: "6200f2df4f7b5f2557e498d7", fullName: "Крум Стефанов" },
  { _id: "6202a18caa76245d0028e3e2", fullName: "Никола Керезов" },
  { _id: "62011544a766fb2963fa3560", fullName: "Калоян Сотиров" },
  { _id: "6202baf1fc0d049cd278d861", fullName: "Пламена Георгиева" },
  { _id: "6202b9fafc0d049cd278d604", fullName: "Мартин Тарлев" },
  { _id: "6202ba60fc0d049cd278d6dd", fullName: "Емилия Чукалева" },
  { _id: "62023456aae78464a69131b8", fullName: "Ивайло Каньов" },
  { _id: "6204015d842ecc54766739ec", fullName: "Виктор Димитров" },
  { _id: "620117ab4e1616bf6cb2dc75", fullName: "Данаил Стоилов" },
  { _id: "62011387a766fb2963fa33f9", fullName: "Александра Александрова" },
  { _id: "6201158ea766fb2963fa35a9", fullName: "Ивона Вълчева" },
  { _id: "6201526014391c71393a2576", fullName: "Християн Тодоров" },
  { _id: "62011601a766fb2963fa3693", fullName: "Андрей Иванов" },
  { _id: "62012a744e0611652f9c4f4b", fullName: "Божидар Попниколов" },
  { _id: "62042593f01e20ff9b518fd8", fullName: "Никола Милков" },
  { _id: "62050b7c319b85eb210185b4", fullName: "Велизар Йорданов" },
  { _id: "62025e71aae78464a6917f9e", fullName: "Боримир Ганчев" },
  { _id: "62036d0e7587f76866c16c0c", fullName: "Радослав Цветанов" },
  { _id: "6203e98a842ecc547667256b", fullName: "Даниел Блъсков" },
  { _id: "62041727842ecc5476674bff", fullName: "Ивайло Русинчовски" },
  { _id: "62053d3b319b85eb210193c8", fullName: "Александър Стоянов" },
  { _id: "6203b66e842ecc547666ff82", fullName: "Александър Георгиев" },
  { _id: "6200f32e4f7b5f2557e49915", fullName: "Калоян Чакъров" },
  { _id: "6203bf14842ecc54766705d4", fullName: "Георги Станачков" },
  { _id: "6203c092842ecc547667073b", fullName: "Георги Янакиев" },
  { _id: "6203c177842ecc54766707ee", fullName: "Владислав Павлов" },
  { _id: "620147567cc0967467b9ac06", fullName: "Мартин Дойчинов" },
  { _id: "6203bf35842ecc54766705f1", fullName: "Александър Малинов" },
  { _id: "6203f779842ecc547667300d", fullName: "Антон Николов" },
  { _id: "620160881d06c5ac94f4c48a", fullName: "Валентин Асенов" },
  { _id: "6200ff0c9e09393546c24bfe", fullName: "Емил Момчев" },
  { _id: "62040653842ecc5476673dd5", fullName: "Мартин Андонов" },
  { _id: "620402d3842ecc5476673af1", fullName: "Николай Иванов" },
  { _id: "620406ef842ecc5476673ec5", fullName: "Сашо Александров" },
  { _id: "6205188d319b85eb210186ef", fullName: "Християна Ангелова" },
  { _id: "62051a36319b85eb2101876c", fullName: "Михаел Иванов" },
  { _id: "620519a5319b85eb2101875d", fullName: "Константин Хаджийски" },
  { _id: "62056fb2319b85eb2101a47f", fullName: "Чавдара Белева" },
  { _id: "620531b6319b85eb21018bf2", fullName: "Богдан Яков" },
  { _id: "620532a7319b85eb21018c60", fullName: "Георги Стоянов" },
  { _id: "620563bc319b85eb2101a04d", fullName: "Илина Мутафчиева" },
  { _id: "620214e5aae78464a690fc3a", fullName: "Атанас Ружинов" },
  { _id: "62056f17319b85eb2101a42d", fullName: "Анна-Мариа Димитрова" },
  { _id: "620532b3319b85eb21018c8a", fullName: "Йосиф Салиел" },
  { _id: "62053564319b85eb21018e30", fullName: "Борислав Миланов" },
  { _id: "62053714319b85eb21018f68", fullName: "Кристина Иванова" },
  { _id: "620533b6319b85eb21018d2d", fullName: "Йоанна Стаменова" },
  { _id: "620532af319b85eb21018c78", fullName: "Томислав Иванов" },
  { _id: "6205316e319b85eb21018bc8", fullName: "Симеон Симеонов" },
  { _id: "62053b88319b85eb210192fb", fullName: "Георги Динков" },
  { _id: "62053078319b85eb21018a80", fullName: "Иван Сираков" },
  { _id: "6206a7a6bbafd0c9a58a9ad8", fullName: "Павел Симеонов" },
  { _id: "6206d5aa1266cd0c1d7dd51a", fullName: "Георги Стоянов" },
  { _id: "62021fc4aae78464a69111a1", fullName: "Димитър Желев" },
  { _id: "620784541266cd0c1d7ddcb5", fullName: "Александър Христов" },
  { _id: "6207ac891266cd0c1d7de1dd", fullName: "Александър Тодоров" },
  { _id: "620817401266cd0c1d7df4db", fullName: "Петър Петров" },
  { _id: "620abaaf1266cd0c1d7e2eb9", fullName: "Симеон Симеонов" },
  { _id: "6207f1d21266cd0c1d7de989", fullName: "Калоян Венков" },
  { _id: "6207f5261266cd0c1d7dea4e", fullName: "Стилиян Мандалиев" },
  { _id: "6207f5241266cd0c1d7dea41", fullName: "Мартин Раднев" },
  { _id: "62081fb51266cd0c1d7df668", fullName: "Любомир Милев" },
  { _id: "620bf28e36775210844428b4", fullName: "Росен Маринов" },
  { _id: "620834181266cd0c1d7df96f", fullName: "Димитър Грозев" },
  { _id: "620a61d11266cd0c1d7e1f55", fullName: "Силвия Антова" },
  { _id: "620a60651266cd0c1d7e1eec", fullName: "Алекса Рашова" },
  { _id: "620a61631266cd0c1d7e1f0a", fullName: "Далия Дацева" },
  { _id: "620954941266cd0c1d7e081e", fullName: "Владимир Барев" },
  { _id: "620aab081266cd0c1d7e29a1", fullName: "Алекс Гечовски" },
  { _id: "620ab4071266cd0c1d7e2c3d", fullName: "Иваело Кръстев" },
  { _id: "620aab4f1266cd0c1d7e29ca", fullName: "Данаил Божков" },
  { _id: "620bc3ad3677521084442481", fullName: "Валентин Найденов" },
  { _id: "620bc82c367752108444252d", fullName: "Лъчезар Велинов" },
  { _id: "620a52361266cd0c1d7e1bc7", fullName: "Антоан Аргиров" },
  { _id: "620ac2451266cd0c1d7e3069", fullName: "Екатерина Димитрова" },
  { _id: "620ab8f51266cd0c1d7e2e82", fullName: "Явор Симеонов" },
  { _id: "620a52cf1266cd0c1d7e1c56", fullName: "ВИКТОР ПЕНЕВ" },
];
