export default [
  {
    title: `<span style="color:#009d60">Hack TUES </span> <span style="color:#105231">GG</span>`,
    description: `Hack TUES GG -“Develop for the environment”. Изцяло онлайн формат. 185 участници.`,
    teams: [
      {
        teamName: "Holdinga",
        teamPic: "/content/site-images/archive-teams/htgg/1.jpg",
        members: "Виктор Горчилов, Валентин Спасов, Самуил Георгиев, Антон Янчев",
        projectDescription:
          "Recommendation engine за маршрути за велосипедисти на база на фактори като замърсяване на въздуха, оценяване от други и дистанция до крайната точка. Изработва се с Graph DB, NestJS и React.",
      },
      {
        teamName: "Мечо пух",
        teamPic: "/content/site-images/archive-teams/htgg/2.jpg",
        members: "Александър Найденов, Стефан Босев, Стоян Тинчев, Кристиян Стоименов",
        projectDescription:
          "Verda is a chatbot that helps you understand and react appropiately to the the problems which surround us. How can Verda help us? The amount of precious resources that our planet provides us are declining daily - and all of that is our own fault. Deforestation, biodiversity loss and plastic pollution are only a small portion of the global disaster. Living our lives without complying to the nature's laws is not doing us any good and we have to find a way to change this. Thus, our idea - Verda. A chatbot, which gives you information about matured problems and alternative solutions to them",
      },
      {
        teamName: "Dynamics",
        members: "Ангел Стоянов, Лилия Любенова, Евгени Атанасов, Денис Захариев",
        projectDescription:
          "GRobot - робот, който се придвижва и на базата на изкуствен интелект, разпознава отпадъци и ги събира. GRobot разполага с камера, която е свързана към микрокомпютър, който стриймва видео към сървър. YOLO v4 определя вида на боклука от стрийма, след което роботизирана щипка го взима и го поставя в тялото на робота - контейнер.",
      },
    ],
    stats: {
      participants: 193,
      teamCount: 43,
      awardedTeams: 30,
    },
    body: `За първи път училищния хакатон се проведе изцяло онлайн през 2021 г. със седмото си издание - Hack TUES GG. Както винаги 43-те отбора имаха малко над 48 ч. да направят своите проекти по темата на хакатона - “Develop for the environment”, свързана със зелените технологии и опазването на околната среда. Подтемите бяха Code red - свързана с борбата с глобалното затопляне и вредните емисии, Deep in the blue - свързана с опазването на водите от замърсяване, Browny soil - свързана с опазване на почвите от замърсяване, Pallete Nature - цели популяризирането на рециклирането, втората употреба и предпазването на биоразнообразието.`,
    name: "hacktues-gg",
  },
  {
    title: `<span style="color: white;">Hack </span><span style="color: rgb(253, 173, 32);">TUES </span><span style="color: white;">6</span>`,
    description: `Hack TUES 6 - "Smart City"". Хибриден формат. 125 участници`,
    teams: [
      {
        teamName: "Fanton",
        teamPic: "/content/site-images/archive-teams/ht6/1.jpg",
        members: "Ангел Пенчев, Симеон Георгиев, Боян Иванов, Богдан Миронов, Мирослав Мирчев",
        projectDescription: "DRUN: Автоматизирана система за доставка на пратки с дронове.",
      },
      {
        teamName: "789",
        teamPic: "/content/site-images/archive-teams/ht6/2.jpg",
        members:
          "Илиана Генова, Венелин Атанасов, Боряна Стефанова, Стефан Антонов, Иваело Кръстев",
        projectDescription:
          "Пейо: преработено радио на около 60 години, така че да използва нови технологии",
      },
      {
        teamName: "Нишки",
        teamPic: "/content/site-images/archive-teams/ht6/3.jpg",
        members: "Анета Цветкова, Калин Дойчев, Костадин Костадинов, Евгени Димов",
        projectDescription:
          "NotInfo: браузър разширение за Chrome, коeто следи дали съдържание, което човек чете не е пропаганда.",
      },
    ],
    stats: {
      participants: 125,
      teamCount: 29,
      awardedTeams: 15,
    },
    body: `Отборите работеха по проектите си дистанционно, а финалът на състезанието и церемонията по награждаването се състояха в София Тех Парк при спазване на противоепидемичните мерки от 2020 г. 30 ИТ професионалисти бяха ментори и членове на журито. 
За поредна година водещи технологични компании подкрепиха провеждането на ученическото ИТ състезание Hack TUES 6. Това бяха Experian, SAP, TelebidPro, VMware Bulgaria, Astea Solutions, Kaufland IT Hub, Progress, CPD Ltd., Bosch.IO, Creative Assembly Sofia, DevriX, Dopamine, Ubisoft Sofia. Партньори на събитието тази година бяха Асоциация за развитие на София, Bulged, Domino’s, COMET ELECTRONICS, Cloud Balkan, Galactic Banitsa, init Lab, Компютърна академия IT Step, Ora, Pure Water, Smartcom - Bulgaria AD, Sofia Tech Park и Telelink Business Services. Медиен партньор на събитието беше DevStyleR. `,
    name: "hacktues-6",
  },
  {
    title: `<span style="color: white;">Hack </span><span style="color: rgb(153, 208, 43);">TUES</span><span style="color: rgb(153, 208, 43);"><sup>^365</sup></span>`,
    description: `Hack TUES 365 - "Полза на ежедневната работа на бизнеса и живота на хората". Изцяло присъствен формат. Финалния ден в София Тех парк. 198 участници.`,
    teams: [
      {
        teamName: "Elsyser",
        teamPic: "/content/site-images/archive-teams/ht365/1.jpg",
        members: "Виктор Велев, Петър Герасимов, Теодор Станишев",
        projectDescription:
          "VoiceCV: Вградено устройство (в шапка или очила) с камера и изкуствен интелект в помощ на незрящите хора - играе ролята на личен асистент, който информира с глас за околните обекти чрез снимки в близка и далечна перспектива",
      },
      {
        teamName: "Какводой",
        teamPic: "/content/site-images/archive-teams/ht365/2.jpg",
        members: "Владислав Георгиев, Мартин Дацев, Антонио Милев, Телерик Арсов",
        projectDescription:
          "GeoSolver: приложение за решаване на геометрични задачи, което сканира условието и генерира електронен чертеж и решение на задачата",
      },
      {
        teamName: "Babba4e",
        teamPic: "/content/site-images/archive-teams/ht365/3.jpg",
        members: "Мартин Йорданов, Самуил Георгиев, Антон Янчев, Огнян Барух, Георги Любенов",
        projectDescription:
          "Product Chain: система за проверка на автентичността на продукти чрез сканиране на уникален QR код на всеки продукт, който дава информация за пътя на продукта, използваща блокчейн технология, за да предпази потребителите от фалшиви стоки на пазара",
      },
    ],
    stats: {
      participants: 198,
      teamCount: 43,
      awardedTeams: 10,
    },
    body: `Състезанието се проведе от 15 до 17 март 2019 г. в ТУЕС и София Тех Парк. Над 60 ИТ професионалисти от различни компании, много от които възпитаници на училището, бяха ментори и членове на журито. За поредна година водещи технологични компании подкрепят провеждането на ученическото ИТ състезание Hack TUES. Това бяха SAP, SoftServe, TelebidPro, VMware, Droxic, Progress, ScaleFocus, Astea Solutions, DevriX, Dopamine, Nemetschek Bulgaria. Технологични партньори на събитието тази година бяха София Тех Парк, Комет Електроникс, Смартком - България, Булгед, Cloud Balkan, Data Science Society и init Lab. Кетеринг партньори на хакатона бяха CoKitchen, Aristeon.bg, Delishu, Domino’s, Пекарчето на татко, Pura Vida Organic Farm, Pure Water, Работилничка за сладки моменти и сладкар Ирина Иванова. Събитието беше подкрепено и от Ротари Клуб София, Капитал, DevStyler и YoungArtist. 
`,
    name: "hacktues-365",
  },
  {
    title: `<span style="color: cyan;">Hack <sup>30x</sup>TUES</span>`,
    description: `Hack 30хTUES - "Бизнес, училище, университет". Изцяло присъствен формат. 206 участници. `,
    teams: [
      {
        teamName: "COCOBE",
        teamPic: "/content/site-images/archive-teams/ht30x/1.jpg",
        members: "Телерик Арсов, Ивона Мирчева, Владислав Георгиев, Мартин Дацев, Петър Милев",
        projectDescription:
          "Приложение за резюмиране на текст, използващо невронни мрежи и машинно самообучение",
      },
      {
        teamName: "Old and Bald",
        teamPic: "/content/site-images/archive-teams/ht30x/2.jpg",
        members: "Георги Любенов, Виктор Велев, Антон Янчев, Самуил Георгиев",
        projectDescription:
          "Приложение, базирано на машинно самообучение, за чатбот на историческа тема, което предлага резюме на текстове по дадена тема, спрямо интересите на ползвателите",
      },
      {
        teamName: "IDK",
        teamPic: "/content/site-images/archive-teams/ht30x/3.jpg",
        members: "Михаил Киров, Петър Николов, Денис Бързанов, Тони Пеловски, Стефан Стефанов",
        projectDescription: "Игрална конзола",
      },
    ],
    stats: {
      participants: 206,
      teamCount: 45,
      awardedTeams: 8,
    },
    body: `В четвъртото издание на тридневния ученически хакатон Hack 30xTUES участниците създадоха софтуерни и хардуерни проекти по теми, свързани с 30-годишния юбилей на училището: - Бизнес, училище, университет - посветена на 3-те необходими елемента за успешно ИТ образование; Digital past – за възраждане на историята и интереса към миналото с помощта на технологиите; 30xТУЕС – проекти за честване на юбилея на училището. Спонсори на юбилейното издание Hack 30xTUES бяха развойният център на SAP в София, SoftServe, TelebidPro, VMware, Dopamine, ScaleFocus, Софтуерен Университет, Accedia, Astea Solutions, Немечек България и Uber Engineering в София. Партньори в организацията на събитието бяха София Тех Парк, Смартком - България АД, init Lab, Cloud Balkan и Пирин Спринг. В хакатона участваха почти половината настоящите ученици в ТУЕС. В работата им помагаха над 40 ИТ професионалисти като ментори. Събитието беше организирано от екип от около 30 ученици, доброволци и представители на Асоциацията на завършилите ТУЕС.`,
    name: "hacktues-30x",
  },
  {
    title: `<span style="color: rgb(255, 255, 255);">Hack </span><span style="color: rgb(9, 192, 222);">TUES </span><span style="color: rgb(178, 0, 110);">3</span>`,
    description: `Hack TUES 3 - "Art&&Creativity". 136 участници.`,
    teams: [
      {
        teamName: "Зор",
        teamPic: "/content/site-images/archive-teams/ht3/1.jpg",
        members:
          "Лазар Френкев, Александър Вербовский, Андрей Стрински, Християн Генчев, Алекс Стефанов",
        projectDescription:
          "2D Platformer с puzzle game елементи (забавна игра, създадена за Android)",
      },
      {
        teamName: "To6o",
        teamPic: "/content/site-images/archive-teams/ht3/2.jpg",
        members: " Телерик Арсов, Стефан Бабуков, Николай Милчев, Мартин Дацев",
        projectDescription: "Уебсайт за продажба и обмен на продукти от рециклирани материали",
      },
      {
        teamName: "Top8",
        teamPic: "/content/site-images/archive-teams/ht3/3.jpg",
        members:
          "Георги Корчаков, Кристиян Йочев, Йордан Златанов, Симеон Георгиев, Александър Стоичков",
        projectDescription:
          "Image Marker: уеб приложение за създаване и редактиране на изображения, което работи на всякакви платформи",
      },
    ],
    stats: {
      participants: 136,
      teamCount: 31,
      awardedTeams: 8,
    },
    body: `В продължение на три дни учениците работиха върху създаването на демонстрационни проекти на тема "Art && Creativity". Партньори на Hack TUES 3 бяха Dopamine, SAP, TelebidPro, VMware, Nemetschek Bulgaria, Astea Solutions, CloudBalkan, Musala Soft, Chaos Group и Smartcom Bulgaria.`,
    name: "hacktues-3",
  },
  {
    title: `<span>Hack </span><span style="color: rgb(68, 101, 118);">TUES </span><span>2</span>`,
    description: `Hack TUES 2 - "Подпомагaне опазването на околната среда и социални каузи." 77 участници.`,
    teams: [
      {
        teamName: "ASDF Unicorns",
        teamPic: null,
        members:
          "Жанет Хаджиангелова, Мария Стоянова, Георги Данчев, Николай Миланов, Димитър Кацаров",
        projectDescription: "Образователна игра за опазване на околната среда",
      },
      {
        teamName: "Walking Chaos",
        teamPic: null,
        members: " Николета Далакчиева, Владимир Младенов, Марина Тихова, Лъчезар Младенов",
        projectDescription: "Уебсайт за продажба и обмен на продукти от рециклирани материали",
      },
      {
        teamName: "The Tanks",
        teamPic: null,
        members:
          "Андреа Георгиева, Николай Рангелов, Александър Велинов, Калин Карев, Светлин Йорданов, Теодор Добрев",
        projectDescription: "Приложение за лекари и медицински работници",
      },
    ],
    stats: {
      participants: 100,
      teamCount: 18,
      awardedTeams: 7,
    },
    body: `Hack TUES 2 се проведе от 9 до 11 октомври 2015 г. с цел разработване на проекти, подпомагащи опазването на околната среда и социални каузи. Hack TUES 2 е подкрепен от Astea Solutions, ChaosGroup, HackBulgaria, Microsoft, Obecto, SAP, MusalaSoft, Delta.bg и Telerik, Progress Company. Медиен партньор на хакатона е Socialevo.net. Събитието е част от Европейската седмица на програмирането (EU Code Week), която цели да популяризира програмирането сред широк кръг млади хора.`,
    name: "hacktues-2",
  },
  {
    title: `<span>Hack </span><span style="color: rgb(68, 101, 118);">TUES</span>`,
    description: `Hack TUES 1 - "Автоматизиране и подобряване на учебния процес в ТУЕС". 84 участници.`,
    teams: [
      {
        teamName: "Go6o",
        teamPic: "/content/site-images/archive-teams/ht1/1.jpg",
        members: "Телерик Арсов, Антонио Милев, Мартин Дацев",
        projectName: "",
        projectDescription:
          "Система за автоматизирана оценка на тестове, на основата на технология за разпознаване на изображения",
      },
      {
        teamName: "R&T",
        teamPic: "/content/site-images/archive-teams/ht1/2.jpg",
        members: "Антонио Миндов, Дария Никитова, Кристиана Иванова, Петър Петров, Ясен Алексиев",
        projectName: "",
        projectDescription: "Визуализация на код с блок-схеми",
      },
      {
        teamName: "Coding Masters",
        teamPic: "/content/site-images/archive-teams/ht1/3.jpg",
        members:
          "Габриел Гарвалов, Дейвид-Николас Петров, Илиян Кордев, Филип Пепегов, Кристиан Томов",
        projectName: "",
        projectDescription: "Система за обмен на информация и знания между учениците",
      },
    ],
    stats: {
      participants: 84,
      teamCount: 18,
      awardedTeams: 6,
    },
    body: "Темата на първия ученически хакатон Hack TUES беше Автоматизация и подобряване на учебния процес. Инициативата беше подкрепена от SoftUni, HackBulgaria, MusalaSoft, ChaosGroup, Astea, Nemetschek, StandByte и Subway. Идеята за състезанието идва от ученици от 10 клас в ТУЕС и среща подкрепата на ръководството на училището.",
    name: "hacktues-1",
  },
];
