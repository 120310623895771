import _ from "lodash";

const isChangedArray = (array1, array2) => {
  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i])) {
      return true;
    }
  }
  for (let i = 0; i < array2.length; i++) {
    if (!array1.includes(array2[i])) {
      return true;
    }
  }
  return false;
};

export default (initialState, changedState) => {
  let changedProps = {};
  Object.keys(changedState).forEach((changedStateProp) => {
    if (
      !_.isEqual(initialState[changedStateProp], changedState[changedStateProp]) &&
      (!_.isUndefined(initialState[changedStateProp]) || initialState[changedStateProp] === "")
    ) {
      if (
        changedStateProp === "allergies" ||
        (changedStateProp === "technologies" &&
          isChangedArray([...initialState[changedStateProp]], [...changedState[changedStateProp]]))
      ) {
        changedProps = {
          ...changedProps,
          [changedStateProp]: changedState[changedStateProp],
        };
      } else if (changedStateProp !== "allergies" && changedStateProp !== "technologies")
        changedProps = {
          ...changedProps,
          [changedStateProp]: changedState[changedStateProp],
        };
    }
  });
  return changedProps;
};
