import calculateAsciiValueOfString from "./calculateAsciiValueOfString";

export default (string) => {
  const seed = Math.sin(calculateAsciiValueOfString(string)) * 10000;
  const rgbaVal9Dig = `${Math.floor((seed - Math.floor(seed)) * 1000000000)}`;
  const rgbaValSeg = rgbaVal9Dig.match(/.{1,3}/g);
  for (let i = 0; i < rgbaValSeg.length; i++) {
    rgbaValSeg[i] %= 255;
  }
  return rgbaValSeg.toString();
};
