<template>
  <div
    :class="`Team ${this.$store.getters.isMobile ? 'Mobile' : ''}`"
    :id="team._id"
    @click="openTeamPage"
  >
    <span class="TeamName">{{ team.teamName }}</span>
    <div :class="`Approved ${team.approved ? 'Green' : 'Red'}`" />
    <span class="ProjectName">{{ team.projectName || "Няма проект" }}</span
    ><br />
    <span class="AcceptsNewMembers">{{
      team.acceptsNewMembers ? "Отворен отбор" : "Затворен отбор"
    }}</span
    ><br />
    <span>Членове ({{ team?.members?.length }})</span><br />
    <div class="Technologies" v-if="team.technologies?.length">
      <Prop
        :isColored="true"
        :isEdit="false"
        v-if="team.technologies[0]?.length"
        :arrayProp="team.technologies[0]"
        :spanStyle="`font-size: var(--font-size-here)`"
        type="technologies"
      />
      <Prop
        v-if="team.technologies[1]?.length"
        :isColored="true"
        :isEdit="false"
        :spanStyle="`font-size: var(--font-size-here)`"
        :arrayProp="team.technologies[1]"
        type="technologies"
      />
      <Prop
        :isColored="false"
        :isEdit="false"
        v-if="team.technologies?.length > 2"
        :spanStyle="`font-size: var(--font-size-here)`"
        :arrayProp="`+${team.technologies.length - 2}`"
        type="technologies"
      />
    </div>
  </div>
</template>

<script>
import Prop from "./ui/Prop.vue";

export default {
  name: "Team",
  props: {
    team: {
      type: Object,
      default: null,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
    isViewerCaptain: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Prop,
  },
  methods: {
    openTeamPage() {
      if (!this.isPage) {
        this.$router.push({ path: "team", query: { teamId: this.team._id } });
      }
    },
  },
};
</script>

<style scoped>
.Team {
  --font-size-here: calc(var(--font-size) * 1.7);
  position: relative;
  display: inline-block;
  width: 30%;
  margin-right: calc(1vh + 1vw);
  margin-bottom: calc(1vh + 1vw);
  border-radius: var(--border-radius);
  background-color: var(--blur-background-color);
  color: white;
  backdrop-filter: blur(var(--blur));
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  vertical-align: top;
  min-height: 15vw;
}

.Team span {
  position: relative !important;
  margin-left: 5%;
  font-size: var(--font-size-here);
}

.Mobile {
  width: 95%;
  --font-size-here: calc(var(--font-size) * 4);
}

.TeamName {
  padding: calc(0.5vw + 0.5vh);
  font-size: var(--font-size-here) !important;
  display: block;
  width: 100%;
  text-align: center;
  background-color: #1b063e;
  word-break: break-word;
  margin: 0 !important;
}

.ProjectName {
  font-weight: 900;
}

.Approved {
  width: 100%;
  height: 0.2vw;
  margin-bottom: 0.5vw;
}

.Green {
  background-color: green;
}

.Red {
  background-color: red;
}

.Technologies {
  padding-top: 0.7vw;
  margin-left: 5%;
}
</style>
