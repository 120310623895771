<template>
  <div @click="this.$emit('btnClicked')">
    <base-svg-child
      :style1="wrapperStyle"
      height1="446"
      width1="447"
      path1="M0.5 223C14.1 45 154.5 0.5 223 0.5C402.6 9.3 446.167 152.5 445.5 223C439.1 401 294.5 445.5 223 445.5C46.6 439.1 1.16667 294.5 0.5 223Z"
      :height2="height"
      :width2="width"
      stroke="rgba(255, 255, 255, 1)"
      :strokeWidth="strokeWidth"
      :fill="fill"
      :path2="path"
      :size="size"
      :background="background"
    />
  </div>
</template>

<script>
export default {
  name: "BaseSvgButton",
  props: {
    wrapperStyle: { required: false },
    height: {
      required: true,
      type: String,
    },
    width: {
      required: true,
      type: String,
    },
    path: {
      required: true,
      type: String,
    },
    strokeWidth: {
      type: String,
      default: "3",
    },
    fill: String,
    size: String,
    background: String,
  },
};
</script>
