<template>
  <div>
    <div :class="`HomePage ${this.$store.getters.isMozilla ? 'isMozilla' : 'Alo'}`">
      <div class="HackTUES8">
        <span>{{ "\ Hack TUES\ Infinity \ " }}</span>
      </div>
      <HomePageTimer />
      <div class="Sponsors" style="margin-top: 20vh">
        <span style="margin-top: 1vh">Alpha спонсори</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="alphaSponsors" />
        </div>
      </div>
      <div class="Sponsors">
        <span>Beta спонсори</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="betaSponsors" />
        </div>
      </div>
      <div class="Sponsors">
        <span>Gamma спонсори</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="gammaSponsors" />
        </div>
      </div>
      <div class="Sponsors" style="margin-bottom: 10vh">
        <span>Партньори</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="partners" />
        </div>
      </div>
      <div class="Sponsors" style="margin-bottom: 10vh">
        <span>Медийни партньори</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="mediaPartners" />
        </div>
      </div>
      <div class="Sponsors" style="margin-bottom: 10vh">
        <span>Хостинг и сървър провайдъри</span>
        <div class="SponsorsSvgs">
          <SponsorsSvg sponsorType="cloudPartners" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SponsorsSvg from "../components/SponsorsSvg.vue";
import HomePageTimer from "../components/HomePageTimer.vue";

export default {
  name: "HomePage",
  components: {
    SponsorsSvg,
    HomePageTimer,
  },
};
</script>

<style scoped>
.HomePage {
  position: relative;
  --elem-height: calc(13vh + 2vw);
  padding-top: 10vh;
}
.HackTUES8 {
  margin-left: auto;
  margin-right: auto;
  border: var(--medium-border);
  border-radius: calc(var(--border-radius) + 1vh);
  color: white;
  background-color: var(--blur-background-color);
  backdrop-filter: blur(var(--blur));
  width: min-content;
  --pading: calc(2% + 3vh);
  padding-left: var(--pading);
  padding-right: var(--pading);
  height: var(--elem-height);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  max-width: 80vw;
}

.isMozilla .Sponsors {
  background-color: rgb(141, 121, 173);
}

.HackTUES8 span {
  font-family: "LLPixel", sans-serif !important;
  font-size: calc(3vw + 3vh);
  width: max-content;
  display: flex;
  justify-content: center;
  text-align: center;
  max-height: 100%;
  height: min-content;
  overflow: hidden;
  position: relative;
  padding-bottom: 0.3vw;
}

.Sponsors {
  position: relative;
  border: var(--thick-border);
  border-radius: calc(var(--border-radius) * 4);

  --width: clamp(50vw, calc(65vw + 25vh), 90vw);
  width: var(--width);
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(var(--blur));
  margin-top: 10vh;
  text-align: center;
  padding: 1vw;
  padding-top: 2vw;
}

.Sponsors span {
  font-size: 4vh;
  display: flex;
  color: white;
  justify-content: center;
  padding-bottom: 2vw;
}

.SponsorsSvgs {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}
</style>
