<template>
  <div class="SvgChild" :style="style1">
    <base-svg :width="width1" :height="height1" :path="path1" :background="background" />
    <svg
      :width="width2"
      :height="height2"
      :viewBox="viewBox"
      class="Child"
      :style="`width: ${this.size}; height: ${this.size}`"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        :tranform="transform"
        :d="path2"
        :style="style2"
        :stroke="stroke"
        :stroke-width="fill ? null : strokeWidth"
        :fill="fill"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "BaseSvgChild",
  computed: {
    viewBox() {
      return `0 0 ${this.width2} ${this.height2}`;
    },
    transform() {
      return `scale(${1 / this.width2} ${1 / this.height2})`;
    },
  },
  props: {
    width1: {
      required: true,
    },
    height1: {
      required: true,
    },
    path1: {
      required: true,
    },
    style1: { required: false },
    width2: {
      required: true,
    },
    height2: {
      required: true,
    },
    path2: {
      required: true,
    },
    background: String,
    stroke: String,
    strokeWidth: String,
    style2: { required: false },
    fill: String,
    size: String,
  },
};
</script>

<style scoped>
.Child {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
