<template>
  <base-svg-button
    @btnClicked="clicked"
    :wrapperStyle="style"
    width="89"
    height="98"
    path="M58 18.9999H84C89.5999 22.9997 86.3333 28.9999 84 31.5H74M58 18.9999H31.5M58 18.9999C59.6 4.99992 49.6666 2.16655 44.5 2.49986C32.9 2.09986 31 10.4998 31.5 18.9999M31.5 18.9999H4.49997C2.8333 20.8332 0.399967 25.9 3.99997 31.5H14.5M14.5 31.5V85C14.5 88.6667 16.4 95.9 24 95.5H65C68.1667 95 74.4 92.2 74 85V31.5M14.5 31.5H74M30.5 46.3219V80.5C31.7 82.6424 33 81.3927 33.5 80.5V46.3219C32.5 43.8219 30.5 45.3219 30.5 46.3219ZM43.2576 46.3219V80.5C44.4576 82.6424 45.7576 81.3927 46.2576 80.5V46.3219C45.2576 43.8219 43.2576 45.3219 43.2576 46.3219ZM56 46.3219V80.5C57.2 82.6424 58.5 81.3927 59 80.5V46.3219C58 43.8219 56 45.3219 56 46.3219Z"
  />
</template>

<script>
export default {
  name: "DeleteButton",
  props: {
    style: { required: false },
    clicked: {
      required: true,
    },
  },
};
</script>
