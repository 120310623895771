export default {
  setUser(state, payload) {
    state.id = payload.id;
    state.refreshToken = payload.refreshToken;
  },
  setDiscordId(state, { discordId }) {
    state.discordId = discordId;
  },
  setEmailVerified(state, { emailVerified, email }) {
    state.emailVerified = emailVerified;
    state.email = email;
  },
  setHasTeam(state, { hasTeam, teamId, isCaptain }) {
    state.hasTeam = hasTeam;
    state.teamId = teamId;
    state.isCaptain = isCaptain;
  },
};
