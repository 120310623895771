<template>
  <loading v-if="loading" />
  <div v-else-if="!addTeamMembers && !showAuxMembers" class="Wrapper-Team">
    <div>
      <div v-if="this.team && !isAddArrayProps" class="Team">
        <span v-if="!isEditTeam" class="TeamName">{{ team.teamName }}</span>
        <!-- <input
          v-else
          class="TeamName TeamNameInput"
          v-model.trim="editTeamInfo.teamName"
          :style="`width: 100%`"
        /> -->

        <!--
        <img
          :class="`PadLock ${this.isEditTeam ? 'Clickable' : ''}`"
          @click="toggleAcceptsNewMembers"
          :src="`${
            isEditTeam
              ? editTeamInfo.acceptsNewMembers
                ? '/content/assets/OpenPadLock.svg'
                : '/content/assets/LockedPadLock.svg'
              : team.acceptsNewMembers
              ? '/content/assets/OpenPadLock.svg'
              : '/content/assets/LockedPadLock.svg'
          }`"
          :alt="`Този отбор е ${team.acceptsNewMembers ? 'отворен' : 'затворен'} за кандидатстване`"
        />
        -->
        <div class="ProfilePictures">
          <div
            class="ProfilePicWrapper"
            :style="index !== editTeamInfo.members.length - 1 ? 'margin-right: 3vw' : ''"
            v-for="(member, index) in isEditTeam ? editTeamInfo.members : team.members"
            :key="index"
          >
            <img
              :class="`ProfilePic ${member.isCaptain ? 'CaptainPic' : ''}`"
              :src="member.profilePicURL"
              :alt="`Профилна снимка на ${member.fullName}`"
            />
            <img v-if="member.isCaptain" class="Crown" src="/content/assets/Crown.svg" />
            <button
              @click="spliceUserInfoArray('members', index)"
              v-if="!member.isCaptain && isEditTeam"
              class="XButton"
            >
              +
            </button>
            <button
              v-if="!member.isCaptain && isEditTeam"
              @click="makeCaptain(member.id)"
              type="button"
              class="BaseWhiteButton"
            >
              Направи капитан
            </button>
            <span class="ProfileName">{{ member.fullName }} - {{ member.studentClass }}</span>
          </div>
        </div>
        <div class="red_button">
          <span :class="`Approved ${team.approved ? '' : 'Red'}`"
            >Отборът е {{ team.approved ? "потвърден" : "непотвърден" }}</span
          >
        </div>
        <!--
        <div class="main-buttons">
          <button
            @click="
              this.showAuxMembers = true;
              this.auxMembers = 'pendingInvites';
            "
            type="button"
            v-if="isViewerCaptain"
            style="padding-right: 0.3rem"
            class="BaseWhiteButton UpperControlButton"
          >
            <span> {{ isEditTeam ? "Редактирай" : "Виж" }} поканените</span>
          </button>
          <button
            @click="
              this.showAuxMembers = true;
              this.auxMembers = 'pendingApplications';
            "
            type="button"
            v-if="isViewerCaptain"
            class="BaseWhiteButton UpperControlButton"
          >
            <span>
              {{ isEditTeam ? "Редактирай" : "Виж" }}
              кандидатствалите</span
            >
          </button>
        </div>
        -->
        <!--
        <div class="buttonwrapper">
          <button
            @click="this.addTeamMembers = true"
            type="button"
            v-if="isEditTeam"
            class="BaseWhiteButton UpperControlButton addingpeople"
          >
            Добави членове
          </button>
        </div>
        -->
        <div class="ProjectInfo">
          <div class="Technologies ProjectSpans">
            <span> Технологии </span>
            <ArrayProps
              style="position: relative"
              type="technologies"
              @clicked-add="setAddArrayProps"
              @clicked-x="spliceUserInfoArray"
              :isEdit="isEditTeam"
              :isColored="true"
              :arrayProps="isEditTeam ? editTeamInfo.technologies : team.technologies"
            />
          </div>
          <div class="ProjectSpans">
            <span> Име на проект: </span>
            <span v-if="!isEditTeam">{{ team.projectName }}</span>
            <input
              v-else
              type="text"
              placeholder="Име на проект"
              v-model="editTeamInfo.projectName"
            />
            <div
              class="RepoLink"
              v-if="team.repoLink.length && !isEditTeam"
              @click="goTo(team.repoLink)"
            >
              <span>GitHub</span>
              <img src="/content/assets/GoToLink.svg" />
            </div>
            <div class="RepoLink" v-else-if="isEditTeam">
              <input placeholder="Линк към GitHub репо" v-model="editTeamInfo.repoLink" />
            </div>
            <br />
            <span>Описание на проект:</span>
            <div :class="`Description ${!team.repoLink.length || isEditTeam ? 'Extended' : ''}`">
              <span v-if="!isEditTeam">{{ team.projectDescription }}</span>
              <textarea
                v-else
                placeholder="Описание.."
                v-model.trim="editTeamInfo.projectDescription"
              />
            </div>
          </div>
        </div>
        <div>
          <button
            class="BaseWhiteButton ControlButton"
            @click="editTeam"
            v-if="isEditTeam"
            style="background-color: rgba(186, 137, 255, 0.659)"
          >
            Запис
          </button>

          <button
            v-if="isViewerCaptain"
            @click="this.switchEditMode"
            class="BaseWhiteButton ControlButton"
          >
            {{ isEditTeam ? "Отказ" : "Редактирай" }}
          </button>
          <!--
          <button
            :disabled="!team.acceptsNewMembers || isApplied"
            @click="applyForTeam"
            class="BaseWhiteButton ControlButton"
            v-if="
              this.$store.getters.isAuthenticated &&
              !this.$store.getters.hasTeam &&
              !isViewerMember &&
              !isViewerCaptain
            "
          >
            {{ applyText }}
          </button> -->
          <!-- <button
            v-if="isViewerMember"
            @click="this.leaveTeam"
            class="BaseWhiteButton ControlButton"
          >
            Напусни Отбор
          </button>

          <button
            class="BaseWhiteButton ControlButton"
            style="background-color: #db0707"
            v-if="isViewerCaptain"
            @click="this.deleteTeam"
          >
            Изтрий Отбор
          </button> -->
        </div>
        Ментор - {{ team.mentorName }}
      </div>
      <div class="AddArrayProps" v-else-if="isAddArrayProps">
        <AddArrayProps
          :arrayProps="editTeamInfo.technologies"
          @clicked-main-x="this.nullAddAP"
          @clicked-add="this.addArrayProp"
          @clicked-x="this.spliceUserInfoArray"
          type="technologies"
          :isColored="true"
        />
      </div>
      <error-dialog v-if="errors?.errorMessages?.length" :errorMessages="errors?.errorMessages" />
    </div>
  </div>
  <AddTeamMembers
    v-else-if="!showAuxMembers"
    v-model="newMembers"
    :show="addTeamMembers"
    @update:show="addTeamMembers = newATM"
  />
  <team-members-list
    v-else
    v-model="team[auxMembers]"
    @update:show="showAuxMembers = newSAM"
    :isEdit="isEditTeam"
    :isApprovable="auxMembers === 'pendingApplications'"
    :approve="acceptApplication"
  />
</template>

<script>
import axiosInstance from "../axiosInstance";
import errorHandler from "../util/errorHandler";
import ErrorDialog from "../components/ui/ErrorDialog.vue";
import createTokenPair from "../util/createTokenPair";
import ArrayProps from "../components/ArrayProps.vue";
import AddArrayProps from "../components/AddArrayProps.vue";
import lodash from "lodash";
import getChangedProps from "../util/getChangedProps";
import AddTeamMembers from "../components/AddTeamMembers.vue";
import TeamMembersList from "../components/TeamMembersList.vue";
import teams from "../util/pageTexts/teams";

export default {
  name: "TeamPage",
  data() {
    return {
      editTeamInfo: null,
      team: null,
      loading: false,
      isEditTeam: false,
      errors: null,
      isAddArrayProps: false,
      newMembers: [],
      showAuxMembers: false,
      addTeamMembers: false,
      isApplied: false,
    };
  },
  components: {
    ErrorDialog,
    ArrayProps,
    AddArrayProps,
    AddTeamMembers,
    TeamMembersList,
  },
  computed: {
    isViewerCaptain() {
      if (this.team) {
        return (
          this.team?.members?.filter((e) => e.isCaptain === true)[0]?.id === this.$store.getters.id
        );
      }
      return false;
    },
    isViewerMember() {
      if (this.team) {
        return this.team?.members?.filter((e) => e.id === this.$store.getters.id).length;
      }
      return false;
    },
    applyText() {
      return this.isApplied ? "Кандидаствал" : "Кандидатствай";
    },
  },
  methods: {
    spliceUserInfoArray(arrayInfoName, index) {
      this.editTeamInfo[arrayInfoName].splice(index, 1);
    },
    setAddArrayProps(type) {
      this.currAPType = type;
      this.isAddArrayProps = true;
    },
    addArrayProp(type, arrayProp) {
      this.editTeamInfo[type].push(arrayProp);
    },
    changeTechnologies(technologiesData) {
      this.team.technologies = [...technologiesData];
    },
    goTo(link) {
      window.open(link, "_blank");
    },
    nullAddAP() {
      this.isAddArrayProps = false;
    },
    toggleAcceptsNewMembers() {
      this.editTeamInfo.acceptsNewMembers = !this.editTeamInfo.acceptsNewMembers;
    },
    switchEditMode() {
      if (this.isEditTeam) {
        this.editTeamInfo = lodash.clone(this.team);
        this.editTeamInfo.technologies = lodash.clone(this.team.technologies);
        this.editTeamInfo.members = lodash.clone(this.team?.members);
        this.editTeamInfo.pendingInvites = lodash.clone(this.team.pendingInvites);
        this.newMembers = [];
      }
      this.isEditTeam = !this.isEditTeam;
    },
    async acceptApplication(applicantId) {
      this.loading = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        const {
          data: {
            response: { editedTeam },
          },
        } = await axiosInstance.post(
          "/team/accept-application-id",
          {
            applicantId,
            teamId: this.team._id,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.errors = null;
        this.updateTeam({
          ...editedTeam,
          members: [...editedTeam.members],
          pendingInvites: [...editedTeam.pendingInvites],
          pendingApplications: editedTeam.pendingApplications && [
            ...editedTeam.pendingApplications,
          ],
          technologies: [...editedTeam.technologies],
        });
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
    async makeCaptain(id) {
      this.loading = true;

      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        await axiosInstance.post(
          "/team/make-captain",
          {
            newCaptainId: id,
            teamId: this.team._id,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.errors = null;
        this.loading = false;
        this.$router.go();
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
    async deleteTeam() {
      let teamNameCheck = "";
      teamNameCheck = window.prompt(`За да изтриете отбора, напишете ${this.team.teamName}`, "");

      if (teamNameCheck === this.team.teamName && this.team._id) {
        try {
          const { success, accessToken } = await createTokenPair(this.$store);
          if (!success) {
            this.$router.go(0);
            return;
          }
          await axiosInstance.delete(`/team/delete-team/${this.team._id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          this.errors = null;
          this.team = null;
          this.$router.replace({ path: "teams" });
          this.$store.commit("setHasTeam", { hasTeam: false, teamId: null });
        } catch (err) {
          this.errors = errorHandler(err);
        }
      } else {
        window.alert("Изтриването на отбора прекратено");
      }
    },
    async leaveTeam() {
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        this.loading = true;
        await axiosInstance.get("team/leave-team", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        this.$store.commit("setHasTeam", { hasTeam: false, teamId: null });
        this.$router.replace("/teams");
        this.errors = null;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.errors = errorHandler(err);
      }
    },
    async applyForTeam() {
      this.loading = true;
      try {
        const { success, accessToken } = await createTokenPair(this.$store);
        if (!success) {
          this.$router.go(0);
          return;
        }
        const teamId = this.team._id;
        await axiosInstance.post(
          "/team/apply-for-team",
          {
            teamId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        this.loading = false;
        this.errors = null;
        alert("Изпратен е имейл на капитана");
        this.isApplied = true;
      } catch (err) {
        this.errors = errorHandler(err);
        this.loading = false;
      }
    },

    async normalizeMembers(members) {
      for (let i = 0; i < members.length; i++) {
        try {
          const response = await axiosInstance.get(
            `/user/get-user/${members[i].id || members[i]._id || members[i]}`
          );

          if (!response.data.success) {
            alert(response.data.errors);
          }

          const { fullName, profilePicURL, studentClass, emailVerified } = response.data.response;

          members[i] = {
            _id: members[i].id || members[i]._id || members[i],
            id: members[i].id || members[i]._id || members[i],
            isCaptain: members[i].isCaptain || false,
            emailVerified,
            fullName,
            profilePicURL,
            studentClass,
          };
        } catch (err) {
          members[i] = {
            id: "1",
            isCaptain: false,
            fullName: "Unknown",
            profilePicURL: "",
            studentClass: "",
          };
        }
      }
    },
    flattenMembers(members) {
      const normalizedMembers = [
        {
          isCaptain: true,
          id: this.$store.getters.id,
        },
      ];
      members.forEach((member) => {
        if (!member.isCaptain) {
          normalizedMembers.push({
            isCaptain: member.isCaptain || false,
            id: member.id || member,
          });
        }
      });
      return normalizedMembers;
    },
    flattenTeamMembers(members) {
      const normalizedMembers = [
        {
          isCaptain: true,
          id: this.$store.getters.id,
        },
      ];
      members.forEach((member) => {
        if (!member.isCaptain) {
          normalizedMembers.push({
            isCaptain: member.isCaptain || false,
            id: member.email,
          });
        }
      });
      return normalizedMembers;
    },
    async editTeam() {
      const editedTeamInfo = getChangedProps(this.team, {
        ...this.editTeamInfo,
        members: this.editTeamInfo.members.concat(this.newMembers),
      });

      if (Object.keys(editedTeamInfo).length) {
        this.loading = true;
        try {
          const { success, accessToken } = await createTokenPair(this.$store);
          if (!success) {
            this.$router.go(0);
            return;
          }
          const {
            data: {
              response: { editedTeam },
            },
          } = await axiosInstance.post(
            `/team/edit-team/${this.team._id}`,
            {
              ...editedTeamInfo,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          this.errors = null;
          this.updateTeam({
            ...editedTeam,
            members: [...editedTeam.members],
            pendingInvites: [...editedTeam.pendingInvites],
            pendingApplications: [...editedTeam.pendingApplications],
            technologies: [...editedTeam.technologies],
          });
          this.newMembers = [];
        } catch (err) {
          this.errors = errorHandler(err);
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    async updateTeam(teamInfo) {
      this.isEditTeam = false;
      this.team = { ...this.team, ...teamInfo };
      this.loading = true;
      await this.normalizeMembers(this.team.members);
      await this.normalizeMembers(this.team.pendingInvites);
      await this.normalizeMembers(this.team.pendingApplications);
      this.loading = false;
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const { teamId } = this.$route.query;
      const team = teams.filter((e) => e._id == teamId)[0];
      this.team = lodash.clone(team);
      this.editTeamInfo = lodash.clone(team);
      this.editTeamInfo.technologies = lodash.clone(team.technologies);
      this.editTeamInfo.members = lodash.clone(team.members);
      this.editTeamInfo.pendingInvites = lodash.clone(team.pendingInvites);
      this.loading = false;
      this.isApplied = this.team?.pendingApplications?.filter(
        (e) => e._id == this.$store.getters.id
      ).length;
    } catch (err) {
      const { myTeam } = this.$route.query;
      const { teamId } = this.$route.query;
      this.loading = false;
      this.team = null;
      if (myTeam === "true") {
        if (teamId === this.$store.getters.teamId) {
          this.$store.commit("setHasTeam", { hasTeam: false, teamId: null });
        }
      }
      this.$router.replace("/");
    }
  },
};
</script>

<style scoped>
.Wrapper-Team {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Team {
  width: 73vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(var(--blur));
  height: max-content;
  background-color: var(--blur-background-color);
  border: var(--border);
  border-radius: var(--border-radius);
  color: white;
  padding-top: 1vh;
  padding-bottom: 1vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.gridWrapper {
  width: 100%;
}

.TeamName {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: calc(var(--font-size) * 2);
  font-weight: 900;
}

.TeamNameInput {
  all: unset;
  font-size: calc(var(--font-size) * 2);
  border-bottom: var(--border);
}

.PadLock {
  position: absolute;
  width: 2.5vw;
  height: auto;
  top: 4vw;
  left: 2vw;
  z-index: 30;
}

.Clickable {
  cursor: pointer;
}

.ProfilePictures {
  position: relative;
  display: flex;
  justify-content: center;
  height: max-content;
  margin-top: 3vh;
  flex-wrap: wrap;
}

.ProfilePicWrapper {
  display: block;
  width: 12.5%;
  position: relative;
  height: max-content;
}

.ProfilePic {
  width: 100%;
  aspect-ratio: 1;
  background-color: white;
  height: auto;
  border: var(--medium-border);
  border-radius: 50%;
}

.ProfilePicname {
  font-size: var(--font-size);
  max-width: 100%;
  word-break: break-all;
}

.CaptainPic {
  border: var(--medium-ts) solid yellow;
}

.Crown {
  position: absolute;
  top: 0;
  right: 0;
  height: 1.7vw;
  width: auto;
  transform: rotate(50deg) translate(40%, -50%);
}

.UpperControlButton {
  position: relative;
  display: flex;
  float: center;
}

.Approved {
  display: inline-block;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  text-align: center;
}
.main-buttons {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
}
.main-buttons span {
  font-size: 1.3rem;
}
.red_button {
  width: 95%;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
.Red {
  padding: 0.15rem 0;
  background: rgb(5, 85, 255);
  background: linear-gradient(
    162deg,
    rgba(5, 85, 255, 1) 0%,
    rgba(158, 6, 6, 1) 0%,
    rgba(81, 2, 88, 1) 100%
  );
  border-radius: 7%;
  overflow: hidden;
  font-size: 1rem;
}
.Approved {
  font-size: 1rem;
}
.ProjectInfo {
  position: relative;
  height: max-content;
  width: 95%;
  padding: 1rem 1rem;
  display: grid;
  --n: 3;
  grid-template-columns: repeat(auto-fill, minmax(max(300px, 100% / var(--n)), 1fr));
  gap: 1.5em;
}

.ProjectSpans {
  width: 100%;
  font-size: calc(var(--font-size) * 1.5);
  text-align: left;
  height: max-content;
}

.ProjectSpans input {
  all: unset;
  font-size: inherit;
  border-bottom: var(--border);
}

.RepoLink {
  cursor: pointer;
  position: relative;
  display: flex;
  height: auto;
  width: max-content;
  font-size: inherit;
}

.RepoLink a {
  text-decoration: none;
  color: white;
  font-size: inherit;
}

.RepoLink img {
  display: block;
  height: 1.5vh;
  width: auto;
}

.ProjectSpans span {
  font-size: calc(var(--font-size) * 1.5);
}

.Technologies {
  height: auto;
  width: 100%;
  font-size: var(--font-size);
}

.Description {
  font-size: inherit;
  height: max-content;
  max-height: 15vh;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  word-break: break-all;
  text-align: justify;
  padding-right: 0.5rem;
}

.Extended {
  height: 80%;
  max-height: 80%;
}

.Description span,
.Description textarea {
  font-size: inherit;
}

.Description textarea {
  width: 100%;
  height: 100%;
}

.ControlButton {
  border: var(--medium-border);
  padding: 0.7vw;
  font-size: calc(var(--font-size) * 1.5);
  border-radius: calc(var(--border-radius) * 0.7);
  margin-right: 1vw;
}
.buttonwrapper {
  padding-top: 0.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.addingpeople {
  width: 30%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
}
.AddArrayProps {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.XButton {
  all: unset;
  position: absolute;
  --dim: 2.5vw;
  width: var(--dim);
  height: var(--dim);
  border: var(--medium-border);
  font-size: calc(var(--font-size) * 2);
  background: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  transition: ease all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg) translate(50%, -50%);
  text-align: center;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 99%;
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  border-radius: var(--border-radius);
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: 99%;
  background: rgba(0, 151, 19, 0);
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: var(--border);
  border-radius: var(--border-radius);
  background: rgba(0, 151, 19, 0);
}
@media only screen and (max-width: 850px) {
  .Team {
    width: 95%;
  }
  .TeamName {
    font-size: calc(var(--font-size) * 5);
  }
  .ProfilePicWrapper {
    width: 25%;
  }
  .ProfileName {
    font-size: 2.5rem;
  }
  .Red {
    font-size: 2rem;
  }
  .main-buttons span {
    font-size: 2.2rem;
  }
  .Description {
    font-size: calc(1vw + 1vh);
  }
  .ProjectSpans span,
  input {
    font-size: calc(1vw + 1vh);
  }
  .RepoLink {
    font-size: calc(1.5vw + 1.5vh);
  }
  .RepoLink img {
    height: calc(1.5vw + 1.5vh);
  }
  .Approved {
    font-size: 2rem;
  }
  .ControlButton {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 620px) {
  .TeamName {
    font-size: calc(var(--font-size) * 6.5);
  }
  .Approved {
    font-size: 3rem;
  }
  .Red {
    font-size: 3rem;
  }
  .main-buttons {
    width: 90%;
  }
  .main-buttons span {
    font-size: 4rem;
  }
  .ProfileName {
    font-size: 4rem;
  }
  .ControlButton {
    font-size: 4rem;
  }
  .Crown {
    height: 3vw;
  }
}
</style>
