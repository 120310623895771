<template>
  <base-svg-button
    @btnClicked="clicked"
    :wrapperStyle="style"
    v-if="condition"
    width="89"
    height="98"
    path="M70.5 74C70.5 77 70.5 83.6 70.5 86C70.5 88.4 68.5 89 67.5 89H4C2 89 1.5 87.6667 1.5 87V5C1.5 3.83333 2.2 1.5 5 1.5H67C68 1.5 70 2.2 70 5C70 7.8 70 31.5 70 43M14.5 72.5H57M56.5 59H14.5M14.5 46H44.5M14.5 32H41M14.5 18H56.5M49.5 47L58.5 38M49.5 47L84 81.5C84.8333 82.5 87.1 83.9 89.5 81.5C91.9 79.1 93.1667 77.8333 93.5 77.5C94.5 76.6667 95.9 74.5 93.5 72.5L58.5 38M49.5 47L45.5 36C44.2666 32.6081 46.9861 33.2534 48.5 34L58.5 38"
  />
  <base-svg-button
    @btnClicked="clickedX"
    :wrapperStyle="style"
    v-else
    width="180"
    height="180"
    fill="white"
    size="100%"
    path="M168 90C168 133.078 133.078 168 90 168C46.9218 168 12 133.078 12 90C12 46.9218 46.9218 12 90 12C133.078 12 168 46.9218 168 90ZM180 90C180 139.706 139.706 180 90 180C40.2944 180 0 139.706 0 90C0 40.2944 40.2944 0 90 0C139.706 0 180 40.2944 180 90ZM117.13 110.129C118.932 111.932 118.932 114.854 117.13 116.656C115.327 118.459 112.405 118.459 110.602 116.656L89.8932 95.9471L68.7911 117.049C66.9886 118.852 64.0663 118.852 62.2639 117.049C60.4615 115.247 60.4615 112.325 62.2639 110.522L83.366 89.42L62.7368 68.7907C60.9344 66.9883 60.9344 64.066 62.7368 62.2636C64.5392 60.4611 67.4615 60.4611 69.2639 62.2636L89.8932 82.8928L110.13 62.6564C111.932 60.854 114.854 60.854 116.657 62.6564C118.459 64.4588 118.459 67.3811 116.657 69.1835L96.4203 89.42L117.13 110.129Z"
  />
</template>

<script>
export default {
  name: "EditXButton",
  props: {
    style: { required: false },
    clicked: {
      required: true,
    },
    clickedX: {
      required: false,
    },
    condition: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>
