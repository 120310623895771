<template>
  <div class="TimerWrapper">
    <div class="Date" v-for="(date, index) in dates" :key="index">
      <DateElem v-for="(digit, index) in date.dates" :key="index" :digit="digit" />
      <div v-if="index !== dates.length - 1" class="Dot">
        <span>:</span>
      </div>
      <div v-if="this.$store.getters.isMobile" class="DateType">
        {{ date.type }}
      </div>
    </div>
  </div>
</template>

<script>
import DateElem from "../components/DateElem.vue";

export default {
  name: "HomePageTimer",
  data() {
    return {
      dates: null,
      countDownDate: new Date("Mar 10, 2022 18:0:0").getTime(),
    };
  },
  created() {
    this.updateTimer();
    this.timer = setInterval(this.updateTimer, 100);
  },
  components: {
    DateElem,
  },
  methods: {
    updateTimer() {
      let now = new Date().getTime();
      let distance = this.countDownDate - now;

      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
      }
      if (this.$store.getters.isMobile) {
        this.dates = [
          { type: "Дни", dates: this.transform(days) },
          { type: "Час", dates: this.transform(hours) },
          { type: "Мин", dates: this.transform(minutes) },
          { type: "Сек", dates: this.transform(seconds) },
        ];
      } else {
        this.dates = [
          { type: "Дни", dates: this.transform(days) },
          { type: "Час", dates: this.transform(hours) },
          { type: "Мин", dates: this.transform(minutes) },
          { type: "Сек", dates: this.transform(seconds) },
        ];
      }
    },
    transform(timeVal) {
      if (timeVal < 10) {
        return [...`0${timeVal}`];
      }
      return [...timeVal.toString()];
    },
  },
};
</script>

<style scoped>
.TimerWrapper {
  position: relative;
  margin-top: 10vh;
  height: min-content;
}

.Date {
  position: relative;
  display: inline-flex;
}

.Dot {
  width: max-content;
  color: white;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.Dot span {
  font-size: 10vw;
  padding-bottom: 0.2vw;
  text-align: center;
}

.DateType {
  color: white;
  position: absolute;
  font-size: 5vw;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 0.4vw), 175%);
}
</style>
