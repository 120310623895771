<template>
  <button
    class="Button"
    @click="this.$emit('click')"
    type="button"
    :style="`background-color: ${color};`"
  >
    <svg
      id="shadow_filter"
      width="60%"
      viewBox="0 0 63 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6574 18.168C4.00197 25.1806 -5.16286 43.4142 11.4216 60.2473C19.7652 67.6707 40.593 76.8173 57.1564 54.0161C60.7915 48.0782 65.1218 32.7502 53.873 19.1239C50.0162 14.452 37.7182 5.66941 22.0224 10.951M22.0224 10.951L30.5 1.5M22.0224 10.951L32 16"
        stroke="white"
        stroke-width="3"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "RegenSeedButton",
  props: {
    styleArg: String,
    color: String,
  },
};
</script>

<style>
.Button {
  width: 7vw;
  height: auto;
  z-index: 2;
  aspect-ratio: 1;
  border-radius: 50% !important;
  border: 0.1vw solid black;
  background-color: purple;
  cursor: pointer;
}
#shadow_filter {
  filter: drop-shadow(0px 0.7px 0px #ccc) drop-shadow(0px -0.7px 0px #ccc)
    drop-shadow(0.7px 0px 0px #ccc) drop-shadow(-0.7px 0px 0px #ccc);
}
</style>
