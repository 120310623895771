export default {
  token(state) {
    return state.refreshToken;
  },
  id(state) {
    return state.id;
  },
  isAuthenticated(state) {
    return state.refreshToken ? true : false;
  },
  hasDiscordId(state) {
    return state.id ? !!state.discordId?.length : true;
  },
  discordId(state) {
    return state.discordId;
  },
  isEmailVerified(state) {
    return state.id ? state.emailVerified : true;
  },
  email(state) {
    return state.email;
  },
  hasTeam(state) {
    return state.hasTeam;
  },
  teamId(state) {
    return state.teamId;
  },
  isCaptain(state) {
    return state.isCaptain;
  },
};
