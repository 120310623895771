<template>
  <loading v-if="loading" />
  <div v-else style="padding-top: 3vh">
    <div class="Controls">
      <input
        type="text"
        v-if="this.teams.length"
        v-model="searchName"
        :class="`SearchTermInput ${this.$store.getters.isMobile ? 'isMobile' : ''}`"
        placeholder="Търсене..."
      />
      <!--
      <base-button
        link
        to="/auth?redirect=team-registration"
        v-if="!isLoggedIn"
        style="font-size: 2vh !important"
        >Създай отбор</base-button
      >
      <base-button
        link
        to="/team-registration"
        v-else-if="isLoggedIn && !this.$store.getters.hasTeam"
        >Създай отбор</base-button
      >
      -->
      <span
        :style="`${
          this.$store.getters.isMobile ? 'font-size: 4rem;' : 'font-size: 1.5rem;'
        } display:flex; width: max-content; margin-left: auto; margin-right: auto; margin-top: 1vh; color:white;`"
        >{{ teams.filter((e) => e.approved).length }}/50 потвърдени отборa</span
      >
    </div>
    <Team v-for="(team, index) in teamNameSearchResults" :key="index" :team="team" />
  </div>
</template>

<script>
import errorHandler from "../util/errorHandler";
import Team from "../components/Team.vue";
import teams from "../util/pageTexts/teams";

export default {
  name: "Teams",
  data() {
    return {
      teams: [],
      loading: true,
      searchName: "",
    };
  },
  async mounted() {
    try {
      this.teams = teams;
      this.loading = false;
    } catch (err) {
      errorHandler(err);
      this.loading = false;
    }
  },
  components: { Team },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    teamNameSearchResults() {
      return this.searchName.length
        ? this.teams.filter(
            (team) =>
              team.teamName.toLowerCase().startsWith(this.searchName.toLowerCase()) ||
              team.projectName.toLowerCase().startsWith(this.searchName.toLowerCase()) ||
              !!team.technologies.filter((tech) =>
                tech.toLowerCase().startsWith(this.searchName.toLowerCase())
              ).length
          )
        : this.teams;
    },
  },
};
</script>

<style scoped>
.Controls {
  height: max-content;
  position: relative;
  margin: 3vh;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5vh;
  background-color: rgba(24, 24, 185, 0.11);
  backdrop-filter: blur(var(--blur));
  border-radius: var(--border-radius);
  width: max-content;
}

.SearchTermInput {
  all: unset;
  color: white;
  border: var(--default-ts) solid rgb(123, 87, 255);
  border-radius: var(--border-radius);
  font-size: calc(var(--font-size) * 1.3);
  text-align: left;
  width: calc(8vw + 8vh);
  background-color: rgb(79, 26, 177);
  margin-right: 1.5vh;
  padding: 1vw;
}

.SearchTermInput::placeholder {
  color: white;
}

.isMobile {
  font-size: calc(var(--font-size) * 4);
  width: 40vw;
}
</style>
